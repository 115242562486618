import { ApolloClient, gql } from '@apollo/client';
import { Polygon } from 'geojson';
import { AnnotationState } from '../../api-types';

/**
 * Updates category of given annotation.
 * @param annotationId The annotation's id
 * @param categoryId   The updated category's id
 */
export const setAnnotationCategory = (
  client: ApolloClient<object>,
  annotationId: string,
  categoryId: string
): void => {
  client.cache.modify({
    id: `AnnotationProperties:${annotationId}`,
    fields: {
      category: () => ({
        __ref: `AnnotationCategory:${categoryId}`,
      }),
    },
  });
};

/**
 * Sets annotation update state value.
 * @param id    The annotation's id
 * @param state The annotation's update value or null
 */
export const setAnnotationState = (
  client: ApolloClient<object>,
  id: string,
  state: AnnotationState
): void => {
  client.writeFragment({
    id: `AnnotationProperties:${id}`,
    fragment: gql`
      fragment AnnotationState on AnnotationProperties {
        state
      }
    `,
    data: { state },
  });
};

/**
 * Update the given annotation's geometry
 * @param annotation
 */
export const setAnnotationGeometry = (
  client: ApolloClient<object>,
  annotationId: string,
  geometry: Polygon
): void => {
  client.writeFragment({
    id: `Annotation:${annotationId}`,
    fragment: gql`
      fragment Geometry on Annotation {
        geometry
      }
    `,
    data: {
      geometry,
    },
  });
};

/**
 * Update the annotation created_at field
 * after it was inserted in the DB.
 *
 */
export const setAnnotationCreatedAt = (
  client: ApolloClient<object>,
  annotationId: string,
  createdAt: string
): void => {
  client.writeFragment({
    id: `AnnotationProperties:${annotationId}`,
    fragment: gql`
      fragment CreatedAt on AnnotationProperties {
        createdAt
      }
    `,
    data: {
      createdAt,
    },
  });
};

/**
 * Sets annotation state and updates its geometry.
 * @param client       The Apollo Client instance
 * @param annotationId The annotation's id
 * @param state        The annotation's state value or null
 * @param geometry     The annotation's geometry
 */
export const setAnnotationStateAndGeometry = (
  client: ApolloClient<object>,
  annotationId: string,
  state: AnnotationState | null,
  geometry: Polygon
): void => {
  // Update the state and geometry in a single writeFragment call
  client.writeFragment({
    id: `Annotation:${annotationId}`,
    fragment: gql`
      fragment AnnotationDetails on Annotation {
        properties {
          state
        }
        geometry
      }
    `,
    data: {
      properties: { state },
      geometry,
    },
  });
};
