"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisibleWithScope = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/** Renders children based on users auth roles */
function VisibleWithScope({ scope, role, children, }) {
    if (!role.scopes[scope])
        return null;
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
}
exports.VisibleWithScope = VisibleWithScope;
