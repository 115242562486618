// TODO Duplicated
import { gql } from '@apollo/client';

const GET_MENU_SIDEBAR_WSIS = gql`
  query GET_MENU_SIDEBAR_WSIS(
    $subProjectId: ID!
    $annotations: WsiFilterType!
    $overlays: WsiFilterType!
    $searchSlides: String
    $associations: [ID]
    $batches: [ID]
    $case: String
    $objectivePowers: [Float]
    $scanners: [Int]
    $stainings: [Int]
    $tissues: [Int]
    $diseases: [String]
    $sortBy: sortSubprojectByValues
    $isAsc: Boolean
    $annotatedBy: [ID]!
    $annotationCategory: [ID]!
  ) {
    subProject(id: $subProjectId) {
      id
      wsis(
        annotations: $annotations
        overlays: $overlays
        associations: $associations
        batches: $batches
        case: $case
        objectivePowers: $objectivePowers
        scanners: $scanners
        stainings: $stainings
        tissues: $tissues
        searchSlides: $searchSlides
        diseases: $diseases
        isAsc: $isAsc
        sortBy: $sortBy
        annotatedBy: $annotatedBy
        annotationCategory: $annotationCategory
      ) {
        nodes {
          id
          name
          annotationsCount
          overlaysCount
          taggersCount
        }
      }
    }
  }
`;

export default GET_MENU_SIDEBAR_WSIS;
