import {
  AnnotationCategoriesFilterOptions,
  AnnotationCategorySet,
} from '../../../../../../types';

export const getFilterOptions = ({
  annotationCategorySets,
  selectedSetName,
  selectedSetCode,
}: {
  annotationCategorySets: AnnotationCategorySet[];
  selectedSetName?: string;
  selectedSetCode?: string;
}): AnnotationCategoriesFilterOptions => {
  const indicator = selectedSetName ? 'name' : selectedSetCode ? 'code' : null;
  const selectedValue = selectedSetName
    ? selectedSetName
    : selectedSetCode
      ? selectedSetCode
      : null;
  const selectedSet = indicator
    ? annotationCategorySets.find((set) => set[indicator] === selectedValue)
    : null;
  return {
    setNames: selectedSetCode
      ? [selectedSet?.name as string]
      : annotationCategorySets.map((set) => set.name),
    setCodes: selectedSetName
      ? [selectedSet?.code as string]
      : annotationCategorySets.map((set) => set.code),
    modules: selectedSet
      ? selectedSet.modules.map((module) => module.moduleName)
      : [],
  };
};
