import {
  Button,
  Filter,
  FilterConfigs,
  FilterField,
  FilterFunction,
  filterSearch,
  getFiltersFromQueryParams,
  HStack,
  IconButton,
  Loader,
  Placeholder,
  Section,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  useDisclosure,
  useFilteredCollection,
  useFilters,
  VStack,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { gql, useQuery } from '@apollo/client';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSetQueryParams } from '../../../../hooks';
import { swapArrayElements, useDynamicList } from '../../../../utils';
import DynamicSizeItem from '../AdminSubProjectAssignInteractiveOverlays/components/DynamicItem';
import { TableCellName } from '../components/TableCellName';
import { ToggleOverlaySlides } from '../components/ToggleOverlaySlides';
import { useToggleHandler } from '../Overlays';
import { OverlayMatrixViewModal } from '../Overlays/MatrixViewModal/OverlayMatrixView.Modal';
import {
  addAssignedAndAvailableWsisToStaticOverlaysMap,
  transformWsisToStaticOverlayMap,
} from './AdminSubprojectAssignStaticOverlay.utils';
import { $CenterCellElement } from './AdminSubprojectAssignStaticOverlays.styles';
import {
  StaticOverlayAll,
  StaticOverlayData,
  StaticOverlayWsi,
  UniqueStaticOverlayWithAssignedSlides,
} from './AdminSubprojectAssignStaticOverlays.types';
import { StaticOverlayModal } from './StaticOverlayModal/StaticOverlay.modal';
import { useSortStaticOverlays } from './useSortStaticOverlays';

export const FETCH_STATIC_OVERLAYS_MATRIX = gql`
  query GET_STATIC_OVERLAYS_MATRIX($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      wsis: wsisStaticOverlaysAssignment {
        id
        name
        overlaysAll: overlays {
          id
          originalName: name(type: original)
          sort
        }
        overlaysAssigned: assignedOverlays {
          id
          name
          originalName: name(type: original)
          overrideName: name(type: override)
          sort
        }
      }
    }
  }
`;

type FilterKeys = 'filterStaticOverlayColumn';

export const filterStaticOverlayColumn =
  filterSearch<UniqueStaticOverlayWithAssignedSlides>([
    'originalName',
    'overrideName',
  ]);

const FILTER_FUNCTIONS: Record<
  FilterKeys,
  FilterFunction<UniqueStaticOverlayWithAssignedSlides>
> = {
  filterStaticOverlayColumn: (overlayColumns, value) =>
    filterStaticOverlayColumn(overlayColumns)(value as string),
};

const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
  filterStaticOverlayColumn: {
    icon: 'Search',
    type: 'search',
    label: 'Search',
    value: '',
    placeholder: 'Search',
  },
};
/** Component to assign overlays to slides within the given Subproject. */
const AdminSubprojectAssignStaticOverlays = ({
  projectId,
  subprojectId,
  organizationUuid,
  userRole,
}: {
  projectId: string;
  subprojectId: string;
  organizationUuid: string;
  userRole: OrganizationRole;
}): ReactElement => {
  const matrixViewModal = useDisclosure();

  const [staticOverlays, setStaticOverlays] = useState<
    UniqueStaticOverlayWithAssignedSlides[]
  >([]);

  const [selectedStaticOverlay, setSelectedStaticOverlay] =
    useState<UniqueStaticOverlayWithAssignedSlides | null>();

  // Query data for rendering the table.
  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery<StaticOverlayData>(FETCH_STATIC_OVERLAYS_MATRIX, {
    variables: { subProjectId: subprojectId },
    nextFetchPolicy: 'cache-and-network',
  });

  // Get a list of unique original overlay names and optional override names

  // Setup the basic columns for wsi thumbnail and name

  // Variable overlay columns for each overlay

  const wsis = useMemo(
    () => data?.subProject.wsis ?? [],
    [data?.subProject.wsis]
  );

  useEffect(() => {
    // transform wsis[available, assignedTaggers] -> taggers[availableWsis, assignedWsis]
    // Transform WSIS to a map of taggers
    const allStaticOverlays = transformWsisToStaticOverlayMap(wsis);

    // transform taggersAssigned and taggersAll in wsis to assignedWsis and allWsis in taggersMap
    const taggerMapWithAssignedAndAvailableWsis =
      addAssignedAndAvailableWsisToStaticOverlaysMap(allStaticOverlays, wsis);

    // transform this into an array
    const taggers = Array.from(taggerMapWithAssignedAndAvailableWsis.values());

    const sorted = taggers.sort((a, b) => (a.sort || 0) - (b.sort || 0));
    // const sorted = sortTaggersAssignedBySortValue(taggers);
    setStaticOverlays(sorted);
  }, [wsis]);

  // Global change handler for all toggles
  const [onChange, { loading: mutationLoading, error: mutationError }] =
    useToggleHandler('static', subprojectId);

  // Combine all queries and mutations loading and error states
  const loading = queryLoading || mutationLoading;
  const error = queryError || mutationError;

  const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
    filterStaticOverlayColumn: { fallbackValue: '', type: 'string' },
  };

  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );

  useSetQueryParams(filters);
  const wsiIds: string[] = data?.subProject.wsis.map((wsi) => wsi.id) || [];
  const onSortStaticOverlays = useSortStaticOverlays(wsiIds);

  // TODO: clean up when this ticket address https://aignx.atlassian.net/browse/FE-2218
  const filteredOverlayColumns = useFilteredCollection(
    FILTER_FUNCTIONS,
    filters,
    staticOverlays
  );
  const title = `Static Overlays`;
  const description = `Assign Static Overlays to slides within this subproject.`;

  const onPositionUpdate = useCallback(
    (startIndex: number, endIndex: number) => {
      const newItems = [
        ...swapArrayElements(staticOverlays, startIndex, endIndex),
      ].map((item, index) => {
        return {
          ...item,
          sort: index,
        };
      });

      setStaticOverlays(newItems);
    },
    [staticOverlays]
  );

  const onPositionChange = () => {
    const staticOverlaysSort = staticOverlays.map((overlay) => {
      return {
        overlay: overlay.originalName,
        sort: overlay.sort,
      };
    });
    void onSortStaticOverlays({
      subProjectId: subprojectId,
      staticOverlaysSort,
    });
  };

  const dynamicListItemProps = useDynamicList({
    items: staticOverlays,
    onPositionUpdate,
    onPositionChange,
  });

  if (queryLoading && wsis.length === 0) {
    return (
      <Section
        title={title}
        description={description}
        loading={loading}
        error={error}
      >
        <Loader />
      </Section>
    );
  }

  const onEditIconClick = (
    staticOverlay: UniqueStaticOverlayWithAssignedSlides
  ) => {
    setSelectedStaticOverlay(staticOverlay);
  };

  const toggleOverlayOnAllAvailableSlides = (
    toggleValue: boolean,
    staticOverlayOriginalName: string
  ) => {
    const selectedStaticOverlayData = staticOverlays.find(
      (staticOverlay) =>
        staticOverlay.originalName === staticOverlayOriginalName
    ) as UniqueStaticOverlayWithAssignedSlides;
    const staticOverlayAvailableWsis = selectedStaticOverlayData?.allWsis;
    const overlayAssignment = staticOverlayAvailableWsis?.map((wsi) => {
      const currentWsi = wsis.find(
        (wsiItem) => wsiItem.id === wsi
      ) as StaticOverlayWsi;
      const currentOverlay = currentWsi?.overlaysAll.find(
        (staticOverlay) =>
          staticOverlay.originalName === selectedStaticOverlayData.originalName
      ) as StaticOverlayAll;

      return {
        wsiId: wsi,
        overlayId: currentOverlay.id,
      };
    });
    if (overlayAssignment) {
      onChange(toggleValue, overlayAssignment);
    }
  };

  return (
    <>
      <Section
        title={title}
        description={description}
        loading={loading}
        error={error}
        filterComponent={
          <Filter fields={PAGE_FILTER_FIELDS} isOpen {...filterProps} />
        }
      >
        <HStack justifyContent="flex-end" style={{ width: '100%' }}>
          <Button
            onClick={() => {
              matrixViewModal.open();
            }}
            small
            disabled={!loading && staticOverlays.length === 0}
          >
            Side-by-side view
          </Button>
        </HStack>
        {staticOverlays.length === 0 ? (
          <Placeholder
            title="No Static Overlays"
            description="There are no Static Overlays for the Slides within this Subproject."
            icon="Layers"
          />
        ) : (
          <VStack spacing="line">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader style={{ paddingLeft: '50px', width: '50%' }}>
                    Name
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Assigned Slides
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Edit
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Show
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOverlayColumns.map((staticOverlay, index) => {
                  // if one of the wsis has this static or interactive Overlay active then set isToggleActive header to TRUE
                  const isToggleActive = staticOverlay?.assignedWsis?.some(
                    (assignedWsi) =>
                      staticOverlay?.allWsis?.includes(assignedWsi)
                  );
                  return (
                    <DynamicSizeItem
                      key={staticOverlay.id}
                      index={index}
                      itemProps={dynamicListItemProps}
                    >
                      <TableCell style={{ paddingLeft: '10px', width: '50%' }}>
                        <TableCellName
                          overlay={{
                            overrideName: staticOverlay.overrideName,
                            originalName: staticOverlay.originalName,
                          }}
                          enableDrag={true}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {staticOverlay.assignedWsis?.length}
                      </TableCell>
                      <TableCell>
                        <$CenterCellElement>
                          <IconButton
                            aria-label="Edit"
                            icon={'Edit'}
                            onClick={() => {
                              onEditIconClick(staticOverlay);
                            }}
                            disabled={loading}
                          />
                        </$CenterCellElement>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <$CenterCellElement>
                          <ToggleOverlaySlides
                            overlay={staticOverlay}
                            isToggleActive={Boolean(isToggleActive)}
                            toggleOverlayOnAllAvailableSlides={
                              toggleOverlayOnAllAvailableSlides
                            }
                            disabled={loading}
                          />
                        </$CenterCellElement>
                      </TableCell>
                    </DynamicSizeItem>
                  );
                })}
              </TableBody>
            </Table>
          </VStack>
        )}
      </Section>
      {selectedStaticOverlay && (
        <StaticOverlayModal
          subprojectId={subprojectId}
          selectedStaticOverlay={selectedStaticOverlay}
          onClose={() => {
            setSelectedStaticOverlay(null);
          }}
          wsis={wsis}
          organizationUuid={organizationUuid}
        />
      )}
      {matrixViewModal.isOpen && (
        <OverlayMatrixViewModal
          matrixViewModal={matrixViewModal}
          wsis={wsis}
          subprojectId={subprojectId}
          projectId={projectId}
          organizationUuid={organizationUuid}
          userRole={userRole}
          type="static"
        />
      )}
    </>
  );
};

export default AdminSubprojectAssignStaticOverlays;
