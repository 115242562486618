import { Map as OLMap } from 'ol';
import { RasterSourceEvent } from 'ol/source/Raster';
import { TileSourceEvent } from 'ol/source/Tile';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { FluorescenceChannel, Wsi } from '../../../../../api-types';
import { FluorescenceChannelParam } from '../../../../../types/FluorescenceChannelParamType';
import { ExtendedFluorescenceChannel } from '../../../../__Features/Fluorescence/FeatureItem.Fluorescence.component';
import { getExtendedActiveFluorescenceChannels } from '../../../../__Features/Fluorescence/getExtendedActiveFluorescenceChannels';
import { Channel, luminanceToColor } from '../../Operations/luminanceToColor';
import { FilterOperation } from '../Layer.Image.component';
import { $LayerImageTiles as LayerImagesTile } from '../Layer.Images.styles';

interface FluorescenceLayerProps {
  wsi: Wsi;
  FluorescenceChannels: FluorescenceChannel[]; // DB entities
  FluorescenceChannelsParam: FluorescenceChannelParam[] | undefined; // Activated Fluorescence params
  zIndex: number;
  map: OLMap;
  getToken: () => Promise<string>;
  rasterTileServerUrl: string;
}

// Fix bad typing on ol side
interface __RasterSourceEvent extends RasterSourceEvent {
  data: { channels: Channel[] };
}

interface __TileSourceEvent extends TileSourceEvent {
  data: { channels: Channel[] };
}

const FluorescenceLayer = ({
  wsi,
  FluorescenceChannels: fluorescenceChannels,
  FluorescenceChannelsParam: fluorescenceChannelsParam,
  zIndex,
  map,
  getToken,
  rasterTileServerUrl,
}: FluorescenceLayerProps): ReactElement | null => {
  const theme = useTheme();

  const activeChannels: ExtendedFluorescenceChannel[] = useMemo(
    () =>
      getExtendedActiveFluorescenceChannels(
        fluorescenceChannels.flat(),
        fluorescenceChannelsParam
      ),
    [fluorescenceChannels, fluorescenceChannelsParam]
  );

  const tileUrls: string[] = useMemo(() => {
    return activeChannels.map((channel) => {
      // Construct correct tile server url

      const requestPath = [
        rasterTileServerUrl,
        'tile',
        'multiplex',
        channel.id,
      ].join('/');

      const searchQuery = new URLSearchParams({
        ...(channel.max && { max: channel.max.toString() }),
        ...(channel.min && { min: channel.min.toString() }),
        ...(channel.registrationId && { registration: channel.registrationId }),
        base: wsi.id,
      });

      //eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${requestPath}/{z}/{y}/{x}?${searchQuery}`;
    });
  }, [activeChannels, rasterTileServerUrl, wsi.id]);

  const fluorescence = activeChannels.length > 0;

  /**
   * Set map container background to black on mount and revert to white
   * background on unmount.
   */
  useEffect(() => {
    // This will never be a string because we're setting the target to a
    // HTMLElement ref
    const mapTarget = map.getTarget() as HTMLElement | undefined;
    if (mapTarget !== undefined) {
      mapTarget.style.backgroundColor = fluorescence
        ? theme.colors.black
        : theme.colors.white;
    }
  }, [map, fluorescence, theme.colors.black, theme.colors.white]);

  const firstChannel = wsi.fluorescence.files[0].channels[0];

  const before = useCallback(
    (event: __RasterSourceEvent | __TileSourceEvent) => {
      const data = activeChannels.map((c) => {
        const [r, g, b] = c.color
          .slice(1)
          .match(/.{1,2}/g)
          ?.map((s) => parseInt(s, 16)) || [0, 0, 0];

        return { r, g, b };
      });

      event.data.channels = data;
    },
    [activeChannels]
  );
  const filter = useMemo(
    () => ({
      operation: luminanceToColor,
      before,
    }),
    [before]
  );

  return fluorescence ? (
    <LayerImagesTile
      name="fluorescence"
      map={map}
      tileUrls={tileUrls}
      width={wsi.width}
      height={wsi.height}
      maxNativeZoom={wsi.maxZoom}
      maxZoom={firstChannel.maxZoom}
      opacity={1}
      zIndex={zIndex}
      filter={filter as FilterOperation}
      getToken={getToken}
      layerName="fluorescence"
    />
  ) : null;
};
export default FluorescenceLayer;
