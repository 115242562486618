import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { Annotation, FocusArea } from '../../../../api-types';
import { useViewerController } from '../../../__Viewer/ViewerController';
import {
  FeatureRef,
  useActiveFeature,
  useSetActiveFeature,
} from '../../../__Viewer/ViewerLayerState/ActiveFeatureProvider';
import {
  FeatureInspection,
  flowDirection,
} from '../../__FeatureBar/FeatureInspection/FeatureInspection.component';
import {
  flyToFeatureInspection,
  flyToFeaturesExtent,
} from '../../__FeatureBar/FeatureInspection/flyTo';
import { getNextIndex, getPrevIndex } from './utils';

type FeatureType = 'annotation' | 'focusArea';

interface AnnotationNavigationProps {
  navigationFeatures: (Annotation | FocusArea)[];
  featureType: FeatureType;
  height: number;
  name: string;
  onNavigation: (regionsId?: string) => void;
}

const initIndex = (
  activeFeature: FeatureRef | null,
  featureType: FeatureType,
  features: (Annotation | FocusArea)[]
): number | null => {
  if (activeFeature?.type !== featureType) return null;

  const index = features.findIndex((a) => a.id === activeFeature?.id);

  if (index === -1) return null;

  return index;
};

export function AnnotationSectionItemsNavigation({
  navigationFeatures,
  height,
  name,
  onNavigation,
  featureType,
}: AnnotationNavigationProps): ReactElement {
  const lastActiveFeature = useRef<{
    feature: Annotation | FocusArea;
    index: number;
  }>();
  const theme = useTheme();
  const { activeViewer } = useViewerController();
  const map = activeViewer.map;

  const activeFeature = useActiveFeature();
  const setActiveFeature = useSetActiveFeature();

  const [featureIndex, setFeatureIndex] = useState<number | null>(() => {
    const index = initIndex(activeFeature, featureType, navigationFeatures);

    if (index !== null) {
      lastActiveFeature.current = {
        feature: navigationFeatures[index],
        index,
      };
    }

    return index;
  });

  // update navigation index on delete and create ROI
  useEffect(() => {
    const newIdx = initIndex(activeFeature, featureType, navigationFeatures);
    setFeatureIndex(newIdx);
  }, [navigationFeatures, activeFeature, featureType]);

  const flyToPadding = useMemo(
    () => [
      theme.spacings.button,
      theme.spacings.button,
      theme.spacings.button,
      theme.spacings.button,
    ],
    [theme]
  );

  const handleFlyToFeature = useCallback(
    (feature: Annotation | FocusArea) => {
      setActiveFeature({
        id: feature.id,
        type: featureType,
      });
      flyToFeatureInspection(feature, height, map, flyToPadding);
    },
    [setActiveFeature, featureType, height, map, flyToPadding]
  );

  const handleFlyToFeaturesExtent = useCallback(() => {
    onNavigation();
    flyToFeaturesExtent(navigationFeatures, height, map, flyToPadding);
  }, [onNavigation, navigationFeatures, height, map, flyToPadding]);

  // Set the index to the last active feature when feature change if
  // still visible
  useEffect(() => {
    // Skip if we didn't have a previously set feature
    if (lastActiveFeature.current === undefined) return;

    // Skip if previous feature is still at the same index
    if (
      lastActiveFeature.current.feature.id ===
      navigationFeatures[lastActiveFeature.current.index]?.id
    ) {
      return;
    }

    const lastActiveFeatureIndex = navigationFeatures.findIndex(
      (feature) => feature.id === lastActiveFeature.current?.feature.id
    );

    const lastActiveFeatureFound = lastActiveFeatureIndex >= 0;
    const newIndex = lastActiveFeatureFound ? lastActiveFeatureIndex : null;

    if (newIndex !== null && lastActiveFeature.current !== undefined) {
      lastActiveFeature.current = {
        ...lastActiveFeature.current,
        index: newIndex,
      };
    }
    setFeatureIndex(newIndex);
  }, [navigationFeatures, handleFlyToFeature]);

  const onNavigationToFeature = (direction: flowDirection) => {
    const index =
      direction === 'prev'
        ? getPrevIndex(navigationFeatures, featureIndex)
        : getNextIndex(navigationFeatures, featureIndex);

    if (index >= navigationFeatures.length) {
      throw new Error('Invariant violation: navigation to non-existent index');
    }
    const feature = navigationFeatures[index];
    onNavigation(feature.id);
    // Keep track of last active feature for index recalculation
    lastActiveFeature.current = {
      feature,
      index,
    };
    setFeatureIndex(index);
    handleFlyToFeature(feature);
  };

  return (
    <FeatureInspection
      isGroupFocusable={featureType === 'annotation' ? true : false}
      label={{
        currentIndex: featureIndex,
        totalCount: navigationFeatures.length,
      }}
      name={name}
      onFlyToCategoryClick={handleFlyToFeaturesExtent}
      onNavigation={onNavigationToFeature}
      disableNavigation={false}
    />
  );
}

export default AnnotationSectionItemsNavigation;
