import {
  Button,
  Icon,
  IconButton,
  Input,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import { FirebaseError } from 'firebase/app';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement, useState } from 'react';
import RUOIcon from '../../../public/assets/ruo_icon.png';
import { useRedirectToNextRoute } from './hooks/useRedirectToNextRoute';
import { useValidation } from './Login.component';
import {
  $BackgroundWrapper,
  $BannerContent,
  $ChangePasswordWrapper,
  $ExpandedWrapper,
  $HeaderContainer,
  $LegalData,
  $LoginForm,
  $LoginMain,
  $LoginMainHeader,
  $LoginMainWrapper,
  $LoginWrapper,
  $Logo,
  $RUOContainer,
  $RUOText,
  $Separator,
  $SignInButtonProvider,
  $SignInContentWrapper,
  $SignInSeparator,
  $UnsupportedScreenBanner,
  $VersionContainer,
} from './Login.styles';
import { SignInFn } from './types';

const companyInfo = {
  name: 'Aignostics GmbH',
  street: 'Alt-Moabit',
  houseNumber: '73/73A',
  zipCode: '10555',
  city: 'Berlin',
  country: 'Germany',
};

export const UNSUPPORTED_SCREEN_SIZE_MSG =
  'Unsupported screen size. For correct display and optimal user experience, we recommend accessing Portal on a larger screen.';

export function SignInForm({
  signIn,
  buildTimestamp,
  logo,
  loginBackgroundSrc,
  version,
}: {
  signIn: SignInFn;
  buildTimestamp: string;
  logo: ReactElement;
  loginBackgroundSrc: string | undefined;
  version: string;
}): ReactElement {
  const [isExpanded, setExpanded] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('expanded') === 'true';
  });
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const { addSnackbar } = useSnackbarMutations();
  const redirectToNextRoute = useRedirectToNextRoute();
  const [email, changeEmail] = useValidation('');
  const [password, changePassword] = useValidation('');

  const [showBanner, setShowBanner] = useState(true);
  const handleSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await signIn({ provider: 'aignx', email, password });
      redirectToNextRoute();
    } catch (error) {
      if (!(error instanceof FirebaseError)) {
        addSnackbar({
          type: 'error',
          message: 'Authentication error',
        });
      } else if (
        ['auth/user-not-found', 'auth/wrong-password'].includes(error.code)
      ) {
        addSnackbar({
          type: 'error',
          message: 'Email and/or password is incorrect. Please try again',
        });
      } else {
        Sentry.captureException(error, {
          tags: {
            email,
            error: error.code,
          },
        });
        addSnackbar({
          type: 'error',
          message: error.message,
        });
      }
    }
  };

  const { name, street, houseNumber, zipCode, city, country } = companyInfo;
  const address = `${name} ${street} ${houseNumber} ${zipCode} ${city} - ${country} - ${format(new Date(buildTimestamp), 'yyyy-MM-dd')}`;

  return (
    <$LoginMainWrapper>
      <$BackgroundWrapper backgroundImageSrc={loginBackgroundSrc} />
      {showBanner && (
        <$UnsupportedScreenBanner>
          <$BannerContent>
            <Icon icon="AlertCircle" size="medium" color="warning" />
            <span>{UNSUPPORTED_SCREEN_SIZE_MSG}</span>
            <IconButton
              icon="X"
              onClick={() => {
                setShowBanner(false);
              }}
            />
          </$BannerContent>
        </$UnsupportedScreenBanner>
      )}
      <$HeaderContainer>
        <$RUOContainer>
          <img width="49" src={RUOIcon} />
          <$RUOText>
            For Research Use Only. Not for use in diagnostic procedures.
          </$RUOText>
        </$RUOContainer>
        <$Logo>{logo}</$Logo>
      </$HeaderContainer>
      <$LoginWrapper>
        <$LoginMain>
          <$LoginMainHeader>Sign In</$LoginMainHeader>
          <$LoginForm
            id="authentication-form"
            onSubmit={(e) => {
              e.preventDefault();
              void handleSubmit({ email, password });
            }}
            noValidate={true}
          >
            <$SignInButtonProvider
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e?.preventDefault();
                signIn({ provider: 'google' })
                  .then(() => {
                    redirectToNextRoute();
                  })
                  .catch((error) =>
                    addSnackbar({ type: 'error', message: error.message })
                  );
              }}
              type="button"
            >
              Sign in with Google
            </$SignInButtonProvider>
            <$SignInButtonProvider
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e?.preventDefault();
                signIn({ provider: 'microsoft' })
                  .then(() => {
                    redirectToNextRoute();
                  })
                  .catch((error) =>
                    addSnackbar({ type: 'error', message: error.message })
                  );
              }}
              type="button"
            >
              Sign in with Microsoft
            </$SignInButtonProvider>
            {!isExpanded && (
              <Button
                variant="ghost"
                id="sign-in-with-email-and-password"
                onClick={() => {
                  setExpanded(true);
                }}
              >
                Sign in with email and password
              </Button>
            )}
            <AnimatePresence>
              <$ExpandedWrapper
                isExpanded={isExpanded}
                initial={{ height: isExpanded ? 'auto' : 0 }}
                animate={{ height: isExpanded ? 'auto' : 0 }}
                exit={{ height: isExpanded ? 'auto' : 0 }}
              >
                <$SignInContentWrapper>
                  <VStack spacing="base" alignItems="stretch">
                    <Input
                      id="email"
                      sizeVariant="large"
                      key="aignx-email"
                      name="aignx-email"
                      type="email"
                      autoComplete="username"
                      aria-label="username"
                      placeholder="Email address"
                      value={email}
                      onChange={changeEmail}
                    />

                    <Input
                      id="password"
                      data-testid="password-input"
                      sizeVariant="large"
                      key="aignx-password"
                      name="aignx-password"
                      type={
                        passwordVisible || !isExpanded ? 'text' : 'password'
                      }
                      autoComplete="current-password"
                      aria-label="password"
                      placeholder="Password"
                      value={password}
                      onChange={changePassword}
                      innerIcon={passwordVisible ? 'EyeOff' : 'Eye'}
                      innerIconProps={{
                        role: 'button',
                        'aria-label': passwordVisible
                          ? 'hide-password'
                          : 'show-password',
                        size: 'input',
                        style: { cursor: 'pointer' },
                        onClick: () => {
                          setPasswordVisible((previous) => !previous);
                        },
                      }}
                    />
                    <Button
                      id="user-sign-in-button"
                      type="submit"
                      variant="primaryOutline"
                      disabled={!email || !password}
                    >
                      Sign in with Email
                    </Button>
                  </VStack>
                </$SignInContentWrapper>
                <$Separator />
                <$ChangePasswordWrapper>
                  <$SignInSeparator>Need a new password?</$SignInSeparator>
                  <Button
                    to="/login/reset-password"
                    id="user-sign-in-button"
                    type="submit"
                    variant="primaryOutline"
                  >
                    Create or change password
                  </Button>
                </$ChangePasswordWrapper>
              </$ExpandedWrapper>
            </AnimatePresence>
          </$LoginForm>
        </$LoginMain>
      </$LoginWrapper>
      <$VersionContainer>Version {version}</$VersionContainer>
      <$LegalData>
        <Icon icon="Legal" size="display" />
        <span>{address}</span>
      </$LegalData>
    </$LoginMainWrapper>
  );
}
