import {
  Button,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  PageLayout,
  Pagination,
  PaginationInfo,
  TableSkeleton,
  useFilters,
  usePagination,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useLazyQuery, useQuery } from '@apollo/client';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import noResultsSlideLibrary from '../../../assets/icons/noResultsSlideLibrary.svg';
import slideLibrary from '../../../assets/icons/SlideLibrary.svg';
import { useSetQueryParams } from '../../hooks';
import { PaginationType, Slide, UserWithRoles } from '../../types';
import { FilterKeysWithAssociation } from '../SubProject/Admin/AdminSubProjectSlides/AdminSubProjectSlides.component';
import { GET_SLIDES_QUERY } from './SlideLibrary.queries';
import {
  ActionsWrapper,
  EmptyContainer,
  PaginationWrapper,
  SearchBarContainer,
  SearchContainer,
  SearchFiltersContainer,
  SlideLibraryPageContainer,
  TableContainer,
} from './SlideLibrary.styles';
import { SlideLibraryFooter } from './SlideLibraryFooter.component';
import { SlideLibraryFilters } from './SlideLibrarySearch/SlideLibraryFilters.component';
import { SlideLibrarySearchInput } from './SlideLibrarySearch/SlideLibrarySearchInput.component';
import SlidesResultTable from './SlidesResultTable.component';
import { PAGE_FILTER_CONFIG, searchByValues } from './types';
import { areFiltersApplied, downloadSlidesCsv } from './utils';

export const PAGE_SIZE = 40;

export const SlideLibrary = ({
  getToken,
  rasterTileServerUrl,
  role,
  currentUser,
}: {
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  role: OrganizationRole;
  currentUser: UserWithRoles;
}): ReactElement => {
  const theme = useTheme();
  const [selection, setSelection] = useState<Array<Slide>>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [page, setPage] = usePagination(getPageFromQueryParams());
  const queryFiltersFromParams = getFiltersFromQueryParams({
    ...PAGE_FILTER_CONFIG,
    querySearch: { fallbackValue: '', type: 'string' },
    querySearchBy: { fallbackValue: '', type: 'string' },
  });

  const { addSnackbar } = useSnackbarMutations();
  const { querySearch, querySearchBy, ...queryParamsFilters } =
    queryFiltersFromParams;
  const [search, setSearch] = useState<string>(
    () => (querySearch as string) ?? ''
  );

  const [searchBy, setSearchBy] = useState<
    Array<{ value: string; placeholder: string }>
  >(() => {
    if (querySearchBy === '') return [];
    return ((querySearchBy as string) ?? '')
      .split(',')
      .reduce<
        Array<{ value: string; placeholder: string }>
      >((acc, searchByValue) => {
        const [value, placeholder] = searchByValue.split(':');

        if (value) {
          return [...acc, { value, placeholder }];
        } else {
          return acc;
        }
      }, []);
  });
  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIG,
    queryParamsFilters
  );

  const [sortBy, setSortBy] = useState<{
    column: string;
    direction: 'asc' | 'desc';
  } | null>(null);

  const setSortByOption = (
    value: {
      column: string;
      direction: 'asc' | 'desc';
    } | null
  ) => {
    setSortBy(value);
  };

  const onReset = () => {
    setSearch('');
    setSearchBy([]);
    filterProps.onReset();
  };

  const filtersApplied = areFiltersApplied(filters, search);

  const queryParams = useMemo(
    () => ({
      ...filters,
      querySearch: search,
      querySearchBy: searchBy.length
        ? searchBy
            .map(({ value, placeholder }) => `${value}:${placeholder}`)
            .join(',')
        : '',
    }),
    [filters, searchBy, search]
  );

  useSetQueryParams(queryParams);

  const queryFilters = {
    associations:
      (filters as Record<FilterKeysWithAssociation, string[]>).associations ??
      [],
    batches: filters.batches as string[],
    sortBy: sortBy?.column || 'name',
    sortDirection: sortBy?.direction || 'asc',
    objectivePowers: (filters.objectivePowers as string[]).map((value) =>
      parseFloat(value)
    ),
    samplePreparation: filters.samplePreparations as string[],
    sampleType: filters.sampleType as string[],
    scanners: (filters.scanners as string[]).map((value) => parseInt(value)),
    stainings: (filters.stainings as string[]).map((value) => parseInt(value)),
    tissues: (filters.tissues as string[]).map((value) => parseInt(value)),
    diseases: filters.diseases as string[],
    project: filters.project as string,
    subProject: filters.subProject as string,
    searchBy: (searchBy.length ? searchBy : searchByValues).map(
      ({ value }) => value
    ),
    search,
  };

  const {
    data: fetchedWsis,
    previousData,
    loading,
  } = useQuery<{
    slides: PaginationType<Slide>;
  }>(GET_SLIDES_QUERY, {
    skip: !filtersApplied && search.length === 0,
    variables: {
      page,
      pageSize: PAGE_SIZE,
      ...queryFilters,
    },
    onError: () =>
      addSnackbar({
        message: 'Error while trying to fetch slides please try again',
        type: 'error',
      }),
  });

  const [getSlides] = useLazyQuery<{
    slides: PaginationType<Slide>;
  }>(GET_SLIDES_QUERY, {
    variables: queryFilters,
  });
  const wsis = (fetchedWsis || previousData)?.slides;

  const data = filtersApplied || loading ? wsis : undefined;
  return (
    <PageLayout title="Slide Library" fullWidth>
      <SlideLibraryPageContainer>
        <SearchContainer>
          <SearchFiltersContainer>
            <SearchBarContainer>
              <SlideLibrarySearchInput
                search={search}
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                setSearch={(value) => {
                  filterProps.onChange({ ...filters, search: value });
                  setSearch(value);
                }}
              />
            </SearchBarContainer>
            <SlideLibraryFilters
              filterProps={{
                ...filterProps,
                onReset: () => {
                  onReset();
                  setSelection([]);
                  setIsAllSelected(false);
                },
              }}
              onChange={(filters) => {
                setPage(1);
                setSelection([]);
                filterProps.onChange(filters);
                setIsAllSelected(false);
              }}
              showHeader={true}
              projectId={filters.project}
            />
          </SearchFiltersContainer>
        </SearchContainer>
        {!filtersApplied && (
          <EmptyContainer>
            <img src={slideLibrary} />
            <p style={{ ...theme.fontStyles.baseBold }}>
              Apply filters to search slides
            </p>
          </EmptyContainer>
        )}

        {filtersApplied && !loading && data?.nodes.length === 0 && (
          <EmptyContainer>
            <img src={noResultsSlideLibrary} />
            <h2 style={{ ...theme.fontStyles.baseBold }}>No Results Found</h2>
          </EmptyContainer>
        )}

        {loading && (
          <div style={{ marginTop: 32 }}>
            <TableSkeleton rows={PAGE_SIZE} />
          </div>
        )}
        {filtersApplied && data?.nodes.length && !loading ? (
          <>
            <ActionsWrapper>
              <PaginationInfo
                totalCount={data?.pageInfo.totalElements}
                currentPage={page}
                itemsPerPage={PAGE_SIZE}
              />

              <Button
                icon="Download"
                small
                onClick={() => {
                  downloadSlidesCsv(data?.nodes);
                }}
              >
                Download CSV
              </Button>
            </ActionsWrapper>
            <TableContainer elements={data.nodes.length}>
              <SlidesResultTable
                getToken={getToken}
                rasterTileServerUrl={rasterTileServerUrl}
                selection={selection}
                isAllSelected={isAllSelected}
                data={data}
                sortBy={sortBy}
                setSortByOption={setSortByOption}
                setSelection={(wsi, value) => {
                  if (value) {
                    setSelection((prevState) => [...prevState, wsi]);
                  } else {
                    setIsAllSelected(false);

                    setSelection((prevState) =>
                      prevState.filter(({ id }) => id !== wsi.id)
                    );
                  }
                }}
                onSelectMultiple={async (e) => {
                  switch (e) {
                    case 'page': {
                      setSelection(data?.nodes.map((wsi) => wsi));
                      setIsAllSelected(false);
                      break;
                    }
                    case 'none': {
                      setSelection([]);
                      setIsAllSelected(false);
                      break;
                    }
                    case 'all': {
                      const result = await getSlides();
                      if (result.data?.slides) {
                        setSelection(result.data?.slides.nodes);
                      }
                      setIsAllSelected(true);
                      break;
                    }
                  }
                }}
              />

              {data?.pageInfo.totalPages > 1 && (
                <PaginationWrapper>
                  <Pagination
                    currentPage={page}
                    totalPages={data?.pageInfo.totalPages}
                    onPageChanged={setPage}
                  />
                </PaginationWrapper>
              )}
            </TableContainer>
          </>
        ) : null}
        {selection.length > 0 && (
          <SlideLibraryFooter
            selection={selection}
            currentUser={currentUser}
            role={role}
          />
        )}
      </SlideLibraryPageContainer>
    </PageLayout>
  );
};
