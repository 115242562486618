import {
  Button,
  Checkbox,
  HStack,
  Input,
  VStack,
} from '@aignostics/components';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import * as uuid from 'uuid';

const $AdminSubProjectSlidesImportFormNotice = styled.div`
  border-radius: ${({ theme }) => `${theme.spacings[2]}px`};
  background: rgba(255, 188, 55, 0.2);
  padding: ${({ theme }) => `${theme.spacings[16]}px`};
  ${({ theme }) => theme.fontStyles.small};
`;

interface UpdateCopySlides {
  srcSubProjectId: string;
  dstSubProjectId: string;
  includeAnnotations?: boolean;
  includeAnnotationCategories?: boolean;
  includeFocusArea?: boolean;
}

const ADMIN_SUBPROJECT_SLIDES_IMPORT_FORM_NOTICE = `Interactive and static overlays are globally saved per slide and
        automatically included when the slides are imported.`;

const AdminSubProjectSlidesImportForm = ({
  subprojectId,
  onSubmit,
  onCancel,
}: {
  subprojectId: string;
  onSubmit: (values: UpdateCopySlides) => void;
  onCancel: () => void;
}): ReactElement => {
  const [includeAnnotations, setIncludeAnnotations] = useState(false);
  const [includeAnnotationCategories, setIncludeAnnotationCategories] =
    useState(false);
  const [includeFocusArea, setIncludeFocusArea] = useState(false);
  const [srcSubProjectId, setSrcSubProjectId] = useState('');

  const handleSubmit = () => {
    const values: UpdateCopySlides = {
      srcSubProjectId,
      dstSubProjectId: subprojectId,
      includeAnnotations,
      includeAnnotationCategories,
      includeFocusArea,
    };
    onSubmit(values);
  };

  const checkboxStyle = {
    cursor: 'pointer',
  };

  return (
    <VStack
      role="form"
      area-label="Import Slides"
      alignItems="stretch"
      spacing="24"
    >
      <Checkbox
        id="includeAnnotations"
        label="Include Annotations"
        checked={includeAnnotations}
        onChange={setIncludeAnnotations}
        style={checkboxStyle}
        inline={true}
      />
      <Checkbox
        id="includeAnnotationCategories"
        label="Include Annotation Categories"
        checked={includeAnnotationCategories}
        onChange={setIncludeAnnotationCategories}
        style={checkboxStyle}
        inline={true}
      />
      <Checkbox
        id="includeFocusArea"
        label="Include Focus Areas"
        checked={includeFocusArea}
        onChange={setIncludeFocusArea}
        style={checkboxStyle}
        inline={true}
      />
      <$AdminSubProjectSlidesImportFormNotice>
        {ADMIN_SUBPROJECT_SLIDES_IMPORT_FORM_NOTICE}
      </$AdminSubProjectSlidesImportFormNotice>
      <Input
        label="Subproject ID"
        id="srcSubProjectId"
        type="text"
        placeholder="00000000-0000-0000-0000-000000000000"
        value={srcSubProjectId}
        onChange={setSrcSubProjectId}
        required
      />
      <HStack spacing="8">
        <Button
          disabled={!uuid.validate(srcSubProjectId)}
          type="button"
          variant="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button type="button" variant="primaryOutline" onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </VStack>
  );
};

export default AdminSubProjectSlidesImportForm;
