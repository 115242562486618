"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MementoCache = void 0;
const core_1 = require("@apollo/client/core");
/** Cache abstraction with undo / redo functionality. */
class MementoCache extends core_1.InMemoryCache {
    constructor(config) {
        super(config);
        this.selectors = config.selectors;
    }
    selectors;
    past = [];
    future = [];
    canUndo = () => this.past.length > 0;
    canRedo = () => this.future.length > 0;
    undo = async () => {
        if (this.past.length === 0) {
            return;
        }
        this.future = [...this.future, this.extract()];
        const present = this.past.pop();
        if (present) {
            await this.reset();
            this.restore(present);
            super.broadcastWatches();
        }
    };
    redo = async () => {
        if (this.future.length === 0) {
            return;
        }
        this.past = [...this.past, this.extract()];
        const present = this.future.pop();
        if (present) {
            await this.reset();
            this.restore(present);
            super.broadcastWatches();
        }
    };
    mementoReset = () => {
        this.past = [];
        this.future = [];
    };
    // eslint-disable-next-line sonarjs/cognitive-complexity
    write = (options) => {
        /** Entity of current write operation */
        const currentEntity = options.dataId?.split(':')[0];
        /** Create new history entry */
        const writeHistory = () => {
            const prevPresent = this.extract();
            this.past.push(prevPresent);
            this.future = [];
        };
        for (let i = 0; i < this.selectors.length; i++) {
            const { entity, fields } = this.selectors[i];
            if (entity === currentEntity) {
                if (fields) {
                    for (let j = 0; j < fields.length; j++) {
                        const field = fields[j];
                        if (field in options.result) {
                            writeHistory();
                            break;
                        }
                    }
                }
                else {
                    writeHistory();
                    break;
                }
            }
        }
        return super.write(options);
    };
}
exports.MementoCache = MementoCache;
