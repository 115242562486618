import {
  Button,
  Checkbox,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  useSnackbarMutations,
} from '@aignostics/components';
import { useMutation } from '@apollo/client';
import { parseISO } from 'date-fns';
import React, { VoidFunctionComponent, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { RegistrationGroups } from '../../../../../types';
import { READ_SUBPROJECT_SLIDES } from '../READ_SUBPROJECT_SLIDES.queries';
import { SET_SUB_PROJECT_REGISTRATION_MUTATION } from '../SET_SUB_PROJECT_REGISTRATION_MUTATION';
import {
  $ButtonsContainer,
  $ModalTitle,
  $ModalWrapper,
} from './ImageRegistrationModal.styles';

const RegistrationTableHeader = styled(TableHeader)`
  :last-child {
    text-align: right;
  }
`;

const RegistrationTableCell = styled(TableCell)`
  > div {
    align-items: end;
  }
`;

const dateFormatter = new Intl.DateTimeFormat(navigator.languages.slice(), {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

interface ImageRegistrationModalArgs {
  isOpen: boolean;
  onClose: () => void;
  subprojectId: string;
  registrationGroups: RegistrationGroups | undefined;
  slidesCount: number | undefined;
}

export const ImageRegistrationModal: VoidFunctionComponent<
  ImageRegistrationModalArgs
> = ({ isOpen, onClose, subprojectId, registrationGroups, slidesCount }) => {
  const theme = useTheme();
  const [jobChecked, setChecked] = useState<string | null>(null);
  const { addSnackbar } = useSnackbarMutations();

  const onModalClose = () => {
    setChecked(null);
    onClose();
  };
  const [setRegistration, { loading }] = useMutation(
    SET_SUB_PROJECT_REGISTRATION_MUTATION,
    {
      refetchQueries: [READ_SUBPROJECT_SLIDES],
      onCompleted: (data) => {
        addSnackbar({
          message:
            data.setSubProjectRegistration === slidesCount
              ? 'Image registration coefficient successfully updated.'
              : `Registration coefficient was successfully applied on all ${data.setSubProjectRegistration} eligible slides.`,
          type: 'success',
        });
        onModalClose();
      },
      onError: () => {
        addSnackbar({
          message: `Error happend while trying to apply registration`,
          type: 'error',
        });
      },
    }
  );

  return (
    <Modal
      size={'large'}
      isVisible={isOpen}
      onClose={onModalClose}
      aria-labelledby="imageRegistrationModal"
      header={
        <$ModalTitle id="imageRegistrationModal">
          Apply image registration
        </$ModalTitle>
      }
      footer={
        <$ButtonsContainer>
          <Button onClick={onModalClose}>Cancel</Button>
          <Button
            onClick={async () => {
              await setRegistration({
                variables: {
                  subprojectId,
                  registrationGroupId: jobChecked,
                },
              });
            }}
            disabled={jobChecked === null || loading}
          >
            Apply
          </Button>
        </$ButtonsContainer>
      }
    >
      <$ModalWrapper>
        <Table>
          <TableHead style={{ position: 'sticky', top: 0, zIndex: 9999 }}>
            <TableRow>
              {['Coefficient', 'Date created', 'Apply'].map(
                (headerElement, index) => {
                  return (
                    <RegistrationTableHeader
                      key={index}
                      style={{ padding: theme.spacings[16] }}
                    >
                      {headerElement}
                    </RegistrationTableHeader>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {registrationGroups?.map((group) => (
              <TableRow key={group.registrationGroupId}>
                <TableCell style={{ padding: theme.spacings[16] }}>
                  {group.registrationGroupName}
                </TableCell>

                <TableCell style={{ padding: theme.spacings[16] }}>
                  {group.triggeredAt &&
                    dateFormatter.format(
                      parseISO(group.triggeredAt, { additionalDigits: 2 })
                    )}
                </TableCell>

                <RegistrationTableCell style={{ padding: theme.spacings[16] }}>
                  <Checkbox
                    checked={group.registrationGroupId === jobChecked}
                    onChange={(checked) => {
                      if (checked) {
                        setChecked(group.registrationGroupId);
                      } else {
                        setChecked(null);
                      }
                    }}
                  />
                </RegistrationTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </$ModalWrapper>
    </Modal>
  );
};
