import { Map } from 'ol';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Wsi } from '../../../../api-types';
import { useViewerController } from '../../ViewerController';
import { computeScaleValues } from '../utils';
import { $ScaleBar, $ScaleContainer } from './Scale.styles';

const Scale: FunctionComponent<{ wsi: Wsi }> = ({ wsi }) => {
  const {
    activeViewer: { map },
  } = useViewerController();
  const {
    // use mppx as default mpp value
    mppx: mpp,
    // max native zoom from wsi
    maxZoom: maxNativeZoom,
  } = wsi;

  const [width, setWidth] = useState(0);
  const [legend, setLegend] = useState('');

  const updateZoom = useCallback(
    (map: Map) => {
      const zoom = map.getView().getZoom() || 0;

      const { legend: l, width: w } = computeScaleValues(
        mpp,
        zoom,
        maxNativeZoom
      );
      setLegend(l);
      setWidth(w);
    },
    [maxNativeZoom, mpp]
  );

  useEffect(() => {
    const updateZoomCallback = () => {
      updateZoom(map);
    };
    // Use event listener to synchronously re-compute the scale on map update
    map.on('postrender', updateZoomCallback);

    return () => {
      map.un('postrender', updateZoomCallback);
    };
  }, [map, updateZoom]);

  return (
    <$ScaleContainer data-testid="scale">
      <$ScaleBar style={{ width }}>{legend}</$ScaleBar>
    </$ScaleContainer>
  );
};

export default Scale;
