import { ViewerIndex } from '@aignostics/components';
import { debounce } from 'lodash';
import { Map as OLMap, View } from 'ol';
import { MapOptions } from 'ol/Map';
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { DefaultTheme, useTheme } from 'styled-components';
import { Annotation, Wsi } from '../../../api-types';
import { DrawingMode } from '../../__Features/Drawing';
import {
  defaultInteractions,
  flyToFeature,
  getOLCoordinates,
  getProjection,
  getResolutions,
} from '../OpenLayers/utils';
import {
  getViewersParamsFromOnloadUrl,
  updateSearchStringWithViewersParams,
} from '../ViewerLayerState/viewersLayersParams';
import {
  ExtendedViewerParams,
  ViewerLayersParams,
} from '../ViewerLayerState/viewersLayersParams/ViewerLayersParamsType';
import getObjectiveMaxZoom from '../utils/getObjectiveMaxZoom';
import {
  GetFeatureExtent,
  getInitialLocation,
} from './utils/getInitialLocation';

export interface ViewerState {
  map: OLMap;
}

export type AddSplitViewPanel = () => void;
export type RemoveSplitViewPanel = (index?: ViewerIndex) => void;
export interface ViewerControllerParams {
  addSplitViewPanel: AddSplitViewPanel;
  removeSplitViewPanel: RemoveSplitViewPanel;
  activeViewer: ViewerState;
  activeViewerIndex: ViewerIndex;
  setActiveViewerIndex: (index: ViewerIndex) => void;
  viewers: ExtendedViewerParams[];
  maps: OLMap[];
  setActiveParams: (params: Partial<ViewerLayersParams>) => void;
  setSelectedFeature: (newSelectedFeature: Annotation | null) => void;
  setDrawingMode: (newDrawingMode: DrawingMode) => void;
}

const NAVIGATE_DEBOUNCED = 200;

/**
 * Holds the current viewer state (split or not) and the maps of all
 * viewers.
 *
 * Accessed through useViewerController
 */
export function ViewerControllerProvider({
  children,
  wsi,
  getFeatureExtent,
  isROIEnabled,
}: {
  children: ReactNode;
  wsi: Wsi;
  getFeatureExtent: GetFeatureExtent;
  isROIEnabled: boolean;
}): ReactElement {
  const theme = useTheme();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const wsiId = wsi.id;

  const previousWsiId = useRef<string | undefined>(undefined);

  const view = useMemo(() => {
    return buildInitialView(
      wsi.height,
      wsi.width,
      wsi.maxZoom,
      wsi.objectivePower
    );
  }, [wsi.width, wsi.height, wsi.maxZoom, wsi.objectivePower]);

  const initialViewersLayersStates: ViewerLayersParams[] = useMemo(
    () => getViewersParamsFromOnloadUrl().params,
    []
  );

  const [activeViewerIndex, setActiveViewerIndex] = useState<ViewerIndex>(0);
  const [viewers, setViewers] = useState<ExtendedViewerParams[]>(
    initialViewersLayersStates.map((viewerParams) => ({
      selectedFeature: null,
      viewerParams,
      drawingMode: { mode: 'off' },
    }))
  );

  const maps = useMemo(() => {
    const maps = [];
    for (let index = 0; index < viewers.length; index++) {
      maps.push(initMap(view));
    }
    return maps;
  }, [view, viewers.length]);

  useEffect(() => {
    const isMountingNewSlide = previousWsiId.current !== wsiId;
    previousWsiId.current = wsiId;
    if (!isMountingNewSlide) return;
    void goToInitialLocation(getFeatureExtent, wsi, view, theme, isROIEnabled);
  }, [getFeatureExtent, theme, view, wsi, isROIEnabled, previousWsiId, wsiId]);

  const activeViewer = useMemo(
    () => ({
      map: maps[activeViewerIndex],
    }),
    [activeViewerIndex, maps]
  );

  const setActiveParams = useCallback(
    (newParams: Partial<ViewerLayersParams>) => {
      setViewers((viewers) => {
        const updatedViewers: ExtendedViewerParams[] = viewers.map((v, i) => {
          const { viewerParams } = v;
          if (i === activeViewerIndex) {
            return {
              ...v,
              viewerParams: { ...viewerParams, ...newParams },
            };
          }
          return v;
        });
        return updatedViewers;
      });
    },
    [activeViewerIndex]
  );

  const setSelectedFeature = useCallback(
    (newSelectedFeature: Annotation | null) => {
      setViewers((viewers) => {
        const updatedSelectedFeature: ExtendedViewerParams[] = viewers.map(
          (v, i) => {
            if (i === activeViewerIndex) {
              return { ...v, selectedFeature: newSelectedFeature };
            }
            return v;
          }
        );

        return updatedSelectedFeature;
      });
    },
    [activeViewerIndex]
  );

  const setDrawingMode = useCallback(
    (newDrawingMode: DrawingMode) => {
      setViewers((viewers) => {
        const updatedDrawingMode: ExtendedViewerParams[] = viewers.map(
          (v, i) => {
            if (i === activeViewerIndex) {
              return { ...v, drawingMode: newDrawingMode };
            }
            return v;
          }
        );

        return updatedDrawingMode;
      });
    },
    [activeViewerIndex]
  );

  const addSplitViewPanel = () => {
    const viewer = viewers[activeViewerIndex];
    setViewers([...viewers, viewer]);
  };

  const getLastNonActiveIndex = (): ViewerIndex => {
    const lastIndex = viewers.length - 1;
    const lastNonActiveIndex =
      lastIndex === activeViewerIndex ? lastIndex - 1 : lastIndex;
    return lastNonActiveIndex as ViewerIndex;
  };

  const removeSplitViewPanel = (index?: ViewerIndex) => {
    if (!index) index = getLastNonActiveIndex();
    setActiveViewerIndex(0);
    setViewers(viewers.filter((_: unknown, i: number) => index !== i));
  };

  const navigateDebounced = useMemo(
    () =>
      debounce(
        (newLocation: To, options: NavigateOptions) => {
          navigate(newLocation, options);
        },
        NAVIGATE_DEBOUNCED,
        { trailing: true }
      ),
    [navigate]
  );

  useEffect(() => {
    const updatedSearchParams = updateSearchStringWithViewersParams(
      viewers.map(({ viewerParams }) => viewerParams)
    );
    navigateDebounced(`${pathname}?${updatedSearchParams}`, {
      replace: true,
    });

    return () => {
      navigateDebounced.cancel();
    };
  }, [navigateDebounced, pathname, viewers]);

  return (
    <ViewerControllerStateContext.Provider
      value={{
        activeViewerIndex,
        setActiveViewerIndex,
        activeViewer,
        viewers,
        maps,
        setActiveParams,
        setSelectedFeature,
        setDrawingMode,
        addSplitViewPanel,
        removeSplitViewPanel,
      }}
    >
      {children}
    </ViewerControllerStateContext.Provider>
  );
}

export const ViewerControllerStateContext =
  createContext<ViewerControllerParams | null>(null);

async function goToInitialLocation(
  getFeatureExtent: GetFeatureExtent,
  wsi: Wsi,
  view: View,
  theme: DefaultTheme,
  isROIEnabled: boolean
) {
  const initialLocation = await getInitialLocation(
    getFeatureExtent,
    wsi,
    isROIEnabled
  );

  if (Array.isArray(initialLocation)) {
    if (initialLocation.length === 3) {
      const [x, y, zoom] = initialLocation;
      view.setCenter(getOLCoordinates([x, y], wsi.height));
      view.setZoom(zoom);
    } else {
      view.fit(initialLocation, {
        padding: [
          theme.spacings.button,
          theme.spacings.button,
          theme.spacings.button,
          theme.spacings.button,
        ],
      });
    }
  } else {
    flyToFeature({
      view,
      duration: 0,
      feature: initialLocation,
      width: wsi.width,
      height: wsi.height,
      theme,
    });
  }
}

export function useViewerController(): ViewerControllerParams {
  const viewerController = useContext(ViewerControllerStateContext);
  if (viewerController === null) {
    throw new Error(
      'useViewerController can only be used in a descendant of ViewerControllerProvider'
    );
  }

  return viewerController;
}

export function useActiveViewerParams(): ViewerLayersParams {
  const viewerController = useContext(ViewerControllerStateContext);

  if (viewerController === null) {
    throw new Error(
      'useActiveViewerParams can only be used in a descendant of ViewerControllerProvider'
    );
  }

  const { activeViewerIndex, viewers } = viewerController;

  return viewers[activeViewerIndex].viewerParams;
}

export function useSelectedFeature(): Annotation | null {
  const viewerController = useContext(ViewerControllerStateContext);
  if (viewerController === null) {
    throw new Error(
      'useSelectedFeature can only be used in a descendant of ViewerControllerProvider'
    );
  }
  const { activeViewerIndex, viewers } = viewerController;

  return viewers[activeViewerIndex].selectedFeature;
}

export function useSetActiveViewerParams(): ViewerControllerParams['setActiveParams'] {
  const viewerController = useContext(ViewerControllerStateContext);
  if (viewerController === null) {
    throw new Error(
      'useSetActiveViewerParams can only be used in a descendant of ViewerControllerProvider'
    );
  }

  return viewerController.setActiveParams;
}

export function useSetSelectedFeature(): ViewerControllerParams['setSelectedFeature'] {
  const viewerController = useContext(ViewerControllerStateContext);
  if (viewerController === null) {
    throw new Error(
      'useSetSelectedFeature can only be used in a descendant of ViewerControllerProvider'
    );
  }

  return viewerController.setSelectedFeature;
}

export function useSetDrawingMode(): ViewerControllerParams['setDrawingMode'] {
  const viewerController = useContext(ViewerControllerStateContext);
  if (viewerController === null) {
    throw new Error(
      'useSetDrawingMode can only be used in a descendant of ViewerControllerProvider'
    );
  }

  return viewerController.setDrawingMode;
}

function buildInitialView(
  width: number,
  height: number,
  maxZoom: number,
  objectivePower: number
): View {
  const objectiveMaxZoom = getObjectiveMaxZoom({ maxZoom, objectivePower });

  const view = new View({
    center: [0, 0],
    zoom: 0,
    projection: getProjection(width, height),
    resolutions: getResolutions(maxZoom, objectiveMaxZoom),
    minZoom: 0,
    maxZoom: objectiveMaxZoom,
  });
  return view;
}

function initMap(view: View): OLMap {
  const leftMapInteractions = defaultInteractions();

  const mapOptions: MapOptions = {
    view,
    layers: [],
    controls: [],
    overlays: [],
    interactions: leftMapInteractions,
  };

  const map = new OLMap(mapOptions);

  return map;
}
