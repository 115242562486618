import { gql } from '@apollo/client';

export const GET_SLIDE_LIBRARY_FILTERS = gql`
  query GET_FILTERS {
    associations {
      id
      name
    }
    batches {
      id
      name
    }
    stainings {
      id
      name
    }
    tissues {
      id
      name
    }
    scanners {
      id
      vendor
      model
    }
    diseases {
      name
    }
    sampleTypes {
      name
    }
    samplePreparations {
      name
    }
    projects(isAdminView: true) {
      nodes {
        id
        name
      }
    }
  }
`;

export const FETCH_SUBPROJECTS_LIST = gql`
  query GET_SUBPROJECTS_LIST($projectId: ID!, $isAdminView: Boolean!) {
    project(id: $projectId) {
      id
      subProjects(isAdminView: $isAdminView) {
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const GET_SLIDES_QUERY = gql`
  query FETCH_WSIS(
    $page: Int
    $pageSize: Int
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [Int]!
    $stainings: [Int]!
    $tissues: [Int]!
    $diseases: [String]!
    $samplePreparation: [String]!
    $sampleType: [String]!
    $project: String!
    $subProject: String!
    $sortBy: SlideLibrarySortValues!
    $sortDirection: SlideLibrarySortDirection!
    $searchBy: [SlideSearchByValues]!
  ) {
    slides(
      page: $page
      pageSize: $pageSize
      associations: $associations
      batches: $batches
      case: $case
      objectivePowers: $objectivePowers
      scanners: $scanners
      stainings: $stainings
      tissues: $tissues
      search: $search
      diseases: $diseases
      samplePreparation: $samplePreparation
      sampleType: $sampleType
      project: $project
      subproject: $subProject
      sortBy: $sortBy
      sortDirection: $sortDirection
      searchBy: $searchBy
    ) {
      nodes {
        id
        name
        originalName: name(type: original)
        batchName
        objectivePower
        tissue
        staining
        fluorescence {
          id
          name
          staining
        }
        case {
          id
          sid
        }
        scanner {
          id
          vendor
          model
        }
        association {
          id
          name
        }
      }
      pageInfo {
        page
        totalElements
        totalPages
      }
    }
  }
`;
