import { Pill } from '@aignostics/components';
import React, { ReactElement, useMemo } from 'react';
import styled from 'styled-components';

const TransferCloudSlidesCrumbsContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
`;

type Crumb = {
  path: string;
  name: string;
};

type TransferCloudSlidesCrumbsProps = {
  path: string;
  bucket: string;
  onPathUpdate: (path: string) => void;
};

const TransferCloudSlidesCrumbs = ({
  path,
  bucket,
  onPathUpdate,
}: TransferCloudSlidesCrumbsProps): ReactElement => {
  const crumbs = useMemo(() => {
    const pathArray = path.split('/');
    const filteredPathArray = pathArray.filter(
      (str, index) => str.length > 0 && pathArray.length - 1 > index
    );
    return filteredPathArray.reduce((crumbs: Crumb[], key, index) => {
      const prefix = index > 0 ? crumbs[index - 1].path : '';
      const crumb = {
        path: `${prefix}${key}/`,
        name: key,
      };
      return [...crumbs, crumb];
    }, []);
  }, [path]);

  return (
    <TransferCloudSlidesCrumbsContainer>
      <Pill
        style={{ cursor: 'pointer' }}
        text={bucket}
        icon="ArrowLeft"
        onClick={() => {
          onPathUpdate('');
        }}
        key="arrow-left"
      />
      {crumbs.map((crumb) =>
        crumb.path !== path ? (
          <Pill
            key={crumb.path}
            style={{
              marginLeft: '8px',
              cursor: 'pointer',
            }}
            text={crumb.name}
            role="button"
            onClick={() => {
              onPathUpdate(crumb.path);
            }}
          />
        ) : (
          <Pill
            key={crumb.path}
            style={{
              marginLeft: '8px',
              cursor: 'default',
            }}
            text={crumb.name}
          />
        )
      )}
    </TransferCloudSlidesCrumbsContainer>
  );
};

export default TransferCloudSlidesCrumbs;
