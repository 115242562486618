import styled from 'styled-components';

export const $ScaleContainer = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.transparentDark};
  bottom: ${({ theme }) => theme.spacings.small}px;
  right: ${({ theme }) => theme.spacings.small}px;
  z-index: 10;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacings.large}px;
`;

export const $ScaleBar = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacings.small}px;
  ${({ theme }) => theme.fontStyles.smallBold};
  color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.spacings.base}px;
  border-left: 1px solid white;
  border-right: 1px solid white;

  &:before,
  &:after {
    content: '';
    display: flex;
    flex-grow: 1;
    width: auto;
    height: 1px;
    background: ${({ theme }) => theme.colors.white};
  }

  &:before {
    margin-right: ${({ theme }) => theme.spacings.tiny}px;
  }

  &:after {
    margin-left: ${({ theme }) => theme.spacings.tiny}px;
  }
`;
