import { gql } from '@apollo/client';

export const FETCH_ANNOTATION_CATEGORIES_FILTER_DATA = gql`
  query GET_ANNOTATION_CATEGROY_SETS(
    $page: Int
    $pageSize: Int
    $sortBy: sortByAnnotationCategorySetsValues
    $sortDirection: sortDirection
  ) {
    annotationCategorySets(
      page: $page
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        code
        name
        moduleCount
        categoryCount
        modules {
          moduleId
          moduleName
          categoryCount
        }
      }
    }
  }
`;
