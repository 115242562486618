import React from 'react';
import styled from 'styled-components';
import { VStack } from '../Stack';
import { placeholderLoading } from '../animations/placeholderLoading';
export const TableRowSkeleton = styled.div `
  animation: ${({ theme }) => placeholderLoading(theme)} 2s ease-in-out infinite;
  background-color: ${({ theme }) => theme.colors.light};
  height: ${({ theme }) => `${theme.spacings.touch}px`};
  width: 100%;
`;
function* range(from, to) {
    for (let i = from; i < to; i++) {
        yield i;
    }
}
export function TableSkeleton({ rows }) {
    return (React.createElement(VStack, { spacing: "8", style: { width: '100%' } }, Array.from(range(0, rows)).map((n) => (React.createElement(TableRowSkeleton, { key: n })))));
}
