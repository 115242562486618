import React, { Dispatch, ReactElement } from 'react';

import { TransferCloudSlideStep } from '../Admin.TransferCloudSlides.component';
import { SetFileMetadataStep } from '../SetFileMetadataStep';
import { RequestMetadataStep } from './RequestMetadataStep.component';
import { TransferFilesStep } from './TransferFiles.component';

/**
 * Renders the current step of the upload slides wizard. Handles step changing.
 */
export function TransferCloudSlidesSteps({
  step,
  onStepChange,
  authToken,
  apiUrl,
}: {
  step: TransferCloudSlideStep;
  onStepChange: Dispatch<TransferCloudSlideStep>;
  authToken: string | null;
  apiUrl: string;
}): ReactElement {
  switch (step.name) {
    case 'requestMetadata': // 1 step
      return (
        <RequestMetadataStep
          files={step.files}
          onFetchedMetadata={(filesWithMetadata) => {
            onStepChange({
              files: filesWithMetadata,
              name: 'setFileMetadata',
              title: 'Slides Metadata',
            });
          }}
        />
      );

    case 'setFileMetadata': // 2 step
      return (
        <SetFileMetadataStep
          apiUrl={apiUrl}
          authToken={authToken}
          files={step.files}
          submitLabel="transfer"
          onSuccessfulCreate={({ createOnboardingBatch: { batchId } }) => {
            onStepChange({
              name: 'transferFiles',
              title: 'Transfer Slides',
              batchId,
            });
          }}
        />
      );

    case 'transferFiles': // 3 step
      return <TransferFilesStep batchId={step.batchId} />;
  }
}
