import { useSnackbarMutations } from '@aignostics/components';
import { useMutation } from '@apollo/client';
import { Polygon } from 'geojson';
import { Map as OLMap } from 'ol';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import * as uuid from 'uuid';
import { CREATE_ROI } from '../../../../../graphql/mutations/CREATE_ROI';
import { FRAGMENT_FOCUS_AREA_ON_WSI } from '../../../../../graphql/queries';
import { useRegionsSettingsStore } from '../../../../__Features/Annotations/FocusArea/useRegionsSettingsStore';
import { SlideRouteParams } from '../../../Slide';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
} from '../../../ViewerController';
import StampLayer from './Stamp/Stamp.layer';

interface FocusAreaDrawingLayerProps {
  map: OLMap;
  height: number;
  maxZoom: number;
  size: number;
  disableDrawing: () => void;
}

/**
 * Returns the correct drawing tool layer for the given drawing mode
 */
const RegionsDrawingLayer = ({
  height,
  maxZoom,
  map,
  size,
  disableDrawing,
}: FocusAreaDrawingLayerProps): ReactElement | null => {
  const { subProjectId, wsiId } = useParams<
    keyof SlideRouteParams
  >() as SlideRouteParams;
  const { addSnackbar } = useSnackbarMutations();
  const setActiveViewerParams = useSetActiveViewerParams();
  const { visibleRegions } = useActiveViewerParams();
  const { setSelectedRegionId } = useRegionsSettingsStore((state) => ({
    setSelectedRegionId: state.setSelectedRegionId,
  }));

  const [createRegion] = useMutation(CREATE_ROI, {
    onCompleted: () => {
      addSnackbar({
        message: 'The region was created successfully',
        type: 'success',
      });
      disableDrawing();
    },
  });

  const addRegionOfInterest = (polygon: Polygon) => {
    const regionId = uuid.v4();
    void createRegion({
      variables: { subProjectId, wsiId, polygon, regionId },
      update: (cache) => {
        cache.modify({
          id: `WSI:${wsiId}`,
          fields: {
            regionsOfInterest: (prevRefs) => {
              const newRef = cache.writeFragment({
                fragment: FRAGMENT_FOCUS_AREA_ON_WSI,
                data: {
                  __typename: 'RegionOfInterest',
                  id: regionId,
                  type: 'Feature',
                  geometry: polygon,
                },
              });

              if (!newRef) return prevRefs;

              return newRef ? [...prevRefs, newRef] : prevRefs;
            },
          },
        });
      },
      onError: (error) => {
        addSnackbar({ message: error.message, type: 'error' });
      },
    }).then(() => {
      setActiveViewerParams({
        visibleRegions: [...(visibleRegions || []), regionId],
      });
      setSelectedRegionId(regionId);
    });
  };

  return (
    <StampLayer
      size={size || 100}
      height={height}
      maxZoom={maxZoom}
      onDrawingDone={addRegionOfInterest}
      map={map}
    />
  );
};

export default RegionsDrawingLayer;
