import { aignx as aignxTheme, gavi as gaviTheme } from '@aignostics/theme';
import React from 'react';
import loginBackgroundSrc from '../../assets/aignx/loginBackground.png';
import { ReactComponent as LogoAignx } from '../../assets/aignx/logo.svg';
import { ReactComponent as BannerGavi } from '../../assets/gavi/banner.svg';
import { ReactComponent as LogoGavi } from '../../assets/gavi/logo.svg';
import { AppBranding } from './appConfig.types';
import { getAppName } from './utils/getAppName';
import { getFavicon } from './utils/getFavicon';
import { requireEnv } from './utils/requireEnv';

/**
 * Build time environment variable
 */
const buildEnv = requireEnv(process.env.REACT_APP_BUILD_ENV);

const aignx: AppBranding = {
  name: getAppName('Portal', buildEnv),
  brand: 'Aignostics',
  logo: <LogoAignx />,
  texts: {
    home: {
      title: 'All projects',
      description: '',
    },
  },
  theme: aignxTheme,
  favicon: getFavicon('aignx', buildEnv),
  loginBackgroundSrc,
};

const gavi: AppBranding = {
  name: getAppName('Tissue Explorer', buildEnv),
  brand: 'Ultivue + Aignostics',
  logo: <LogoGavi />,
  banner: <BannerGavi />,
  texts: {
    home: {
      title: 'Fluorescence immunofluorescence panels',
      description:
        'Characterize immune biology with pre-optimized panels and markers for whole slide analysis. Ultivue’s FixVUE, FlexVUE and U-VUE panels provide unprecedented signal to noise, same slide H&E staining, and fast turnaround time, allowing you to explore more biologically relevant markers in your tissue samples.\n\nIn addition, Aignostics’ co-optimized AI models allow for easy, robust and reproducible analysis of these panels at scale, for instance for phenotyping, analysis of spatial relationships, or biomarker validation.',
    },
  },
  theme: gaviTheme,
  favicon: getFavicon('gavi', buildEnv),
};

export const brandings: Record<string, AppBranding> = {
  gavi,
  aignx,
};
