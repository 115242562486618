import { FocusArea } from '../../../../api-types';

const sizeFromCoordinates = (focusArea: FocusArea): number => {
  const coordinates = focusArea.geometry.coordinates[0];
  if (coordinates.length < 4) {
    throw new Error(
      'At least 4 coordinates are required to define a rectangle.'
    );
  }

  const [topLeft, topRight, , bottomLeft] = coordinates;

  const width = Math.abs(topRight[0] - topLeft[0]);
  const height = Math.abs(bottomLeft[1] - topLeft[1]);

  return (width + height) / 2;
};

export default sizeFromCoordinates;
