import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $ToggleButton = styled(motion.label) `
  outline: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacings.base}px;
  height: ${({ theme }) => theme.spacings.input - 2}px;
  overflow: hidden;
  white-space: nowrap;
  margin: 1px;

  :first-of-type {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
  }

  :last-of-type {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
  }

  :not(:last-of-type) {
    margin-right: 0px;
  }

  input {
    position: absolute;
    top: -100%;
    left: -100%;
  }

  :disabled {
    opacity: 0.5;
  }
`;
