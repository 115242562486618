import { useSnackbarMutations } from '@aignostics/components';
import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  FluorescenceFile,
  OverlayByType,
  SubProject,
} from '../../../../../types';
import {
  FilterKeysWithAssociation,
  FilterKeysWithoutAssociation,
} from '../AdminSubProjectSlides.component';
import { EditStainingsModalMutation } from './EditStainingsModal.mutation';
import {
  GET_STAININGS_DATA,
  LIST_SUB_PROJECT_SLIDES_WITH_STAININGS,
} from './EditStainingsModal.query';
import { SET_SUB_PROJECT_OVERLAY } from './SET_SUB_PROJECT_OVERLAY';

export interface ToggleStainingAction {
  baseWsiUuid: string;
  stainingUuid: string;
  active: boolean;
  registration?: string | null;
}

interface ToggleStainingMutation extends ToggleStainingAction {
  isFluorescence: boolean;
}

export interface MutationVariables {
  subProjectId: string;
  associations: string | string[];
  batches: string | string[];
  tissues: number | number[];
  stainings: number | number[];
  scanners: number | number[];
  objectivePowers: number | number[];
  case: string | string[];
  search: string | string[];
  diseases: string | string[];
  active: boolean;
  brightfieldType?: string;
  brightfieldUuid?: string;
  overlayBy: OverlayByType | undefined;
}
type SwitchStaining = (
  subProject: string,
  staining: string,
  active: boolean
) => void;

type StainingsData = Array<{ name: string; isActive: boolean }>;
type SwitchAll = (stainingName: string, value: boolean) => void;
type UseEditStainingsArgs = (
  subProjectId: string,
  debouncedFilters: Record<FilterKeysWithoutAssociation, string | string[]>,
  page: number,
  pageSize: number
) => {
  subProject: SubProject | undefined;
  loading: boolean;
  stainingsData: StainingsData;
  switchStaining: SwitchStaining;
  switchAll: SwitchAll;
  switchingStainings: boolean;
  setSubProjectOverlayBy: (overlayBy: OverlayByType) => void;
};

export const updateCacheItem =
  (): MutationUpdaterFunction<
    { editFilteredWsisStainings: ToggleStainingMutation[] },
    MutationVariables,
    DefaultContext,
    ApolloCache<unknown>
  > =>
  (cache, { data }) => {
    const stainings = data?.editFilteredWsisStainings;
    if (!stainings) return;

    stainings.forEach((s) => {
      const id = s.isFluorescence ? 'Fluorescence' : 'stainings';

      if (id === 'Fluorescence') {
        cache.modify({
          id: cache.identify({ __ref: `SubprojectWsi:${s.baseWsiUuid}` }),
          fields: {
            fluorescence(fluorescence) {
              const index = fluorescence?.files?.findIndex(
                (f: FluorescenceFile) => f.uuid === s.stainingUuid
              );

              const newFiles = [...fluorescence.files];

              newFiles[index] = {
                ...newFiles[index],
                isVisible: s.active,
                registrationId: s.registration,
              };
              return {
                ...fluorescence,
                files: newFiles,
              };
            },
            layersVisibleCount(layersVisibleCount) {
              return s.active ? layersVisibleCount + 1 : layersVisibleCount - 1;
            },
          },
        });
      } else {
        cache.modify({
          id: cache.identify({ __ref: `stainings:${s.stainingUuid}` }),

          fields: {
            isActive() {
              return s.active;
            },
          },
        });
        cache.modify({
          id: cache.identify({ __ref: `SubprojectWsi:${s.baseWsiUuid}` }),

          fields: {
            layersVisibleCount(layersVisibleCount) {
              return s.active ? layersVisibleCount + 1 : layersVisibleCount - 1;
            },
          },
        });
      }
    });
  };

export const useEditStainings: UseEditStainingsArgs = (
  subprojectId,
  debouncedFilters,
  page,
  pageSize
) => {
  const { addSnackbar } = useSnackbarMutations();

  const filterValues = {
    subProjectId: subprojectId,
    search: debouncedFilters.searchWsis,
    associations:
      (debouncedFilters as Record<FilterKeysWithAssociation, string | string[]>)
        .associations ?? [],
    batches: debouncedFilters.batches,
    case: debouncedFilters.caseId,
    objectivePowers: (debouncedFilters.objectivePowers as string[]).map(
      (value) => parseFloat(value)
    ),
    scanners: (debouncedFilters.scanners as string[]).map((value) =>
      parseInt(value)
    ),
    stainings: (debouncedFilters.stainings as string[]).map((value) =>
      parseInt(value)
    ),
    tissues: (debouncedFilters.tissues as string[]).map((value) =>
      parseInt(value)
    ),
    diseases: debouncedFilters.diseases as string[],
  };

  const { data: subProjectData, loading } = useQuery<{
    subProject: SubProject;
  }>(LIST_SUB_PROJECT_SLIDES_WITH_STAININGS, {
    fetchPolicy: 'network-only',
    variables: { ...filterValues, page, pageSize },
  });

  const { data } = useQuery<{
    subProjectStainingsData: StainingsData;
  }>(GET_STAININGS_DATA, {
    skip: !subProjectData?.subProject?.overlayBy,
    variables: {
      ...filterValues,
      overlayBy: subProjectData?.subProject?.overlayBy,
    },
  });

  const stainingsData = data?.subProjectStainingsData ?? [];

  const [setOverlayBy, { loading: loadingOverlays }] = useMutation(
    SET_SUB_PROJECT_OVERLAY,
    {
      refetchQueries: [
        {
          query: LIST_SUB_PROJECT_SLIDES_WITH_STAININGS,
          variables: { ...filterValues, page, pageSize },
        },

        {
          query: GET_STAININGS_DATA,
          variables: {
            ...filterValues,
            overlayBy: subProjectData?.subProject?.overlayBy,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [setVisible, { loading: switchingStainings }] = useMutation<
    { editFilteredWsisStainings: ToggleStainingMutation[] },
    MutationVariables,
    DefaultContext,
    ApolloCache<unknown>
  >(EditStainingsModalMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_SUB_PROJECT_SLIDES_WITH_STAININGS,
        variables: { ...filterValues, page, pageSize },
      },
      {
        query: GET_STAININGS_DATA,
        variables: {
          ...filterValues,
          overlayBy: subProjectData?.subProject?.overlayBy,
        },
      },
    ],
    onError: (error) => {
      addSnackbar({ message: error.message, type: 'error', closesAfter: 0 });
    },
  });

  const setSubProjectOverlayBy = async (overlayBy: OverlayByType) => {
    await setOverlayBy({
      variables: { subProjectId: subprojectId, overlayBy },
    });
  };
  const switchStaining: SwitchStaining = (wsiId, staining, value) => {
    void setVisible({
      variables: {
        ...filterValues,
        brightfieldUuid: wsiId,
        active: value,
        overlayBy: subProjectData?.subProject?.overlayBy,
      },
      update: updateCacheItem(),
    });
  };

  const switchAll: SwitchAll = (staining, value) => {
    void setVisible({
      variables: {
        ...filterValues,
        brightfieldType: staining,
        active: value,
        overlayBy: subProjectData?.subProject?.overlayBy,
      },
      update: updateCacheItem(),
    });
  };

  return {
    subProject: subProjectData?.subProject,
    stainingsData,
    loading,
    switchingStainings: switchingStainings || loadingOverlays,
    switchAll,
    switchStaining,
    setSubProjectOverlayBy,
  };
};
