import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const $Menu = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.light};
  position: absolute;
  margin-top: ${({ theme }) => `${theme.spacings[8]}px`};
  list-style: none;
  border-radius: ${({ theme }) => theme.spacings.radius};
  box-shadow: ${({ theme }) => theme.shadows.banner};
  overflow: scroll;
  z-index: 1000;
  top: ${({ theme }) => `${theme.spacings[32]}px`};
  width: max-content;
  :focus-visible {
    box-shadow: ${({ theme }) => theme.shadows.banner};
  }
`;
