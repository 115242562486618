import { Map as OLMap } from 'ol';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { FocusArea } from '../../../../../api-types';
import { default as VectorLayer } from '../Vector.Layer.component';

interface FocusAreaLayerProps {
  focusArea?: FocusArea | null;
  zIndex: number;
  width: number;
  height: number;
  map: OLMap;
}

const OldFocusAreaLayer: FunctionComponent<FocusAreaLayerProps> = ({
  focusArea,
  zIndex,
  width,
  height,
  map,
}) => {
  const theme = useTheme();
  if (focusArea === null || focusArea === undefined) return null;

  return (
    <VectorLayer
      features={[focusArea]}
      zIndex={zIndex}
      opacity={1}
      width={width}
      height={height}
      getFeatureColor={() => theme.colors.warning}
      map={map}
    />
  );
};
export default OldFocusAreaLayer;
