import { User } from '@aignostics/core';
import { Polygon } from 'geojson';
import { v4 as uuid } from 'uuid';
import {
  Annotation,
  AnnotationCategory,
  AnnotationProperties,
} from '../../../../api-types';

/**
 * Creates a new annotation for the given geometry
 * @param polygon GeoJSON polygon object
 * @returns       New annotation
 */
const createAnnotation = (
  polygon: Polygon,
  user: User,
  category: AnnotationCategory,
  properties?: Partial<AnnotationProperties>
): Annotation => {
  const id = uuid();

  const annotation: Annotation = {
    id,
    type: 'Feature',
    geometry: polygon,
    properties: {
      id,
      category,
      createdAt: null,
      drawnAt: new Date().toISOString(),
      createdBy: {
        id: user.id,
        name: user.name || user.email,
        email: user.email,
        deleted: false,
        avatarUrl: user.avatarUrl,
        __typename: 'Annotator',
      },
      __typename: 'AnnotationProperties',
      state: 'create',
      origin: null,
      ...properties,
    },
    __typename: 'Annotation',
  };

  return annotation;
};

export default createAnnotation;
