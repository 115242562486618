import { OneLiner } from '@aignostics/components';
import { Drag } from '@aignostics/icons';
import React from 'react';
import { useTheme } from 'styled-components';
import { InteractiveOverlayWithAssignedSlides } from '../AdminSubProjectAssignInteractiveOverlays/AdminSubProjectAssignInteractiveOverlays.utils';
import { StageIndicator } from '../Overlays/StageIndicator';

export const TableCellName = ({
  overlay,
  enableDrag = true,
}: {
  overlay: Pick<
    Partial<InteractiveOverlayWithAssignedSlides>,
    'stage' | 'originalName' | 'overrideName'
  >;
  enableDrag?: boolean;
}): React.ReactElement => {
  const theme = useTheme();
  const overlayDisplayName = overlay.overrideName ?? overlay.originalName;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        gap: '8px',
      }}
    >
      {enableDrag && (
        <div
          style={{ paddingLeft: '8px', paddingRight: '6px', paddingTop: '8px' }}
        >
          <Drag height={36} width={36} />
        </div>
      )}

      <div>{overlay?.stage && <StageIndicator stage={overlay.stage} />}</div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <OneLiner>{overlayDisplayName}</OneLiner>
        {overlay.overrideName && (
          <OneLiner
            style={{ ...theme.fontStyles.small, color: theme.colors.mid }}
          >
            {overlay.originalName}
          </OneLiner>
        )}
      </div>
    </div>
  );
};
