import type { WsiSlideMetadata } from '@aignostics/extract-wsi-metadata';
import React, { useCallback, type Dispatch, type ReactElement } from 'react';
import type {
  BlobFileWithChecksumAndMetadata,
  UploadSlidesStep,
} from '../Admin.UploadSlides.component';
import { SetFileMetadataStep } from '../SetFileMetadataStep';
import { CalculateChecksumStep } from './CalculateChecksumStep';
import { UploadFilesStep } from './UploadFilesStep';

export type OnboardingLocalFileWithMetadata = {
  checksum: string;
  size: number;
  filename: string;
  type: 'local';
  metadata: WsiSlideMetadata;
  // we don't specify remote source for browser-uploaded files
  source: null;
};

type UploadSlidesStepsProps = {
  step: UploadSlidesStep;
  onStepChanged: Dispatch<UploadSlidesStep>;
  authToken: string | null;
  apiUrl: string;
  origin: string;
};

/**
 * Renders the current step of the upload slides wizard. Handles step changing.
 */
export function UploadSlidesSteps({
  step,
  onStepChanged,
  authToken,
  apiUrl,
  origin,
}: UploadSlidesStepsProps): ReactElement {
  const handleCalculatedChecksum = useCallback(
    (filesWithChecksum: BlobFileWithChecksumAndMetadata[]) => {
      if (step.name === 'calculateChecksum') {
        onStepChanged({
          csv: step.csv,
          files: filesWithChecksum,
          name: 'setFileMetadata',
          title: 'Slides metadata',
        });
      }
    },
    [onStepChanged, step.name, step.csv]
  );

  switch (step.name) {
    case 'calculateChecksum': {
      // 1 step
      return (
        <CalculateChecksumStep
          origin={origin}
          files={step.files}
          onCalculatedChecksum={handleCalculatedChecksum}
        />
      );
    }

    case 'setFileMetadata': {
      // 2 step
      const filesWithMetadata: OnboardingLocalFileWithMetadata[] =
        step.files.map((file) => ({
          metadata: file.metadata,
          checksum: file.checksum,
          filename: file.file.name,
          size: file.file.size,
          type: 'local',
          source: null,
        }));

      return (
        <SetFileMetadataStep
          apiUrl={apiUrl}
          authToken={authToken}
          csv={step.csv}
          files={filesWithMetadata}
          onSuccessfulCreate={({ createOnboardingBatch, records }) => {
            const { batchId, batchName, pairedUploadLinks, wsiUuids } =
              createOnboardingBatch;

            // map filenames to wsiUuids
            const filenameToWsiUuids: Map<string, string[]> = new Map();
            records.forEach((record, index) => {
              const list = filenameToWsiUuids.get(record.filename);
              if (list) {
                filenameToWsiUuids.set(record.filename, [
                  ...list,
                  wsiUuids[index],
                ]);
              } else {
                filenameToWsiUuids.set(record.filename, [wsiUuids[index]]);
              }
            });

            // map wsiUuids to file indexes
            const wsiUuidsToFileIndex: Map<string, number> = new Map();
            wsiUuids.forEach((wsiUuid, index) => {
              wsiUuidsToFileIndex.set(
                wsiUuid,
                step.files.findIndex(
                  ({ file: { name } }) => name === records[index].filename
                )
              );
            });

            onStepChanged({
              name: 'uploadFiles',
              files: step.files,
              title: 'Upload',
              csv: undefined,
              batchId,
              batchName,
              filenameToWsiUuids,
              wsiUuidsToFileIndex,
              pairedUploadLinks,
            });
          }}
          submitLabel="submit"
        />
      );
    }

    case 'uploadFiles': {
      // 3 step
      return (
        <UploadFilesStep
          files={step.files}
          batchId={step.batchId}
          batchName={step.batchName}
          filenameToWsiUuids={step.filenameToWsiUuids}
          wsiUuidsToFileIndex={step.wsiUuidsToFileIndex}
          pairedUploadLinks={step.pairedUploadLinks}
        />
      );
    }
  }
}
