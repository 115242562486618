import { motion } from 'framer-motion';
import styled from 'styled-components';
/**
 * Feature item container element
 */
export const $FeatureItem = styled.div `
  width: 100%;
  position: relative;
  // Offset the first item by height of preview component if featureBar is open
  ${({ isOpenFeatureBar, theme }) => isOpenFeatureBar
    ? `:first-of-type {
    margin-top: ${theme.spacings.button * 4}px;
    border-top: 1px solid ${theme.colors.mid};
  }`
    : ''}
`;
export const $FeatureItemHeader = styled.header `
  z-index: 2;
  position: sticky;
  display: flex;
  width: 100%;
  height: ${({ theme }) => theme.spacings.button}px;
  top: 0;
  background-color: ${({ theme }) => theme.colors.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  box-sizing: content-box;
`;
/**
 * The button to open and close a feature item accordion.
 */
export const $FeatureItemButton = styled.button `
  border: none;
  outline: none;
  padding: 0;

  top: 0;
  left: 0;
  overflow: visible;
  display: flex;
  flex-grow: 1;
  align-items: center;
  overflow: clip;
  max-width: ${({ isOpenFeatureBar }) => isOpenFeatureBar ? `calc(100% - 44px)` : '100%'};
  height: ${({ theme }) => theme.spacings.button}px;
  color: ${({ theme }) => theme.colors.base};
  ${({ theme }) => theme.fontStyles.base};

  ${({ theme, isOpenFeatureBar, isLayerVisible }) => !isOpenFeatureBar && isLayerVisible
    ? `color: ${theme.colors.white};
      background-color: ${theme.colors.primary};
      &:hover {
        background-color: ${theme.colors.primaryLight};
      }
      `
    : `color: ${theme.colors.base};
      background-color: ${theme.colors.light};
      &:hover {
        background-color: ${theme.colors.lighter};
      }
      `}

  transition: background-color ${({ theme }) => theme.animationSpeeds.fast},
    box-shadow ${({ theme }) => theme.animationSpeeds.fast};

  &:active {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:focus-visible {
    box-shadow: ${({ theme }) => theme.glows.focusInner};
  }
`;
/**
 * Rectangular icon container
 */
export const $FeatureItemIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacings.button}px;
  height: ${({ theme }) => theme.spacings.button}px;
  flex-shrink: 0;
`;
export const $FeatureItemTitle = styled.h2 `
  display: block;
  flex-grow: 1;
  white-space: nowrap;
  text-align: left;
  ${({ theme }) => theme.fontStyles.baseBold};
`;
/**
 * Container for main content of a feature item
 */
export const $FeatureItemContent = styled(motion.div) `
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
`;
/**
 * Container for sticky footer elements inside a feature item
 */
export const $FeatureItemFooter = styled.div `
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.light};
`;
