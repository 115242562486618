import { gql } from '@apollo/client';

export const REMOVE_ALL_WSIS_FROM_SUB_PROJECT = gql`
  mutation REMOVE_ALL_WSIS_FROM_SUBPROJECT(
    $subProjectId: ID!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [Int]!
    $stainings: [Int]!
    $tissues: [Int]!
  ) {
    removeAllWsisFromSubproject(
      subProjectId: $subProjectId
      associations: $associations
      batches: $batches
      case: $case
      objectivePowers: $objectivePowers
      scanners: $scanners
      stainings: $stainings
      tissues: $tissues
      searchSlides: $search
    )
  }
`;
