import styled, { css } from 'styled-components';
export const $DropdownContainer = styled.div `
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: ${({ theme }) => theme.colors.mid};
  width: fit-content;

  > button > span {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;
export const $HorizontalDivider = styled.div `
  width: 1px;
  height: 10px;

  background: ${({ theme }) => theme.colors.mid};
  margin: 0 8px 0 12px;
`;
export const $DropdownMenu = styled.ul `
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.light};
  position: absolute;
  margin-top: ${({ theme }) => `${theme.spacings[8]}px`};
  list-style: none;
  border-radius: ${({ theme }) => theme.spacings.radius};
  box-shadow: ${({ theme }) => theme.shadows.banner};
  overflow: scroll;
  z-index: 1000;

  :focus-visible {
    box-shadow: ${({ theme }) => theme.shadows.banner};
  }

  > li {
    ${({ theme }) => theme.fontStyles.small};
    margin: 0;
    text-align: left;
    background-color: white;
  }
`;
export const $MenuItem = styled.button `
  ${({ theme }) => theme.fontStyles.small}

  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  margin: 0;
  padding: 0 12px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    background-color: ${({ theme }) => theme.colors.light};
  }

  ${({ isActive }) => isActive &&
    css `
      background-color: ${({ theme }) => theme.colors.lighter};
    `}

  ${({ variant }) => variant === 'checkbox' &&
    css `
      padding: 0 12px 0 0px;
    `}
`;
