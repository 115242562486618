import { LazyImage, WsiPlaceholder } from '@aignostics/components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useAsync } from '../../hooks/useAsync';

export interface WsiThumbnailProps {
  wsiId: string;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}

export function WsiThumbnail({
  wsiId,
  rasterTileServerUrl,
  getToken,
}: WsiThumbnailProps): ReactElement {
  const fetcher = useMemo(
    () => async () => {
      const token = await getToken();

      const response = await fetch(
        `${rasterTileServerUrl}/thumbnail/wsi/${wsiId}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      const blob = await response.blob();

      return URL.createObjectURL(blob);
    },
    [getToken, rasterTileServerUrl, wsiId]
  );
  // We need the token on render, no workaround for this
  const url = useAsync(fetcher);

  // Cleanup to avoid memory leak
  useEffect(
    () => () => {
      if (url === null) return;
      URL.revokeObjectURL(url);
    },
    [url]
  );

  return (
    <>
      <LazyImage
        renderPlaceholder={({ state }) => (
          <WsiPlaceholder
            state={state}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        )}
        alt={`Preview of whole slide image ${wsiId}`}
        src={url !== null ? url : ''}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </>
  );
}
