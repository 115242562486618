import { ApolloError, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Annotation,
  AnnotationSettings,
  FocusArea,
  Wsi,
} from '../../../../api-types';
import { FETCH_SUBPROJECT_WSI_ANNOTATIONS } from '../../../../graphql/queries';
import getSubprojectParamsForViewer from '../../../__Pages/Client/getSubprojectParamsForViewer';
import { sizeFromCoordinates } from '../../../__Viewer/OpenLayers/utils';
import { SlideRouteParams } from '../../../__Viewer/Slide';
import {
  ROISettings,
  useRegionsSettingsStore,
} from '../FocusArea/useRegionsSettingsStore';

const IS_REGION_EDITABLE = true;

const defaultROISettings: Omit<ROISettings, 'regionInfo'> = {
  latestRegionSize: 100,
  isEditable: IS_REGION_EDITABLE,
};

/** Queries `annotations` and relevant metadata from api. */
export function useAnnotations(shouldFetchAnnotations: boolean): [
  {
    annotations: Annotation[];
    annotationSettings: AnnotationSettings | null;
    nextWsiIdWithoutAnnotations: string | null;
    height: number;
    regionsOfInterest?: FocusArea[];
    defaultROISettings: Omit<ROISettings, 'regionInfo'>;
  },
  { loading: boolean; error?: ApolloError },
] {
  // Get ids from location pathname
  const { subProjectId, wsiId } = useParams<keyof SlideRouteParams>();
  const { setAllROIs, getAllROIs } = useRegionsSettingsStore((state) => ({
    setAllROIs: state.setAllROIs,
    getAllROIs: state.getAllROIs,
  }));

  const queryVariables = getSubprojectParamsForViewer();

  // Query annotations and annotation categories from cache
  const { data, loading, error } = useQuery<{
    subProject: AnnotationSettings;
    wsi: Wsi;
  }>(FETCH_SUBPROJECT_WSI_ANNOTATIONS, {
    skip: !subProjectId || !shouldFetchAnnotations,
    variables: { subProjectId, wsiId, ...queryVariables },
  });

  const annotationSettings = data?.subProject || null;
  const nextWsiIdWithoutAnnotations =
    data?.wsi?.subsequentWsiIds?.nextWsiId || null;
  const height = data?.wsi?.height || 0;

  const annotations = useMemo(
    () => data?.wsi.annotations ?? [],
    [data?.wsi.annotations]
  );

  const regionsOfInterest = data?.wsi.regionsOfInterest;

  useEffect(() => {
    if (!regionsOfInterest) return;
    const allROIs = getAllROIs();
    const mappedROIs = regionsOfInterest?.reduce<Record<string, ROISettings>>(
      (acc, cur) => {
        const key = cur.id;
        acc[key] = {
          ...(allROIs[cur.id]
            ? allROIs[cur.id]
            : {
                ...defaultROISettings,
                latestRegionSize: sizeFromCoordinates(cur),
              }),
          regionInfo: cur,
        };
        return acc;
      },
      {}
    );
    setAllROIs(mappedROIs);
  }, [regionsOfInterest, setAllROIs, getAllROIs]);

  return [
    {
      annotations,
      annotationSettings,
      nextWsiIdWithoutAnnotations,
      height,
      regionsOfInterest,
      defaultROISettings,
    },
    { loading, error },
  ];
}
