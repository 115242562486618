// NOTE: taggers = interactiveOverlays && tag = class
import {
  Button,
  Filter,
  FilterConfigs,
  FilterField,
  HStack,
  IconButton,
  Placeholder,
  Section,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  VStack,
  getFiltersFromQueryParams,
  useDisclosure,
  useFilters,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useDebounce } from '@aignostics/hooks';
import { IconKey } from '@aignostics/theme';
import { pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSetQueryParams } from '../../../../hooks';
import { swapArrayElements, useDynamicList } from '../../../../utils';
import { useToggleHandler } from '../Overlays';
import { OverlayMatrixViewModal } from '../Overlays/MatrixViewModal/OverlayMatrixView.Modal';
import { TableCellName } from '../components/TableCellName';
import { ToggleOverlaySlides } from '../components/ToggleOverlaySlides';
import { $CenterCellElement } from './AdminSubProjectAssignInteractiveOverlays.styles';
import {
  FilterKeys,
  InteractiveOverlayData,
  ModalPayload,
} from './AdminSubProjectAssignInteractiveOverlays.types';
import {
  InteractiveOverlayWithAssignedSlides,
  addAssignedAndAvailableWsisToTaggersMap,
  sortTaggersAssignedBySortValue,
  transformWsisToTaggerMap,
} from './AdminSubProjectAssignInteractiveOverlays.utils';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from './FETCH_INTERACTIVE_OVERLAYS_MATRIX';
import { ColorsSelectorModal } from './components/ColorSelectorModal/ColorSelectorModal';
import { DeleteOverlaysModal } from './components/DeleteOverlays.Modal';
import DynamicSizeItem from './components/DynamicItem';
import { InteractiveOverlayStageLegend } from './components/InteractiveOverlayStageLegend';
import { InteractiveOverlayModal } from './components/interactiveOverlayModal/InteractiveOverlay.modal';
import { useSortInteractiveOverlays } from './components/interactiveOverlayModal/hooks/useSortInteractiveOverlays';

const INTERACTIVE_OVERLAY_ICONS: IconKey = 'Disc';
const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  searchOverlayName: { fallbackValue: '', type: 'string' },
  stage: { type: 'array', fallbackValue: [] },
};
const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
  searchOverlayName: {
    type: 'search',
    label: 'Search',
    value: '',
    placeholder: 'Search',
  },
  stage: {
    type: 'multiselect',
    label: 'Stage',
    options: [
      { value: 'dev', label: 'dev' },
      { value: 'prod', label: 'prod' },
      { value: 'debug', label: 'debug' },
    ],
    placeholder: 'Select',
    value: [],
  },
};

/** Component to assign overlays to slides within the given Subproject. */
const AssignInteractiveOverlays = ({
  projectId,
  subprojectId,
  organizationUuid,
  userRole,
}: {
  projectId: string;
  subprojectId: string;
  organizationUuid: string;
  userRole: OrganizationRole;
}): ReactElement => {
  const [selectedTagger, setSelectedTagger] =
    useState<InteractiveOverlayWithAssignedSlides | null>(null);
  const [taggers, setTaggers] = useState<
    InteractiveOverlayWithAssignedSlides[]
  >([]);
  const [modalData, setModalData] = useState<ModalPayload | null>(null);
  const initialFilters = useMemo(
    () => getFiltersFromQueryParams(PAGE_FILTER_CONFIGS),
    []
  );
  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    initialFilters
  );
  useSetQueryParams(filters);
  const debouncedFilters = useDebounce(filters);
  const deleteTaggerModal = useDisclosure<string[]>();
  const matrixViewModal = useDisclosure();

  // Query data for rendering the table.
  const {
    data,
    previousData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<InteractiveOverlayData>(FETCH_INTERACTIVE_OVERLAYS_MATRIX, {
    variables: {
      subProjectId: subprojectId,
      stage: debouncedFilters.stage.length > 0 ? debouncedFilters.stage : null,
      searchOverlayName:
        (debouncedFilters.searchOverlayName as string)?.trim() || null,
    },
    fetchPolicy: 'network-only',
  });

  const wsis = useMemo(
    () => (data ?? previousData)?.subProject.wsis || [],
    [data, previousData]
  );

  const wsiId: string[] = data?.subProject.wsis.map((wsi) => wsi.id) || [];
  const sortInteractiveOverlays = useSortInteractiveOverlays(wsiId);

  useEffect(() => {
    // transform wsis[available, assignedTaggers] -> taggers[availableWsis, assignedWsis]
    // Transform WSIS to a map of taggers
    const allTaggersMap = transformWsisToTaggerMap(wsis);
    // transform taggersAssigned and taggersAll in wsis to assignedWsis and allWsis in taggersMap
    const taggerMapWithAssignedAndAvailableWsis =
      addAssignedAndAvailableWsisToTaggersMap(allTaggersMap, wsis);
    // transform this into an array
    const taggers = Array.from(taggerMapWithAssignedAndAvailableWsis.values());
    const sortedTaggers = sortTaggersAssignedBySortValue(taggers);
    setTaggers(sortedTaggers);
  }, [wsis]);

  // Global change handler for all toggles
  const [onChange, { loading: mutationLoading, error: mutationError }] =
    useToggleHandler('interactive', subprojectId);

  // Combine all queries and mutations loading and error states
  const loading = queryLoading || mutationLoading;
  const error = queryError || mutationError;

  const onPositionUpdate = useCallback(
    (startIndex: number, endIndex: number) => {
      const newItems = [
        ...swapArrayElements(taggers, startIndex, endIndex),
      ].map((item, index) => {
        return {
          ...item,
          sort: index,
        };
      });
      setTaggers(newItems);
    },
    [taggers, setTaggers]
  );

  const onPositionChange = () => {
    const interactiveOverlaysSort = taggers.map((tagger) => {
      return {
        tagger: tagger.id,
        sort: tagger.sort,
      };
    });
    void sortInteractiveOverlays({
      subProjectId: subprojectId,
      interactiveOverlaysSort,
    });
  };

  const dynamicListItemProps = useDynamicList({
    items: taggers,
    onPositionUpdate,
    onPositionChange,
  });

  const toggleOverlayOnAllAvailableSlides = (
    toggleValue: boolean,
    taggerOriginalName: string,
    taggerId: string | undefined
  ) => {
    const selectedTagger = taggers.find((tagger) =>
      tagger.id
        ? tagger.id === taggerId
        : tagger.originalName === taggerOriginalName
    ) as InteractiveOverlayWithAssignedSlides;
    const taggersAvailableWsis = selectedTagger?.allWsis;
    const overlayAssignment = taggersAvailableWsis?.map((wsi) => ({
      wsiId: wsi,
      overlayId: selectedTagger.id,
    }));
    if (overlayAssignment) {
      onChange(toggleValue, overlayAssignment);
    }
  };

  const onEditIconClick = (tagger: InteractiveOverlayWithAssignedSlides) => {
    setSelectedTagger(tagger);
  };

  const wsiList = wsis.map((wsi) => {
    return {
      name: wsi.name,
      id: wsi.id,
    };
  });

  return (
    <>
      <Section
        title="Interactive Overlays"
        description="Assign Interactive Overlays to slides within this subproject."
        loading={loading}
        error={error}
        filterComponent={
          <Filter fields={PAGE_FILTER_FIELDS} isOpen={false} {...filterProps} />
        }
      >
        <HStack justifyContent="flex-end" style={{ width: '100%' }}>
          <Button
            onClick={() => {
              matrixViewModal.open();
            }}
            small
            disabled={queryLoading || (!queryLoading && taggers.length === 0)}
          >
            Side-by-side view
          </Button>
        </HStack>
        <HStack>
          <VStack
            spacing="large"
            justifyContent="flex-start"
            style={{ width: '100%' }}
          >
            {wsis.length > 0 &&
              ` ${wsis.length} ${pluralize('Slide', wsis.length)}`}
          </VStack>

          <VStack>
            <InteractiveOverlayStageLegend />
          </VStack>
        </HStack>

        <VStack spacing="line" alignItems="center">
          {!queryLoading && taggers.length === 0 ? (
            <Placeholder
              title="No Interactive Overlays"
              description="There are no Interactive Overlays for the Slides within this Subproject."
              icon={INTERACTIVE_OVERLAY_ICONS}
            />
          ) : (
            wsis.length !== 0 && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader
                        style={{ paddingLeft: '50px', width: '50%' }}
                      >
                        Name
                      </TableHeader>
                      <TableHeader style={{ textAlign: 'center' }}>
                        Assigned Slides
                      </TableHeader>
                      <TableHeader style={{ textAlign: 'center' }}>
                        Edit
                      </TableHeader>
                      <TableHeader style={{ textAlign: 'center' }}>
                        Show
                      </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taggers.map((tagger, index) => {
                      // if one of the wsis has this tagger active then set isToggleActive to TRUE
                      const isToggleActive = tagger?.assignedWsis?.some(
                        (assignedWsi) => tagger?.allWsis?.includes(assignedWsi)
                      );
                      return (
                        <DynamicSizeItem
                          key={tagger.id}
                          index={index}
                          itemProps={dynamicListItemProps}
                        >
                          <TableCell
                            style={{ paddingLeft: '10px', width: '50%' }}
                          >
                            <TableCellName
                              overlay={{
                                stage: tagger.stage,
                                overrideName: tagger.overrideName,
                                originalName: tagger.originalName,
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {tagger.assignedWsis?.length}
                          </TableCell>
                          <TableCell>
                            <$CenterCellElement>
                              <IconButton
                                aria-label="Edit"
                                icon={'Edit'}
                                onClick={() => {
                                  onEditIconClick(tagger);
                                }}
                                disabled={loading}
                              />
                            </$CenterCellElement>
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <$CenterCellElement>
                              <ToggleOverlaySlides
                                overlay={tagger}
                                isToggleActive={Boolean(isToggleActive)}
                                toggleOverlayOnAllAvailableSlides={
                                  toggleOverlayOnAllAvailableSlides
                                }
                                disabled={loading}
                              />
                            </$CenterCellElement>
                          </TableCell>
                        </DynamicSizeItem>
                      );
                    })}
                  </TableBody>
                </Table>
              </>
            )
          )}
        </VStack>
      </Section>
      <ColorsSelectorModal
        modalData={modalData}
        subProjectId={subprojectId}
        onClose={() => {
          setModalData(null);
        }}
      />
      <DeleteOverlaysModal deleteTaggerModal={deleteTaggerModal} />

      {selectedTagger && (
        <InteractiveOverlayModal
          selectedInteractiveOverlay={selectedTagger}
          subprojectId={subprojectId}
          onClose={() => {
            setSelectedTagger(null);
          }}
          wsiList={wsiList}
          organizationUuid={organizationUuid}
        />
      )}
      {matrixViewModal.isOpen && (
        <OverlayMatrixViewModal
          matrixViewModal={matrixViewModal}
          wsis={wsis}
          projectId={projectId}
          subprojectId={subprojectId}
          organizationUuid={organizationUuid}
          userRole={userRole}
          type="interactive"
        />
      )}
    </>
  );
};

export default AssignInteractiveOverlays;
