import { SnackbarContainer, SnackbarProvider } from '@aignostics/components';
import {
  OnboardingMetricsProvider,
  OnboardingProvider,
  UploadDoneNotifier,
  UploadWakeAndNavigationLock,
} from '@aignostics/onboarding-ui';
import { CustomThemeProvider } from '@aignostics/theme';
import React, { useMemo, useState, type VoidFunctionComponent } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { initSentry } from '../../sentry';
import { router } from '../__Navigation/Routes';
import { ErrorBoundary } from '../__Pages/ErrorBoundary';
import { RevokedAccessModalWrapper } from '../__Pages/Login/RevokedAccessModalWrapper';
import { AppHead } from './AppHead.component';
import { AuthProvider } from './AuthProvider';
import { AppConfigProvider } from './ConfigProvider';
import { ImpersonationProvider } from './ImpersonationProvider';
import { TrackingProvider } from './TrackingProvider';
import { brandings } from './appBrandings';
import { appConfig } from './appConfig';
import { AppConfig } from './appConfig.types';
import { useNetworkStatusNotification } from './useNetworkStatusNotification';
import { getAppBrandingSetting } from './utils/getAppBrandingSetting';
/**
 * Root react app element to
 * @returns
 */
const App: VoidFunctionComponent = () => {
  // Set initial branding setting from location
  const [appBrandingSetting, setAppBrandingSetting] = useState(
    getAppBrandingSetting()
  );

  // Merge static environment and branding config
  const config: AppConfig = useMemo(
    () => ({
      ...appConfig,

      appBrandingSetting,
      setAppBrandingSetting,

      ...brandings[appBrandingSetting],
    }),
    [appBrandingSetting]
  );

  // should have leading slash, no trailing slash, in case if not specified it
  // should be an undefined instead of empty string

  initSentry(appConfig.sentry.dsn, appConfig.buildEnv);

  return (
    <>
      <CustomThemeProvider theme={config.theme}>
        <QueryParamProvider>
          <ErrorBoundary>
            <AppConfigProvider config={config}>
              <SnackbarProvider>
                <NetworkStatusProvider />
                <OnboardingProvider>
                  <OnboardingMetricsProvider>
                    <UploadWakeAndNavigationLock />
                    <AppHead appConfig={config} />
                    <UploadDoneNotifier />
                    <ImpersonationProvider>
                      <AuthProvider>
                        <SnackbarContainer />
                        <RevokedAccessModalWrapper />
                        <TrackingProvider>
                          <RouterProvider router={router} />
                        </TrackingProvider>
                      </AuthProvider>
                    </ImpersonationProvider>
                  </OnboardingMetricsProvider>
                </OnboardingProvider>
              </SnackbarProvider>
            </AppConfigProvider>
          </ErrorBoundary>
        </QueryParamProvider>
      </CustomThemeProvider>
    </>
  );
};

function NetworkStatusProvider(): null {
  useNetworkStatusNotification();
  return null;
}

export default App;
