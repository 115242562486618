import { Loader } from '@aignostics/components';
import { Theme } from '@aignostics/theme';
import { Feature } from 'geojson';
import React, { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useLayerLoadingState } from '../../../providers/LayerLoadingProvider';
import { getIsAnnotationFeatureActive } from '../../__Features/Annotations/FeatureItem.Annotations.component';
import { canEditOrDeleteAnnotation } from '../../__Features/Annotations/utils/canEditOrDeleteAnnotation';
import {
  useDrawingModeActions,
  useDrawingModeState,
} from '../../__Features/Drawing';
import { SlideRouteParams } from '../Slide';
import { useVisibleAnnotations } from '../hooks/useVisibleAnnotations';
import { useVisibleStaticOverlays } from '../hooks/useVisibleStaticOverlays';
import { ViewerProps } from '../types';
import {
  AnnotationsLayer,
  BoundaryLayer,
  FluorescenceLayer,
  FocusAreaLayer,
  InteractiveOverlaysLayer,
  StainingsLayer,
  StaticOverlaysLayer,
} from './Layers/Abstractions';
import OldFocusAreaLayer from './Layers/Abstractions/OldFocusArea.Layer.component';
import { DrawingLayers } from './Layers/Drawing/DrawingLayers';
import Map from './Map.component';
import Popup from './Overlays/Popup/Popup.component';
import { createVectorTileUrlFactory, getBoundary } from './utils';

/**
 * Render engine to stack the various layers.
 */
function Viewer({
  wsi,
  overlays,
  annotations,
  otherAnnotationVisibility,
  extendedViewerParams,
  map,
  viewerId,
  annotationFeature,
  getToken,
  rasterTileServerUrl,
  vectorTileServerUrl,
  organizationUuid,
  currentUser,
  userRole,
  isROIEnabled,
}: ViewerProps): ReactElement {
  const drawingMode = useDrawingModeState();
  const theme: Theme = useTheme();
  const boundary = useMemo<Feature>(() => getBoundary(wsi), [wsi]);
  const { subProjectId: subprojectId } = useParams<
    keyof SlideRouteParams
  >() as SlideRouteParams;
  /**
   * Context loading state, which gets set in the layer components on tile
   * requests.
   */
  const { loadingStates } = useLayerLoadingState();
  const isLayerLoading = Object.values(loadingStates).some((state) => state);
  const { disableDrawing } = useDrawingModeActions();

  const { viewerParams } = extendedViewerParams;

  const visibleAnnotations = useVisibleAnnotations(
    annotations,
    viewerParams,
    annotationFeature
  );

  const visibleStaticOverlays = useVisibleStaticOverlays(
    overlays,
    viewerParams
  );

  const { activeCategories } = viewerParams;
  // Check whether annotation feature is active
  const isAnnotationFeatureActive =
    getIsAnnotationFeatureActive(activeCategories);

  const buildTileUrl = useMemo(
    () =>
      createVectorTileUrlFactory(vectorTileServerUrl, organizationUuid, wsi.id),
    [organizationUuid, vectorTileServerUrl, wsi]
  );

  const fluorescenceChannels = useMemo(
    () => wsi.fluorescence?.files.map((f) => f.channels).flat() ?? [],
    [wsi]
  );

  return (
    <Map
      map={map}
      viewerId={viewerId}
      isAnnotationFeatureActive={isAnnotationFeatureActive}
    >
      <StainingsLayer
        baseLayer={wsi}
        wsis={wsi.stainings !== undefined ? wsi.stainings : []}
        zIndex={0}
        stainingSettings={viewerParams.wsiLayers}
        map={map}
        getToken={getToken}
        rasterTileServerUrl={rasterTileServerUrl}
      />

      <StaticOverlaysLayer
        zIndex={1}
        overlays={visibleStaticOverlays}
        map={map}
        wsi={wsi}
        getToken={getToken}
        rasterTileServerUrl={rasterTileServerUrl}
      />

      {fluorescenceChannels.length > 0 ? (
        <FluorescenceLayer
          zIndex={overlays.length + 1}
          wsi={wsi}
          FluorescenceChannels={fluorescenceChannels}
          FluorescenceChannelsParam={viewerParams.fluorescenceChannels}
          map={map}
          getToken={getToken}
          rasterTileServerUrl={rasterTileServerUrl}
        />
      ) : undefined}

      <InteractiveOverlaysLayer
        wsiId={wsi.id}
        subprojectId={subprojectId}
        width={wsi.width}
        height={wsi.height}
        maxZoom={wsi.maxZoom}
        zIndex={overlays.length + fluorescenceChannels.length + 1}
        taggersParam={viewerParams.interactiveOverlays}
        map={map}
        buildTileUrl={buildTileUrl}
        getToken={getToken}
        drawingMode={drawingMode}
      />

      <AnnotationsLayer
        annotations={visibleAnnotations}
        zIndex={overlays.length + fluorescenceChannels.length + 2}
        width={wsi.width}
        height={wsi.height}
        map={map}
      />

      <BoundaryLayer
        boundary={boundary}
        loading={loadingStates.brightfield || loadingStates.fluorescence}
        zIndex={overlays.length + fluorescenceChannels.length + 3}
        width={wsi.width}
        height={wsi.height}
        isVisible={viewerParams.isBoundaryVisible}
        map={map}
      />
      {isROIEnabled ? (
        <FocusAreaLayer
          regionsOfInterest={wsi.regionsOfInterest}
          maxNativeZoom={wsi.maxZoom}
          objectivePower={wsi.objectivePower}
          zIndex={overlays.length + fluorescenceChannels.length + 4}
          width={wsi.width}
          height={wsi.height}
          map={map}
          isAnnotationFeatureActive={isAnnotationFeatureActive}
        />
      ) : (
        <OldFocusAreaLayer
          focusArea={(wsi.regionsOfInterest || [])[0]}
          zIndex={overlays.length + fluorescenceChannels.length + 4}
          width={wsi.width}
          height={wsi.height}
          map={map}
        />
      )}

      {annotationFeature !== undefined &&
      otherAnnotationVisibility !== undefined ? (
        <div>
          <DrawingLayers
            width={wsi.width}
            height={wsi.height}
            maxZoom={wsi.maxZoom}
            zIndex={overlays.length + fluorescenceChannels.length + 6}
            map={map}
            otherAnnotationVisibility={otherAnnotationVisibility}
            annotationFeature={annotationFeature}
            drawingMode={extendedViewerParams.drawingMode}
            currentUser={currentUser}
            currentUserRole={userRole}
            disableDrawing={disableDrawing}
          />
          <Popup
            width={wsi.width}
            height={wsi.height}
            annotationFeature={annotationFeature}
            map={map}
            canEditAnnotation={(annotation) =>
              canEditOrDeleteAnnotation({
                currentUserRole: userRole.scopes,
                selectedFeature: annotation,
                currentUser,
                annotationFeatureOn: annotationFeature === 'ON',
              })
            }
          />
        </div>
      ) : null}
      {/* Layer Loading Indicator */}
      {isLayerLoading && (
        <Loader overlay color="warning" sizeContainer={theme.spacings.input} />
      )}
    </Map>
  );
}

export default Viewer;
