import { PageLayout } from '@aignostics/components';
import { OrganizationRole, VisibleWithScope } from '@aignostics/core';
import React, { ReactElement } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelectedOrganizationUuid } from '../utils/useSelectedOrganizationUuid';
import { SetFileMetadataStep } from './SetFileMetadataStep';

/**
 * Page for admins to edit Slides ( WSIS ) from a selected OnnBoardingBatch.
 */

type AdminEditSlidesProps = {
  role: OrganizationRole;
  authToken: string | null;
  apiUrl: string;
};
const AdminEditSlides = ({
  role,
  authToken,
  apiUrl,
}: AdminEditSlidesProps): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const organizationUuid = useSelectedOrganizationUuid();

  const onboardingBatch = location.state?.onboardingBatch;
  if (!onboardingBatch) {
    return <Navigate to={`/${organizationUuid}/admin/data-onboarding`} />;
  }

  return (
    <PageLayout
      title="Edit Slides"
      description="Please provide information about the slides (you can use the table header to add global values). You may also include a CSV file containing prefilled fields."
    >
      <VisibleWithScope role={role} scope="wsi:upload">
        <SetFileMetadataStep
          apiUrl={apiUrl}
          authToken={authToken}
          csv={undefined}
          submitLabel="update"
          onboardingBatch={onboardingBatch}
          files={[]}
          onSuccessfulUpdate={(updateOnboardingBatchWsis) => {
            navigate(
              `/${organizationUuid}/admin/data-onboarding/batch/${updateOnboardingBatchWsis.batch_id}`
            );
          }}
        />
      </VisibleWithScope>
    </PageLayout>
  );
};

export default AdminEditSlides;
