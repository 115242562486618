import { Polygon } from 'geojson';
import { MapBrowserEvent, Map as OLMap } from 'ol';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  addInteractions,
  getImageBoundaryPolygon,
  getPointFromDrawingCoordinateFactory,
  preventTouch,
  removeInteractions,
  usePointerOverlay,
} from '../../../utils';

interface StampLayerProps {
  size: number;
  height: number;
  maxZoom: number;
  onDrawingDone: (polygon: Polygon) => void;
  map: OLMap;
}

/**
 * Stamp a feature polygon for the given size onto the map.
 */
const StampLayer: FunctionComponent<StampLayerProps> = ({
  size,
  height,
  maxZoom,
  onDrawingDone,
  map,
}) => {
  const [projectedSize, setProjectedSize] = useState(0);

  const updateSize = useCallback(() => {
    const zoom = map.getView().getZoom() || 0;
    const _projectedSize = size / 2 ** (maxZoom - zoom);
    setProjectedSize(_projectedSize);
  }, [map, maxZoom, size]);

  useEffect(() => {
    updateSize();
  }, [size, updateSize]);

  useEffect(() => {
    map.on('postrender', updateSize);

    return () => {
      map.un('postrender', updateSize);
    };
  }, [map, maxZoom, size, updateSize]);

  // Setup pointer overlay
  const pointer = usePointerOverlay(map, {
    size: projectedSize,
    type: 'rect',
  });

  useEffect(() => {
    const handlePointerDown = preventTouch(() => {
      removeInteractions(map);
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerdown', handlePointerDown);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerdown', handlePointerDown);
    };
  }, [map]);

  useEffect(() => {
    const getPointFromDrawingCoordinate =
      getPointFromDrawingCoordinateFactory(height);
    const handlePointerUp = (event: MapBrowserEvent<UIEvent>) => {
      addInteractions(map);

      const [x, y] = getPointFromDrawingCoordinate(event.coordinate);
      const offset = size / 2;

      const x1 = Math.round(x - offset);
      const y1 = Math.round(y - offset);
      const x2 = Math.round(x + offset);
      const y2 = Math.round(y + offset);

      const polygon = getImageBoundaryPolygon(x1, y1, x2, y2);
      onDrawingDone(polygon);
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerup', handlePointerUp);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerup', handlePointerUp);
    };
  }, [height, map, onDrawingDone, size]);

  // Attach event listeners to map object
  useEffect(() => {
    const handlePointerMove = (event: MapBrowserEvent<UIEvent>) => {
      // Updated pointer position
      pointer?.setPosition(event.coordinate);
    };
    map.on('pointermove', handlePointerMove);

    return () => {
      map.un('pointermove', handlePointerMove);
    };
  }, [map, pointer]);

  return null;
};

export default StampLayer;
