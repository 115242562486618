import { ApolloClient } from '@apollo/client';
import {
  deleteAnnotationFromCache,
  setAnnotationState,
} from '../../../../graphql/helpers';

export const deleteAnnotation = (
  client: ApolloClient<object>,
  state: string | null | undefined,
  id: string,
  wsiId: string
): void => {
  if (state === 'create') {
    deleteAnnotationFromCache(client, wsiId, id);
  } else {
    setAnnotationState(client, id, 'delete');
  }
};
