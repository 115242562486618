import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import { HStack } from '../Stack';
export function Pill({ icon, text, variant = 'regular', ...rest }) {
    return (React.createElement(PillWrapper, { "aria-label": text, variant: variant, ...rest },
        React.createElement(HStack, { spacing: "small", alignItems: "center" },
            icon ? React.createElement(Icon, { icon: icon, size: "input" }) : null,
            React.createElement("div", null, text))));
}
export const PillWrapper = styled.div(({ theme }) => ({
    padding: '2px 4px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '2px',
    ...theme.fontStyles.small,
}), ({ theme, variant }) => {
    switch (variant) {
        case 'regular':
            return {
                color: theme.colors.dark,
                backgroundColor: theme.colors.light,
            };
        case 'ghost':
            return {
                color: theme.colors.text,
                backgroundColor: 'transparent',
            };
        case 'white':
            return {
                color: theme.colors.text,
                backgroundColor: theme.colors.white,
            };
    }
});
