// Optimal scale width in px
const SCALE_MAX_WIDTH = 200;

const SCALE_STEPS = [
  // μm range
  5, 10, 30, 50, 100, 300,
  // mm range
  500, 1000, 3000, 5000, 10000, 30000,
]
  // Big to small steps are easier to work with
  .reverse();

type ScaleUnit = 'µm' | 'mm' | 'px';

/**
 * Get scale legend - "[value] [unit]""
 *
 * @param n:  n µm
 * @param mpp
 * @returns
 */
const getLegend = (n: number, mpp: number | null) => {
  let unit: ScaleUnit = 'µm';
  let value: number = n;

  if (!mpp) {
    unit = 'px';
    // For more than 500 µm, convert to mm
  } else if (n >= 500) {
    value = n / 1000;
    unit = 'mm';
  }

  return `${value} ${unit}`;
};

/**
 * Coompute scale width and value according to the current zoom level
 *
 * @param mpp:          µm/px
 * @param zoom
 * @param maxNativeZoom
 * @returns
 */
export const computeScaleValues = (
  mpp: number | null,
  zoom: number,
  maxNativeZoom: number
): { legend: string; width: number } => {
  const factor = 2 ** (maxNativeZoom - zoom);

  // Compute mpp at this zoom level
  const currentMpp = (mpp || 1) * factor;

  // Find the closest value within the predefined values set
  const index = SCALE_STEPS.findIndex(
    (value) => value / currentMpp <= SCALE_MAX_WIDTH
  );

  const legend = getLegend(SCALE_STEPS[index], mpp);
  const width = SCALE_STEPS[index] / currentMpp;

  return { legend, width };
};
