/* eslint-disable default-param-last */
import { MementoCache } from '@aignostics/core';
import uniqueArrayByKey from '../utils/uniqueArrayByKey';

/** Client side graphql cache layer with local only fields. */
const createCache = (): MementoCache =>
  new MementoCache({
    // Define entities, which create undo / redo history
    selectors: [
      { entity: 'Annotation' },
      { entity: 'AnnotationProperties' },
      { entity: 'WSI', fields: ['annotations'] },
    ],
    typePolicies: {
      Query: {
        fields: {
          user(_, { args, toReference }) {
            return toReference({
              __typename: 'User',
              id: args?.id,
            });
          },
          annotationCategory(_, { args, toReference }) {
            return toReference({
              __typename: 'AnnotationCategory',
              id: args?.id,
            });
          },
        },
      },
      SubProject: {
        fields: {
          annotationCategories: {
            read: (value = null) => {
              return value;
            },
            merge: (existing = [], incoming) => {
              return uniqueArrayByKey([...existing, ...incoming], '__ref');
            },
          },
        },
      },
      WSI: {
        fields: {
          taggerNames: {
            read: (value = null) => value,
            merge: (existing = [], incoming) =>
              uniqueArrayByKey([...existing, ...incoming], '__ref'),
          },
          annotations: {
            merge: (existing = [], incoming) =>
              uniqueArrayByKey([...existing, ...incoming], '__ref'),
          },
        },
      },
      Bound: {
        keyFields: ['properties', ['id']],
      },
      Annotation: {
        fields: {
          properties: {
            merge: true,
          },
          geometry: {
            merge: true,
          },
        },
      },
      AnnotationProperties: {
        fields: {
          state: {
            read: (value = null) => value,
          },
        },
      },
      AnnotationCategory: {
        fields: {
          color: {
            read: (value) => value,
            merge: (existing, incoming) => incoming || existing,
          },
        },
      },
      Tagger: {
        keyFields: false,
      },
      Tag: {
        keyFields: false,
      },
      Properties: {
        keyFields: false,
      },
    },
  });

export default createCache;
