import {
  Divider,
  FeaturePopup,
  FeaturePopupAction,
  FeaturePopupMetadata,
} from '@aignostics/components';
import { formatDate } from '@aignostics/utils';
import { gql, useApolloClient } from '@apollo/client';
import { Polygon } from 'geojson';
import { Feature, MapBrowserEvent, Map as OLMap, Overlay } from 'ol';
import { Geometry } from 'ol/geom';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Annotation, AnnotationFeatureType } from '../../../../../api-types';
import {
  setAnnotationGeometry,
  setAnnotationStateAndGeometry,
} from '../../../../../graphql/helpers';
import {
  closeRingPolygon,
  isRingPolygon,
} from '../../../../../utils/closeRingPolygon';
import { useAnnotations } from '../../../../__Features/Annotations';
import { deleteAnnotation } from '../../../../__Features/Annotations/utils';
import {
  useDrawingModeActions,
  useDrawingModeState,
} from '../../../../__Features/Drawing';
import { SlideRouteParams } from '../../../Slide';
import {
  useSelectedFeature,
  useSetSelectedFeature,
} from '../../../ViewerController';
import {
  useActiveFeature,
  useSetActiveFeature,
} from '../../../ViewerLayerState/ActiveFeatureProvider';
import { flyToFeature } from '../../utils';
import { calculateBottomCenter } from './calculateBottomCenter';
import calculatePopupPosition from './calculatePopupPosition';

const popupPixelOffset: [number, number] = [0, -5];
const addPixelOffsetToCoordinate = (
  coordinate: [number, number],
  pixelOffSet: [number, number],
  map: OLMap
) => {
  const pixel = map.getPixelFromCoordinate(coordinate);

  if (!pixel) {
    return [coordinate[0] - pixelOffSet[0], coordinate[1] - pixelOffSet[1]];
  }

  const newPixel = [pixel[0] - pixelOffSet[0], pixel[1] + pixelOffSet[1]];
  return map.getCoordinateFromPixel(newPixel);
};

const Popup = ({
  width,
  height,
  annotationFeature,
  map,
  canEditAnnotation,
}: {
  width: number;
  height: number;
  annotationFeature: AnnotationFeatureType;
  map: OLMap;
  canEditAnnotation: (annotation: Annotation) => boolean;
}): ReactElement => {
  const theme = useTheme();
  const drawingMode = useDrawingModeState();
  const client = useApolloClient();
  const { wsiId } = useParams<keyof SlideRouteParams>() as SlideRouteParams;
  const popupRef = useRef<HTMLDivElement>(null);
  const [popup, setPopup] = useState<Overlay>();
  const { setAnnotationDrawingMode } = useDrawingModeActions();
  const setActiveFeature = useSetActiveFeature();
  const activeFeature = useActiveFeature();
  const [{ annotations }] = useAnnotations(true);
  const setSelectedFeature = useSetSelectedFeature();
  const selectedFeature = useSelectedFeature();

  // Programmatically close popup
  const closePopup = () => {
    setSelectedAnnotationRef(null);
  };

  // Delete local annotation and mark db annotation for deletion.
  const deleteFeature = () => {
    if (selectedFeature) {
      const id = selectedFeature.id;
      const state = selectedFeature.properties.state;
      deleteAnnotation(client, state, id, wsiId);
    }
    closePopup();
  };

  /** Fly to annotation bounds */
  const flyToAnnotation = () => {
    if (selectedFeature) {
      flyToFeature({
        feature: selectedFeature,
        height,
        width,
        view: map.getView(),
        duration: 500,
        theme,
      });
    }
  };

  useEffect(() => {
    if (!popupRef.current) return;

    const popup = new Overlay({
      element: popupRef.current,
    });
    setPopup(popup);
    map.addOverlay(popup);

    return () => {
      map.removeOverlay(popup);
    };
  }, [map]);

  useLayoutEffect(() => {
    if (!popup || !selectedFeature?.geometry.coordinates) return;

    const center = calculateBottomCenter(
      selectedFeature.geometry.coordinates[0] as [number, number][]
    );
    const centerWithOffset = addPixelOffsetToCoordinate(
      center,
      popupPixelOffset,
      map
    ) as [number, number];

    popup.setPosition(
      calculatePopupPosition(centerWithOffset, popup, map, height)
    );

    const handlerPostRender = () => {
      popup.setPosition(
        calculatePopupPosition(centerWithOffset, popup, map, height)
      );
    };

    map.on('postrender', handlerPostRender);

    // Cleanup on unmount
    return () => {
      map.un('postrender', handlerPostRender);
    };
  }, [popup, selectedFeature?.geometry.coordinates, map, height]);

  const cachedAnnotation = client.cache.readFragment<{
    id: string;
    geometry: Polygon;
  }>({
    id: `Annotation:${selectedFeature?.id}`,
    fragment: gql`
      fragment SelectedAnnotation on Annotation {
        id
        geometry
      }
    `,
  });

  const isRing = useMemo(
    () =>
      (selectedFeature &&
        cachedAnnotation &&
        isRingPolygon(cachedAnnotation.geometry)) ??
      false,
    [selectedFeature, cachedAnnotation]
  );

  const setSelectedAnnotationRef = useCallback(
    (annotationId: string | null) => {
      if (annotationId === null) {
        if (selectedFeature !== null) {
          setSelectedFeature(null);
        }
        if (activeFeature?.type !== 'annotation') return;

        setActiveFeature(null);
        return;
      }
      if (selectedFeature?.id === annotationId) return;

      // check if clicked feature is an annotation
      const selectedAnnotation = annotations?.find(
        ({ id }) => annotationId === id
      );
      if (!selectedAnnotation) {
        if (selectedFeature !== null) {
          setSelectedFeature(null);
        }
        return;
      }

      setSelectedFeature(selectedAnnotation);
      setActiveFeature({ id: annotationId, type: 'annotation' });
    },
    [
      selectedFeature,
      annotations,
      setSelectedFeature,
      setActiveFeature,
      activeFeature?.type,
    ]
  );

  const handleClick = useCallback(
    ({ pixel }: MapBrowserEvent<UIEvent>) => {
      // Do not show popup for picker mode, as we want to be able to directly
      // update the category or delete the feature
      if (
        drawingMode.mode === 'annotation' &&
        drawingMode.tool.name === 'picker'
      ) {
        return;
      }

      /** Move popup to mouse position on click */
      const featureAtPixel = map.forEachFeatureAtPixel(
        pixel,
        (feature) => feature as Feature<Geometry>
      );

      const id = featureAtPixel?.getId() as string | undefined;
      const category = featureAtPixel?.get('category');

      setSelectedAnnotationRef(id ?? null);

      if (
        category !== null &&
        category !== undefined &&
        annotationFeature === 'ON' &&
        drawingMode.mode === 'annotation'
      ) {
        setAnnotationDrawingMode({
          category,
          tool: drawingMode.tool,
        });
      }
    },
    [
      drawingMode,
      map,
      setSelectedAnnotationRef,
      annotationFeature,
      setAnnotationDrawingMode,
    ]
  );

  // Close ring feature and updates its state if required
  const closeRingFeature = () => {
    if (selectedFeature) {
      const closedPolygon = closeRingPolygon(selectedFeature.geometry);

      // Update the selected annotation's state and geometry
      if (selectedFeature.properties.state !== 'create') {
        setAnnotationStateAndGeometry(
          client,
          selectedFeature.id,
          'update',
          closedPolygon
        );
      } else {
        setAnnotationGeometry(client, selectedFeature.id, closedPolygon);
      }

      setSelectedFeature({
        ...selectedFeature,
        geometry: closedPolygon,
      });
    }
  };

  useEffect(() => {
    // Attach click handlers
    map.on('click', handleClick);

    return () => {
      map.un('click', handleClick);
    };
  }, [map, popup, handleClick]);

  const isEditAnnotationEnabled =
    selectedFeature && canEditAnnotation(selectedFeature);

  return (
    <FeaturePopup
      ref={popupRef}
      title={selectedFeature?.properties.category.name}
      color={selectedFeature?.properties.category.color ?? theme.colors.black}
      isVisible={selectedFeature !== null}
      onClose={closePopup}
      metadata={
        <>
          <FeaturePopupMetadata
            icon="User"
            text={
              selectedFeature?.properties.createdBy?.name ??
              selectedFeature?.properties.createdBy?.email ??
              'Unknown'
            }
          />
          <FeaturePopupMetadata
            icon="Clock"
            text={formatDate(selectedFeature?.properties?.createdAt || '')}
          />
        </>
      }
      actions={
        <>
          {selectedFeature && isRing && isEditAnnotationEnabled && (
            <FeaturePopupAction
              onClick={closeRingFeature}
              icon="Octagon"
              name="Close Polygon"
            />
          )}
          <FeaturePopupAction
            onClick={flyToAnnotation}
            icon="Target"
            name="Focus"
          />
          {isEditAnnotationEnabled && (
            <FeaturePopupAction
              onClick={deleteFeature}
              icon="Trash"
              name="Delete"
            />
          )}
        </>
      }
    >
      {/* Show feature state */}
      {selectedFeature?.properties.state && (
        <Divider color="light">{selectedFeature.properties.state}</Divider>
      )}
    </FeaturePopup>
  );
};

export default Popup;
