import styled from 'styled-components';
export const $ToggleSplitViewWrapper = styled.div `
  display: flex;
  background-color: ${({ theme }) => theme.colors.transparentDark};
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  padding: ${({ theme }) => theme.spacings.base}px;
  gap: ${({ theme }) => theme.spacings.base}px;
`;
export const $SplitViewLabel = styled.label `
  cursor: pointer;
  display: flex;
  background-color: ${({ theme }) => theme.colors.transparent};
  width: ${({ theme }) => theme.spacings.button}px;
  height: ${({ theme }) => theme.spacings.line}px;
  gap: ${({ theme }) => theme.spacings.tiny}px;
  padding: ${({ theme }) => theme.spacings.tiny}px;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  transition: box-shadow ${({ theme }) => theme.animationSpeeds.slow};
  box-shadow: ${({ theme }) => theme.colors.transparent} 0px 0px 0px 2px;

  input:checked + & {
    box-shadow: ${({ theme }) => theme.colors.warning} 0px 0px 0px 2px;
  }
`;
export const $SplitViewButtonPanel = styled.div `
  flex-grow: 1;
  height: 100%;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.light};
  transition: background-color ${({ theme }) => theme.animationSpeeds.fast};

  ${$SplitViewLabel}:hover & {
    background-color: ${({ theme }) => theme.colors.lighter};
  }

  ${$SplitViewLabel}:active & {
    background-color: ${({ theme }) => theme.colors.mid};
  }
`;
