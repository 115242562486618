import { useSnackbarMutations } from '@aignostics/components';
import { gql, useMutation } from '@apollo/client';

type SetSubProjectVisibility = (
  subProjectId: string,
  visibility: boolean,
  projectId?: string
) => Promise<void>;

export function useSetSubProjectVisibility(): SetSubProjectVisibility {
  const [setSubProjectVisibility] = useMutation<
    void,
    { subProjectId: string; visibility: boolean }
  >(gql`
    mutation SET_SUBPROJECT_VISIBILITY(
      $subProjectId: ID!
      $visibility: Boolean!
    ) {
      setSubProjectVisibility(
        subProjectId: $subProjectId
        visibility: $visibility
      )
    }
  `);
  const { addSnackbar } = useSnackbarMutations();

  return (subProjectId, visibility, projectId) =>
    setSubProjectVisibility({
      variables: { subProjectId, visibility },
      update: (cache) => {
        if (projectId) {
          cache.modify({
            id: `Project:${projectId}`,
            fields: {
              subProjects() {
                cache.modify({
                  id: `SubProject:${subProjectId}`,
                  fields: {
                    isVisible() {
                      return visibility;
                    },
                  },
                });
              },
            },
          });
        } else {
          cache.writeFragment({
            id: `SubProject:${subProjectId}`,
            fragment: gql`
              fragment SET_SUBPROJECT_VISIBILITY on SubProject {
                isVisible
              }
            `,
            data: { isVisible: visibility },
          });
        }
      },
    })
      .then(() => {
        addSnackbar({
          type: 'success',
          message: `Subproject is now ${visibility ? 'visible' : 'invisible'}`,
        });
      })
      .catch(() => {
        addSnackbar({
          message: `Error setting subproject visibility`,
          type: 'error',
        });
      });
}
