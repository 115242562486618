import { OrganizationRole, User } from '@aignostics/core';
import { useEventListener, useKeyPress } from '@aignostics/hooks';
import { Map as OLMap } from 'ol';
import React, { ReactElement, useCallback, useState } from 'react';
import {
  AnnotationFeatureType,
  OtherAnnotationVisibility,
} from '../../../../../api-types';
import { DrawingMode } from '../../../../__Features/Drawing/DrawingMode';
import AnnotationDrawingLayer from './AnnotationDrawingLayer';
import FocusAreaDrawingLayer from './FocusAreaDrawingLayer';
import RegionsDrawingLayer from './RegionsDrawingLayer';

interface DrawingLayersProps {
  width: number;
  height: number;
  maxZoom: number;
  map: OLMap;
  zIndex: number;
  otherAnnotationVisibility: OtherAnnotationVisibility;
  annotationFeature: AnnotationFeatureType;
  drawingMode: DrawingMode;
  currentUser: User;
  currentUserRole: OrganizationRole;
  disableDrawing: () => void;
}
export function DrawingLayers({
  width,
  height,
  maxZoom,
  map,
  zIndex,
  otherAnnotationVisibility,
  annotationFeature,
  drawingMode,
  disableDrawing,
  currentUser,
  currentUserRole,
}: DrawingLayersProps): ReactElement | null {
  const [isDrawingPaused, setIsDrawingPaused] = useState(false);
  // Pause drawing while space key is pressed
  const handleSpaceDown = useCallback(({ code }: KeyboardEvent) => {
    if (code === 'Space') {
      setIsDrawingPaused(true);
    }
  }, []);

  const handleSpaceUp = useCallback(({ code }: KeyboardEvent) => {
    if (code === 'Space') {
      setIsDrawingPaused(false);
    }
  }, []);

  useEventListener('keydown', handleSpaceDown);
  useEventListener('keyup', handleSpaceUp);

  // Exit drawing mode when pressing on escape
  useKeyPress({ key: 'Escape' }, () => {
    disableDrawing();
  });

  if (isDrawingPaused) return null;

  switch (drawingMode.mode) {
    case 'annotation':
      return (
        <AnnotationDrawingLayer
          drawingMode={drawingMode}
          width={width}
          height={height}
          maxZoom={maxZoom}
          map={map}
          zIndex={zIndex}
          otherAnnotationVisibility={otherAnnotationVisibility}
          annotationFeature={annotationFeature}
          currentUser={currentUser}
          currentUserRole={currentUserRole}
        />
      );
    case 'focusArea':
      return (
        <FocusAreaDrawingLayer
          height={height}
          map={map}
          maxZoom={maxZoom}
          size={drawingMode.tool.size}
          disableDrawing={disableDrawing}
        />
      );
    case 'regionsOfInterest':
      return (
        <RegionsDrawingLayer
          height={height}
          map={map}
          maxZoom={maxZoom}
          size={drawingMode.toolSize}
          disableDrawing={disableDrawing}
        />
      );
    case 'off':
      return null;
  }
}
