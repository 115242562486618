import {
  Filter,
  FilterField,
  PageLayout,
  Pagination,
  Section,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { useQuery, type QueryResult } from '@apollo/client';
import React, { VoidFunctionComponent, useMemo, useState } from 'react';
import { OnboardingBatches } from '../components/OnboardingBatches/OnboardingBatches.component';
import {
  FilterKeys,
  getOnboardingBatchFilters,
} from '../components/OnboardingBatches/OnboardingBatches.utils';
import { OnboardingBatchStatus } from '../graphql/api.types';
import {
  FETCH_ONBOARDING_BATCHES,
  FetchedOnboardingBatchItem,
} from '../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { FETCH_ONBOARDING_FILTERS_OPTIONS } from '../graphql/queries/FETCH_ONBOARDING_FILTERS_OPTIONS';
import {
  FilterConfigs,
  getFiltersFromQueryParams,
  useFilters,
} from '../hooks/useFilters';
import { getPageFromQueryParams, usePagination } from '../hooks/usePagination';
import { useSetQueryParams } from '../hooks/useSetQueryParams';
import useUserAssignedAssociation from '../hooks/useUserAssignedAssociation';
import { Association, Pagination as IPagination } from '../types';

const PAGE_SIZE = 12;

type FetchOnboardingBatchesQuery = {
  onboardingBatches: IPagination<
    FetchedOnboardingBatchItem,
    {
      users: User[];
      associations: Association[];
      statuses: OnboardingBatchStatus[];
      uploadDates: { created_at: string }[];
    }
  >;
};

const useFetchOnboardingBatchesQuery = ({
  page,
  pageSize,
  name,
  association,
  createdBy,
  status,
  uploadingDate,
}: {
  page: number;
  pageSize: number;
  name?: string | string[];
  association?: string | string[];
  createdBy?: string | string[];
  status?: string | string[];
  uploadingDate?: string;
}): QueryResult<FetchOnboardingBatchesQuery> =>
  useQuery(FETCH_ONBOARDING_BATCHES, {
    pollInterval: 10000,
    variables: {
      page,
      pageSize,
      search: name,
      association,
      createdBy,
      status,
      uploadingDate,
    },
  });

const PAGE_FILTER_CONFIG: FilterConfigs<FilterKeys> = {
  name: { fallbackValue: '', type: 'string' },
  createdBy: { fallbackValue: [], type: 'array' },
  status: { fallbackValue: [], type: 'array' },
  association: { fallbackValue: [], type: 'array' },
  uploadingDate: { fallbackValue: '', type: 'string' },
};

type AdminDataOnboardingProps = {
  externalBucket: string | null;
  role: OrganizationRole;
  currentUser: User;
};

const AdminDataOnboarding: VoidFunctionComponent<AdminDataOnboardingProps> = ({
  externalBucket,
  role,
  currentUser,
}: AdminDataOnboardingProps) => {
  const [page, setPage] = usePagination(getPageFromQueryParams());
  const { filterProps, filters } = useFilters<FilterKeys>(
    PAGE_FILTER_CONFIG,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIG)
  );
  const queryParams = useMemo(
    () => ({ page: page.toString(), ...filters }),
    [page, filters]
  );
  useSetQueryParams(queryParams);
  const { name, association, createdBy, status, uploadingDate } = filters;
  const [filterOptions, setFiltersOptions] = useState({
    users: [],
    statuses: [],
    uploadDates: [],
    associations: [],
  });
  useQuery(FETCH_ONBOARDING_FILTERS_OPTIONS, {
    onCompleted(data) {
      const { users, statuses, uploadDates, associations } =
        data.onboardingBatches.collectionAttributes;
      setFiltersOptions({ users, statuses, uploadDates, associations });
    },
  });
  const { data, loading, error } = useFetchOnboardingBatchesQuery({
    page: page ?? 1,
    pageSize: PAGE_SIZE,
    name,
    association: association.length > 0 ? association : undefined,
    createdBy,
    status: status.length > 0 ? status : undefined,
    uploadingDate: uploadingDate as string,
  });
  const { userAssignedAssociationLoading, userAssignedAssociation } =
    useUserAssignedAssociation();
  const filterFields = getOnboardingBatchFilters({
    users: filterOptions.users,
    statuses: filterOptions.statuses,
    uploadDates: filterOptions.uploadDates,
    associations: filterOptions.associations,
    userAssignedAssociation,
  });
  const totalPages = data?.onboardingBatches.pageInfo.totalPages ?? 0;

  return (
    <PageLayout
      title="Data Onboarding"
      description="Overview, status and progress of onboarding batches."
      loading={loading && userAssignedAssociationLoading}
      error={error}
      currentOrganization={role.organization.name}
    >
      <Section>
        <div>
          <Filter
            fields={filterFields as Record<FilterKeys, FilterField>}
            isOpen={false}
            {...filterProps}
            onChange={(filters) => {
              filterProps.onChange(filters);
              setPage(1);
            }}
          />
        </div>
        <OnboardingBatches
          userAssignedAssociation={userAssignedAssociation}
          data={data?.onboardingBatches.nodes}
          totalBatchesCount={data?.onboardingBatches.pageInfo?.totalElements}
          page={page}
          pageSize={PAGE_SIZE}
          isLoading={loading}
          externalBucket={externalBucket}
          role={role}
          currentUser={currentUser}
        />
      </Section>
      {totalPages && (
        <div style={{ margin: '12px auto' }}>
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChanged={setPage}
          />
        </div>
      )}
    </PageLayout>
  );
};

export default AdminDataOnboarding;
