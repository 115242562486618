import { decodeQueryParams, encodeQueryParams } from 'use-query-params';
import * as yup from 'yup';
import { buildQueryParams } from '../../../../utils/queryString';
import {
  ActiveAnnotationCategoriesParamKey,
  ActiveAnnotatorsParamKey,
  BoundaryParamKey,
  EncodedViewerQueryParams,
  FluorescenceChannelParamKey,
  InteractiveOverlaysParamKey,
  PARAMS_CONFIG,
  StaticOverlaysParamKey,
  VIEWERS_LAYERS_ARRAY_PARAM_KEY,
  ViewerQueryParams,
  VisibleRegionsParamKey,
  WsiLayersParamKey,
} from './QueryParamKeys';
import {
  ViewerLayersParams,
  ViewerLayersParamsKeys,
} from './ViewerLayersParamsType';

const paramsMap: Record<ViewerLayersParamsKeys, string> = {
  wsiLayers: WsiLayersParamKey,
  staticOverlays: StaticOverlaysParamKey,
  interactiveOverlays: InteractiveOverlaysParamKey,
  activeUsers: ActiveAnnotatorsParamKey,
  activeCategories: ActiveAnnotationCategoriesParamKey,
  fluorescenceChannels: FluorescenceChannelParamKey,
  isBoundaryVisible: BoundaryParamKey,
  visibleRegions: VisibleRegionsParamKey,
};

export const defaultParamValues: Omit<ViewerLayersParams, 'wsiLayers'> = {
  staticOverlays: {},
  interactiveOverlays: {},
  fluorescenceChannels: [],
  activeCategories: [],
  activeUsers: [],
  isBoundaryVisible: false,
  visibleRegions: null,
};

/**
 *
 * Get viewer params from the given query params
 */
export const getViewerParamsFromQueryParams = (
  queryParams: Partial<ViewerQueryParams>
): ViewerLayersParams => ({
  wsiLayers: queryParams[WsiLayersParamKey] as ViewerLayersParams['wsiLayers'],
  staticOverlays: queryParams.overlays ?? {},
  interactiveOverlays: queryParams[InteractiveOverlaysParamKey] ?? {},
  activeUsers: queryParams[ActiveAnnotatorsParamKey],
  activeCategories: queryParams[ActiveAnnotationCategoriesParamKey],
  fluorescenceChannels: queryParams[FluorescenceChannelParamKey],
  isBoundaryVisible: queryParams[BoundaryParamKey] ?? false,
  visibleRegions: queryParams[VisibleRegionsParamKey] ?? null,
});

// Schema used to cast from qs parsed string to the desired
// EncodedViewerQueryParams array
export const viewerLayersSchema = yup.array().of(
  yup.object().shape({
    [WsiLayersParamKey]: yup.string(),
    [StaticOverlaysParamKey]: yup.string(),
    [InteractiveOverlaysParamKey]: yup.string(),
    [ActiveAnnotatorsParamKey]: yup.string(),
    [ActiveAnnotationCategoriesParamKey]: yup.string(),
    [FluorescenceChannelParamKey]: yup.string(),
    [BoundaryParamKey]: yup.string(),
    [VisibleRegionsParamKey]: yup.array(yup.string().nullable()).nullable(),
  })
);

/**
 *
 * Get query params from given partial viewer params
 */
export const viewerParamsToQueryParams = (
  viewerParams: Partial<ViewerLayersParams>
): Partial<ViewerQueryParams> => {
  return Object.entries(viewerParams).reduce((acc, curr) => {
    const [paramKey, paramValue] = curr as [
      ViewerLayersParamsKeys,
      ViewerLayersParams[ViewerLayersParamsKeys],
    ];
    const queryParamKey = paramsMap[paramKey];

    return { ...acc, [queryParamKey]: paramValue };
  }, {});
};

/**
 * Encode Viewers Layers Params to Viewers Layers Search Params
 */
export const encodeViewerLayersParams = (
  viewersLayersParams: Partial<ViewerQueryParams>[]
): Partial<EncodedViewerQueryParams>[] => {
  const encodedParams = viewersLayersParams.map((params) =>
    encodeQueryParams(PARAMS_CONFIG, params)
  );

  return encodedParams;
};

/**
 * Decode Viewers Layers Search Params to Viewers Layers Params
 */
export const decodeViewerLayersParams = (
  viewersLayersSearchParams: Partial<EncodedViewerQueryParams>[]
): Partial<ViewerQueryParams>[] => {
  // viewersLayersSearchParams is received as an object
  // {0: encoded VLS, 1: encoded VLS...}
  const decodedArray: Partial<ViewerQueryParams>[] =
    viewersLayersSearchParams.map((viewerLayersSearchParams) =>
      decodeQueryParams(PARAMS_CONFIG, viewerLayersSearchParams)
    );

  return decodedArray;
};

/**
 * Build search string from encoded Viewers Layers Search Params
 * and remaning search params
 *
 * @param encodedViewersLayersParams
 * @param searchParams
 * @returns                          Built search string
 */
export const addViewersLayersParamsToSearchParams = (
  viewersLayersSearchParams: Partial<EncodedViewerQueryParams>[],
  searchParams?: Record<string, unknown>
): string => {
  return buildQueryParams({
    ...(searchParams ?? {}),
    [VIEWERS_LAYERS_ARRAY_PARAM_KEY]: viewersLayersSearchParams,
  });
};
