import {
  Filter,
  FilterConfigs,
  FilterField,
  HStack,
  LoaderBar,
  Pagination,
  Section,
  TableSkeleton,
  VStack,
  useFilters,
  usePagination,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useDebounce } from '@aignostics/hooks';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
  Association,
  Batch,
  Disease,
  PaginationType,
  Scanner,
  Staining,
  SubProject,
  Tissue,
  Wsi,
} from '../../../../../types';
import { FETCH_SUBPROJECT_WSIS } from '../../../FETCH_SUBPROJECT_WSIS';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from '../../AdminSubProjectAssignInteractiveOverlays/FETCH_INTERACTIVE_OVERLAYS_MATRIX';
import { READ_SUBPROJECT_SLIDES } from '../READ_SUBPROJECT_SLIDES.queries';
import { ASSIGN_ALL_SLIDES } from './ASSIGN_ALL_SLIDES.mutation';
import { FETCH_WSIS, GET_FILTERS } from './FETCH_WSIS.queries';
import GET_MENU_SIDEBAR_WSIS from './GET_MENU_SIDEBAR_WSIS';
import { SlideAssignmentTable } from './SlideAssignmentTable';
import {
  WsisAssignmentFilter,
  useSubProjectSlideAssignment,
} from './useSubProjectSlideAssignment';

type FilterKeysWithoutAssociation =
  | 'searchWsis'
  | 'batches'
  | 'tissues'
  | 'stainings'
  | 'scanners'
  | 'objectivePowers'
  | 'caseId'
  | 'diseases';

type FilterKeysWithAssociation = FilterKeysWithoutAssociation | 'associations';

type AdminSubProjectSlidesFilterConfig =
  | FilterConfigs<FilterKeysWithoutAssociation>
  | FilterConfigs<FilterKeysWithAssociation>;

const PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION: AdminSubProjectSlidesFilterConfig =
  {
    searchWsis: { fallbackValue: '', type: 'string' },
    batches: { fallbackValue: [], type: 'array' },
    tissues: { fallbackValue: [], type: 'array' },
    stainings: { fallbackValue: [], type: 'array' },
    scanners: { fallbackValue: [], type: 'array' },
    objectivePowers: { fallbackValue: [], type: 'array' },
    caseId: { fallbackValue: '', type: 'string' },
    diseases: { fallbackValue: [], type: 'array' },
  };

const PAGE_FILTER_CONFIG_WITH_ASSOCIATION: AdminSubProjectSlidesFilterConfig = {
  ...PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION,
  associations: { fallbackValue: [], type: 'array' },
};

const PAGE_SIZE = 20;

/**
 * Subproject slide assignment Component
 */
const AdminSubProjectSlidesAssignment = ({
  subProject,
  showAssociations,
  organizationUuid,
  getToken,
  rasterTileServerUrl,
  userRole,
}: {
  subProject: SubProject;
  showAssociations: boolean;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  userRole: OrganizationRole;
  organizationUuid: string;
}): ReactElement => {
  const theme = useTheme();
  const [page, setPage] = usePagination();

  const { filters, filterProps } = useFilters(
    showAssociations
      ? PAGE_FILTER_CONFIG_WITH_ASSOCIATION
      : PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION
  );

  const { addSnackbar } = useSnackbarMutations();

  const debouncedFilters = useDebounce(filters, 200);

  const queryFilters: WsisAssignmentFilter = useMemo(
    () => ({
      search: debouncedFilters.searchWsis,
      associations:
        (
          debouncedFilters as Record<
            FilterKeysWithAssociation,
            string | string[]
          >
        ).associations ?? [],
      batches: debouncedFilters.batches,
      case: debouncedFilters.caseId,
      objectivePowers: (debouncedFilters.objectivePowers as string[]).map(
        (value) => parseFloat(value)
      ),
      scanners: (debouncedFilters.scanners as string[]).map((value) =>
        parseInt(value)
      ),
      stainings: (debouncedFilters.stainings as string[]).map((value) =>
        parseInt(value)
      ),
      tissues: (debouncedFilters.tissues as string[]).map((value) =>
        parseInt(value)
      ),
      diseases: debouncedFilters.diseases as string[],
    }),
    [debouncedFilters]
  );

  const [getFilters, { data: filtersData }] = useLazyQuery<{
    associations: Association[];
    batches: Batch[];
    stainings: Staining[];
    tissues: Tissue[];
    scanners: Scanner[];
    diseases: Disease[];
  }>(GET_FILTERS, {
    variables: {
      associations: queryFilters.associations,
    },
  });

  const {
    data,
    previousData,
    loading: fetchWsisLoading,
    error,
  } = useQuery<{
    wsis: PaginationType<Wsi>;
  }>(FETCH_WSIS, {
    variables: {
      subProjectId: subProject.id,
      page,
      pageSize: PAGE_SIZE,
      ...queryFilters,
    },
    onCompleted: () => {
      if (!filtersData) {
        void getFilters();
      }
    },
  });

  const wsis = (data || previousData)?.wsis;

  const associations = filtersData?.associations ?? [];
  const batches = filtersData?.batches ?? [];
  const scanners = filtersData?.scanners ?? [];
  const stainings = filtersData?.stainings ?? [];
  const tissues = filtersData?.tissues ?? [];
  const diseases = filtersData?.diseases ?? [];

  const {
    addWsiToSubproject,
    removeWsiFromSubproject,
    loading: assignmentLoading,
  } = useSubProjectSlideAssignment(subProject.id);

  const [
    assignAllWsisToSubProjectRequest,
    { loading: assignAllWsisToSubProjectLoading },
  ] = useMutation(ASSIGN_ALL_SLIDES, {
    onCompleted() {
      addSnackbar({
        message: 'All slides matching the filter were added',
        type: 'success',
      });
    },
    onError() {
      if (error instanceof Error) {
        addSnackbar({
          message: `Error adding all slides to subproject: ${error.message}`,
          type: 'error',
        });
      } else {
        addSnackbar({
          message: 'Error adding all slides to subproject.',
          type: 'error',
        });
      }
    },
  });

  const addAllWsisToSubProject = useCallback(() => {
    void assignAllWsisToSubProjectRequest({
      variables: { subProjectId: subProject.id, ...queryFilters },
      awaitRefetchQueries: true,
      refetchQueries: [
        READ_SUBPROJECT_SLIDES,
        GET_MENU_SIDEBAR_WSIS,
        FETCH_SUBPROJECT_WSIS,
        FETCH_WSIS,
        {
          query: FETCH_INTERACTIVE_OVERLAYS_MATRIX,
          variables: {
            subProjectId: subProject.id,
            stage: null,
            searchOverlayName: null,
          },
          fetchPolicy: 'network-only',
        },
      ],
    });
  }, [assignAllWsisToSubProjectRequest, queryFilters, subProject.id]);

  const PAGE_FILTER_FIELDS:
    | Record<FilterKeysWithoutAssociation, FilterField>
    | Record<FilterKeysWithAssociation, FilterField> = {
    searchWsis: {
      type: 'search',
      label: 'Search',
      placeholder: 'Search slides',
      value: '',
    },

    ...(showAssociations
      ? {
          associations: {
            type: 'multiselect',
            label: 'Association',
            value: [],
            options: associations.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          },
        }
      : {}),
    batches: {
      type: 'multiselect',
      label: 'Batch',
      value: [],
      options: batches.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    caseId: {
      type: 'text',
      label: 'CaseID',
      value: '',
    },
    objectivePowers: {
      type: 'multiselect',
      label: 'Objective Power',
      value: [],
      options: [
        { label: '20', value: '20' },
        { label: '40', value: '40' },
      ],
    },
    scanners: {
      type: 'multiselect',
      label: 'Scanner',
      value: [],
      options: scanners.map(({ id, vendor, model }) => ({
        label: `${vendor} ${model ?? ''}`,
        value: id.toString(),
      })),
    },
    stainings: {
      type: 'multiselect',
      label: 'Staining',
      value: [],
      options: stainings.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    tissues: {
      type: 'multiselect',
      label: 'Localization',
      value: [],
      options: tissues.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    diseases: {
      type: 'multiselect',
      label: 'Disease',
      value: [],
      options: diseases.map(({ name }) => ({
        label: name,
        value: name,
      })),
    },
  };

  const loading =
    fetchWsisLoading || assignmentLoading || assignAllWsisToSubProjectLoading;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1,
        }}
      >
        <LoaderBar loading={loading} />
      </div>
      <Section
        background="white"
        error={error}
        style={{
          minWidth: '300px',
          position: 'relative',
          padding: '16px 32px 32px 32px',
        }}
      >
        <VStack spacing="32" alignItems="center">
          <Filter
            title="Filter"
            fields={PAGE_FILTER_FIELDS}
            {...filterProps}
            onChange={(filters) => {
              filterProps.onChange(filters);
              setPage(1);
            }}
          />
          {wsis !== undefined ? (
            <VStack
              spacing="24"
              alignItems="center"
              style={{ width: '100%', overflowX: 'scroll', minHeight: '50vh' }}
            >
              <HStack
                justifyContent="space-between"
                style={{
                  width: '100%',
                  padding: `0 ${theme.spacings.large}px`,
                  ...theme.fontStyles.small,
                }}
              >
                <div>
                  {wsis.nodes.length} of {wsis.pageInfo.totalElements} slides
                </div>
                <div>Added: {subProject?.wsisCount} slides</div>
              </HStack>

              <SlideAssignmentTable
                wsis={wsis.nodes}
                onWsiAssigned={addWsiToSubproject}
                onWsiUnassigned={removeWsiFromSubproject}
                onAllAssigned={addAllWsisToSubProject}
                loading={loading}
                subProject={subProject}
                totalElements={wsis.pageInfo.totalElements}
                rasterTileServerUrl={rasterTileServerUrl}
                getToken={getToken}
                organizationUuid={organizationUuid}
                userRole={userRole}
              />

              {wsis.pageInfo.totalPages > 0 && (
                <Pagination
                  currentPage={page}
                  onPageChanged={setPage}
                  totalPages={wsis.pageInfo.totalPages}
                />
              )}
            </VStack>
          ) : (
            <TableSkeleton rows={PAGE_SIZE + 1} />
          )}
        </VStack>
      </Section>
    </>
  );
};

export default AdminSubProjectSlidesAssignment;
