import styled from 'styled-components';

export const $SplitViewContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const $ToggleSplitViewContainer = styled.div`
  bottom: ${({ theme }) => theme.spacings.small}px;
  left: ${({ theme }) => theme.spacings.small}px;
  position: absolute;
`;
