import React from 'react';
import ReactSelect, { components, } from 'react-select';
import { useTheme } from 'styled-components';
import { Checkbox } from '../Checkbox';
import { FormControl } from '../FormControl';
import { HStack } from '../Stack';
import customStyles from './Select.styles';
import { constructInvalidSelectOption } from './SelectSingle.component';
import { useMenuWithAria } from './useMenuWithAria';
const getMultiSelectedOptions = (options, values, allowInvalidValues) => {
    const results = options.filter((o) => values.findIndex((v) => v === o.value) !== -1);
    if (allowInvalidValues) {
        const badValues = values.filter((value) => results.findIndex((result) => result.value === value) === -1);
        return [...badValues.map(constructInvalidSelectOption), ...results];
    }
    return results;
};
const ValueContainer = ({ children, ...props }) => {
    // Extract values and input from children to get an array of elements
    // first element is the value and second is the input. The values are re-written to the input.
    // This is done to prevent the elements being rendered in the input as the default behavior of react-select
    let [values] = children;
    const [, input] = children;
    const { getValue, hasValue } = props;
    const selectedValuesLength = getValue().length;
    const singleValueSelected = getValue();
    if (hasValue) {
        if (selectedValuesLength === 1) {
            values = singleValueSelected[0].label;
        }
        else {
            values = `${selectedValuesLength} selected`;
        }
    }
    return (React.createElement(components.ValueContainer, { ...props },
        values,
        input));
};
const Option = ({ ...props }) => {
    const { disabled } = props.data;
    return (React.createElement(components.Option, { ...props, isDisabled: disabled ?? false },
        React.createElement(HStack, { spacing: "base", alignItems: "center" },
            React.createElement(Checkbox, { checked: props.isSelected, disabled: disabled }),
            React.createElement("span", null, props.label))));
};
export const SelectMultiple = ({ icon, disabled, label, onChange, options, value, allowInvalidValues = false, id, isInvalid = false, errorMessage, helperMessage, required, placeholder, }) => {
    const normalizedOptions = () => {
        if (allowInvalidValues) {
            const badValues = value.filter((v) => options.findIndex((o) => o.value === v) === -1);
            return [...options, ...badValues.map(constructInvalidSelectOption)];
        }
        return options;
    };
    const selectedOption = getMultiSelectedOptions(normalizedOptions(), value, allowInvalidValues);
    const theme = useTheme();
    const Menu = useMenuWithAria(true, label);
    return (React.createElement(FormControl, { id: id, label: label, icon: icon, isRequired: required, isInvalid: isInvalid, errorMessage: errorMessage, helperMessage: helperMessage },
        React.createElement(ReactSelect, { inputId: id, isDisabled: disabled, isMulti: true, 
            // TODO(santi698): onChange may be incompatible due to isMulti and the
            //                 possibility of having no selection
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: onChange, options: normalizedOptions(), styles: customStyles(theme, isInvalid), value: selectedOption, components: { Option, ValueContainer, Menu }, hideSelectedOptions: false, isClearable: false, isOptionDisabled: (option) => option.disabled ?? false, placeholder: placeholder })));
};
