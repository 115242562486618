import { IconButton } from '@aignostics/components';
import { useViewport } from '@aignostics/hooks';
import { IconKey, Theme } from '@aignostics/theme';
import { useMotionValue, useTransform } from 'framer-motion';
import { Map } from 'ol';
import { Extent } from 'ol/extent';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Wsi } from '../../api-types';
import { useRegionsSettingsStore } from '../__Features/Annotations/FocusArea/useRegionsSettingsStore';
import { getFeatureExtent } from '../__Viewer/OpenLayers/utils';
import {
  $Magnifier,
  $MagnifierActiveIndicator,
  $MagnifierScale,
} from './Magnifier.styles';
import MagnifierLabel from './MagnifierLabel.component';
import {
  transformMagnificationLevelToZoomLevel,
  transformZoomLevelToMagnificationLevel,
} from './transform';

export const magnificationLevels = [1, 2, 5, 10, 20, 40, 80];

export interface MagnifierProps {
  map: Map;
  wsi: Wsi;
}

function getExtendedMagnificationLevels(map: Map, wsi: Wsi) {
  const minZoom = map.getView().getMinZoom();
  const maxZoom = map.getView().getMaxZoom();

  const minLevel = transformZoomLevelToMagnificationLevel(wsi, minZoom);
  const maxLevel = transformZoomLevelToMagnificationLevel(wsi, maxZoom);

  return [minLevel, ...magnificationLevels, maxLevel];
}

export type MagnifierState = 'scaled' | 'min' | 'max' | null;

export const warningStates: MagnifierState[] = ['scaled', 'max'];

function getMagnifierState(map: Map, wsi: Wsi): MagnifierState {
  const view = map.getView();
  const minZoom = view.getMinZoom();
  const maxZoom = view.getMaxZoom();
  const currentZoom = view.getZoom() ?? 0;

  if (currentZoom - minZoom < 0.1) return 'min';

  if (currentZoom >= maxZoom) return 'max';

  if (wsi.maxZoom > 0 && currentZoom > wsi.maxZoom) {
    return 'scaled';
  }

  return null;
}
/**
 * Controls for zooming the map.
 */
export const Magnifier = ({ map, wsi }: MagnifierProps): ReactElement => {
  const { getSelectedROI } = useRegionsSettingsStore((state) => ({
    getSelectedROI: state.getSelectedROI,
  }));
  const theme: Theme = useTheme();
  const [fitTarget, setFitTarget] = useState<'bounds' | 'focusArea'>('bounds');

  const activeMagnificationLevel = useMotionValue(
    transformZoomLevelToMagnificationLevel(wsi, map.getView().getZoom() ?? 0)
  );

  const { DOUBLE } = useViewport(theme.breakpoints);

  const [magnifierState, setMagnifierState] = useState(() =>
    getMagnifierState(map, wsi)
  );

  /** Set zoom to given magnification level */
  const setMagnification = (magnificationLevel: number) => {
    const _zoom = transformMagnificationLevelToZoomLevel(
      wsi,
      magnificationLevel
    );
    map.getView().setZoom(_zoom);
  };

  /**
   * Fit tissue in viewport. The extent will be the generated tissue boundary
   * outline if available, otherwise it will fallback to the image boundary.
   * When a focus area is present on the slide it will toggle between the
   * focus area and tissue boundary.
   */
  const zoomToBounds = () => {
    const { width, height } = wsi;

    const boundary = wsi.bounds[0];
    const regionOfInterest = getSelectedROI() ?? wsi.regionsOfInterest[0];
    const roiExtent =
      regionOfInterest && getFeatureExtent(regionOfInterest, width, height);

    const tissueExtent = boundary && getFeatureExtent(boundary, width, height);

    const imageExtent: Extent = [0, 0, wsi.width, wsi.height];

    if (roiExtent) {
      setFitTarget(fitTarget === 'bounds' ? 'focusArea' : 'bounds');
    }

    const targetExtent = fitTarget === 'focusArea' ? roiExtent : tissueExtent;

    map.getView().fit(targetExtent ?? imageExtent, {
      duration: 500,
      padding: [
        theme.spacings.button,
        theme.spacings.button,
        theme.spacings.button,
        theme.spacings.button,
      ],
    });
  };

  const extendedMagnificationLevels = getExtendedMagnificationLevels(map, wsi);

  const zoomOut = () => {
    const _magnificationLevel =
      Math.round(activeMagnificationLevel.get() * 100) / 100;

    const reversedLevels = extendedMagnificationLevels.slice().reverse();

    const indexBelow = reversedLevels.findIndex(
      (lvl) => _magnificationLevel - lvl > 0.1
    );
    const magnificationLevelBelow = reversedLevels[indexBelow];

    magnificationLevelBelow && setMagnification(magnificationLevelBelow);
  };

  const zoomIn = () => {
    const _magnificationLevel =
      Math.round(activeMagnificationLevel.get() * 100) / 100;

    const indexAbove = extendedMagnificationLevels.findIndex(
      (lvl) => lvl - _magnificationLevel > 0.1
    );
    const magnificationLevelAbove = extendedMagnificationLevels[indexAbove];

    magnificationLevelAbove && setMagnification(magnificationLevelAbove);
  };

  /** Update magnification level and magnifier state on zoom level change */
  useEffect(() => {
    const updateZoom = () => {
      const zoom = map.getView().getZoom() ?? 0;

      const _magnificationLevel = transformZoomLevelToMagnificationLevel(
        wsi,
        zoom
      );

      activeMagnificationLevel.set(_magnificationLevel);

      setMagnifierState(getMagnifierState(map, wsi));
    };
    const view = map.getView();

    view.on('change:resolution', updateZoom);

    return () => {
      view.un('change:resolution', updateZoom);
    };
  }, [activeMagnificationLevel, map, wsi]);

  /** Setup interpolation target values by index. */
  const transformTarget = magnificationLevels.map(
    (_, index) => index * theme.spacings.button
  );

  /** Map active magnification level to x offset for indicator. */
  const activeMagnificationLevelTransform = useTransform(
    activeMagnificationLevel,
    magnificationLevels,
    transformTarget,
    { clamp: false }
  );

  const fitButtonProps: { description: string; icon: IconKey } =
    fitTarget === 'focusArea'
      ? {
          description: 'Fit to Focus Area',
          icon: 'Minimize',
        }
      : {
          description: 'Fit to Bounds',
          icon: 'Maximize',
        };

  const minMagnification = transformZoomLevelToMagnificationLevel(
    wsi,
    map.getView().getMinZoom()
  );

  return (
    <$Magnifier>
      {/* Zoom out button */}
      <IconButton
        data-testid="magnifier-zoom-out"
        aria-label="Zoom Out"
        icon="ZoomOut"
        description="Zoom Out"
        onClick={zoomOut}
        onKey={{ key: '-' }}
        disabled={magnifierState === 'min'}
      />

      {/* Display current magnification level */}
      <MagnifierLabel
        activeMagnificationLevel={activeMagnificationLevel}
        magnifierState={magnifierState}
      />

      {/* Zoom in button */}
      <IconButton
        aria-label="Zoom In"
        icon="ZoomIn"
        description="Zoom In"
        onClick={zoomIn}
        onKey={{ key: '+' }}
        disabled={magnifierState === 'max'}
      />

      {/* Fit to tissue bounds */}
      <IconButton
        data-testid="magnifier-zoom-bounds"
        onClick={zoomToBounds}
        onKey={{ key: 'f' }}
        {...fitButtonProps}
      />

      {/* All magnification buttons */}
      {DOUBLE && (
        <$MagnifierScale role="group" aria-label="Magnifier scale">
          {magnificationLevels.map((lvl, index) => (
            <IconButton
              key={lvl}
              icon={<>{lvl}x</>}
              disabled={lvl < minMagnification}
              onClick={() => {
                setMagnification(lvl);
              }}
              onKey={{ key: (index + 1).toString(), ctrlKey: true }}
            />
          ))}
          <$MagnifierActiveIndicator
            data-testid="zoom-level-indicator"
            color={
              warningStates.includes(magnifierState)
                ? theme.colors.warning
                : theme.colors.white
            }
            style={{
              x: activeMagnificationLevelTransform,
            }}
          />
        </$MagnifierScale>
      )}
    </$Magnifier>
  );
};
