import { isTouchDevice } from '@aignostics/components';
import { Feature } from 'geojson';
import { Feature as OLFeature, Map as OLMap } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { addCoordinateTransforms } from 'ol/proj';
import RenderFeature from 'ol/render/Feature';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { getDataProjection, getProjection } from '../utils';
import LayerBaseComponent from './Layer.Base.component';

export type FeatureType = OLFeature<Geometry> | RenderFeature;
export type VectorLayerType = VectorLayer<VectorSourceType>;
type VectorSourceType = VectorSource<OLFeature<Geometry>>;

interface VectorFeatureLayerProps {
  features: Feature[];
  zIndex: number;
  opacity: number;
  width: number;
  height: number;
  getStyle?: (feature: FeatureType) => Style | undefined | Style[];
  getHighlightStyle?: (feature: FeatureType) => Style;
  getFeatureColor?: (feature: FeatureType) => string;
  map: OLMap;
  layerName?: string;
}

function VectorFeatureLayer({
  features,
  zIndex,
  opacity,
  width,
  height,
  getStyle: getStyleArg,
  getHighlightStyle,
  getFeatureColor: getFeatureColorArg,
  map,
  layerName,
}: VectorFeatureLayerProps): ReactElement {
  const theme = useTheme();
  const getFeatureColor = useMemo(
    () => getFeatureColorArg ?? (() => theme.colors.text),
    [getFeatureColorArg, theme.colors.text]
  );
  const getStyle = useMemo(
    () =>
      getStyleArg ??
      ((feature: FeatureType) =>
        new Style({
          stroke: new Stroke({ color: getFeatureColor(feature), width: 2 }),
        })),
    [getFeatureColor, getStyleArg]
  );
  const [hoveredFeature, setHoveredFeature] = useState<string | null>(null);
  const projection = useMemo(
    () => getProjection(width, height),
    [width, height]
  );
  const dataProjection = useMemo(
    () => getDataProjection(width, height),
    [width, height]
  );

  // Construct openlayers vector layer.
  const layer = useMemo(() => {
    const initSource = new VectorSource();

    return new VectorLayer({
      source: initSource,
      properties: {
        name: layerName,
      },
      updateWhileAnimating: true,
    });
  }, [layerName]);

  // Add given features to layer
  useEffect(() => {
    const olFeatures = new GeoJSON({
      dataProjection,
      featureProjection: projection,
    }).readFeatures({
      type: 'FeatureCollection',
      features,
    });

    const source = layer.getSource();

    if (source === null) {
      throw new Error('Invalid state: layer must always have a source');
    }

    source.clear();
    source.addFeatures(olFeatures);
  }, [dataProjection, features, layer, projection, layerName]);

  addCoordinateTransforms(
    dataProjection,
    projection,
    ([x, y]) => {
      return [x, -y + height];
    },
    ([x, y]) => {
      return [x, -y + height];
    }
  );

  // Track hovered features
  useEffect(() => {
    const handle = ({ pixel }: { pixel: number[] }) => {
      if (layer.getProperties().name === 'focusArea') return;
      const feature = map.forEachFeatureAtPixel(pixel, (feature) => feature);

      const element = map.getTargetElement();
      element.style.cursor = feature ? 'pointer' : 'auto';

      const featureId = feature?.getId();
      const featureIdString = featureId !== null ? String(featureId) : null;

      // Set the hovered feature state
      setHoveredFeature(featureIdString);
    };

    const eventType = isTouchDevice() ? 'click' : 'pointermove';
    map.on(eventType, handle);

    return () => {
      map.un(eventType, handle);
    };
  }, [map, layerName, layer]);

  useEffect(() => {
    layer.setStyle(getStyle);
    const source = layer.getSource();

    if (source && getHighlightStyle) {
      // Update style on hovered element.
      source.getFeatures().forEach((feature) => {
        if (hoveredFeature && hoveredFeature === feature.getId()) {
          const hoverStyle = getHighlightStyle(feature);
          feature.setStyle(hoverStyle);
        } else {
          // Reset other features
          feature.setStyle(undefined);
        }
      });
    }
  }, [hoveredFeature, getStyle, getHighlightStyle, layer]);

  return (
    <LayerBaseComponent
      layer={layer}
      zIndex={zIndex}
      opacity={opacity}
      map={map}
    />
  );
}

export default VectorFeatureLayer;
