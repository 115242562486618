import * as Sentry from '@sentry/react';
import { Feature } from 'geojson';
import { Feature as OLFeature, View } from 'ol';
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import Projection from 'ol/proj/Projection';
import TileGrid from 'ol/tilegrid/TileGrid';
import { DefaultTheme } from 'styled-components';

export { addPoints } from './addPoints';
export { default as closeLineString } from './closeLineString';
export { default as createAnnotation } from './createAnnotation';
export { createVectorTileUrlFactory } from './createVectorTileUrlFactory';
export { default as defaultInteractions } from './defaultInteractions';
export { distanceBetweenPoints } from './distanceBetweenPoints';
export { default as getBlendedColor } from './getBlendedColor';
export { default as getBoundary } from './getBoundary';
export { default as getBufferedGeometry } from './getBufferedGeometry';
export { default as getBufferedPolygonGeometry } from './getBufferedPolygonGeometry';
export { default as getDistance } from './getDistance';
export { getDistanceToLine } from './getDistanceToLine';
export { default as getGeoJsonFromPoints } from './getGeoJsonFromPoints';
export { default as getImageBoundaryPolygon } from './getImageBoundaryPolygon';
export { default as getOpaqueColor } from './getOpaqueColor';
export { default as getPointFromDrawingCoordinateFactory } from './getPointFromDrawingCoordinateFactory';
export { default as getProjectedSize } from './getProjectedSize';
export * from './interactions';
export { offsetCoordinatesFromCenter } from './offsetCoordinatesFromCenter';
export { default as preventTouch } from './preventTouch';
export { default as removeDecimalsFromPolygon } from './removeDecimalsFromPolygon';
export { resizeFromCenter } from './resizeFromCenter';
export { computeScaleValues } from './scale';
export { default as simplifyPolygon } from './simplifyPolygon';
export { default as sizeFromCoordinates } from './sizeFromCoordinates';
export { default as usePointerOverlay } from './usePointerOverlay';
export { useZoom } from './useZoom';

export const getExtent = (width: number, height: number): Extent => [
  0,
  0,
  width,
  height,
];

export const getProjection = (width: number, height: number): Projection =>
  new Projection({
    code: 'pixel-map',
    units: 'pixels',
    extent: getExtent(width, height),
  });

export const getProjectionWithAspectRatio = (
  width: number,
  height: number,
  aspectRatio: number
): Projection => {
  const baseAspectRatio = width / height;
  const aspectRatioDiff = baseAspectRatio / aspectRatio;
  if (aspectRatioDiff >= 1) {
    return new Projection({
      code: 'pixel-map',
      units: 'pixels',
      extent: getExtent(width, height * aspectRatioDiff),
    });
  } else {
    return new Projection({
      code: 'pixel-map',
      units: 'pixels',
      extent: getExtent(width / aspectRatioDiff, height),
    });
  }
};

export const getDataProjection = (width: number, height: number): Projection =>
  new Projection({
    code: 'pixel-data',
    units: 'pixels',
    extent: getExtent(width, height),
  });

/**
 * Setup resolutions array to be passed to layers and view setup
 */
export const getResolutions = (
  maxNativeZoom: number,
  // Pass higher maxZoom than maxNativeZoom to interpolate missing levels.
  maxZoom = maxNativeZoom,
  scale = 1
): number[] => {
  const resolutions = [];

  for (let index = maxZoom; index >= 0; index--) {
    resolutions.unshift(2 ** (maxNativeZoom - index) * scale);
  }

  return resolutions;
};

export const getTileGrid = ({
  width,
  height,
  maxZoom,
  maxNativeZoom = maxZoom,
  scale,
  origin,
}: {
  width: number;
  height: number;
  maxZoom: number;
  maxNativeZoom?: number;
  scale?: number;
  origin?: [number, number];
}): TileGrid =>
  new TileGrid({
    extent: getExtent(width, height),
    resolutions: getResolutions(maxNativeZoom, maxZoom, scale),
    origin,
  });

/**
 * Get a bounding box extent for the given feature.
 */
export const getFeatureExtent = (
  feature: Feature,
  width: number,
  height: number
): Extent => {
  const readFeature = new GeoJSON<OLFeature<Geometry>>({
    featureClass: OLFeature,
    featureProjection: getProjection(width, height),
    dataProjection: getDataProjection(width, height),
  }).readFeature(feature);

  if (Array.isArray(readFeature)) throw new Error('invariant violation');

  const geometry = readFeature.getGeometry();

  if (geometry === undefined) {
    throw new Error("Can't get feature extent");
  }
  return geometry.getExtent();
};

/**
 * Move the map center to the passed Feature.
 */
export const flyToFeature = ({
  view,
  feature,
  width,
  height,
  duration = 0,
  theme,
}: {
  view: View;
  feature: Feature;
  width: number;
  height: number;
  duration: number;
  theme: DefaultTheme;
}): void => {
  try {
    const flyToTargetCoordinates = getFeatureExtent(feature, width, height);
    view.fit(flyToTargetCoordinates, {
      duration,
      padding: [
        theme.spacings.button,
        theme.spacings.button,
        theme.spacings.button,
        theme.spacings.button,
      ],
    });
  } catch (error) {
    if (error instanceof Error) {
      Sentry.captureException(error);
    }
  }
};

/**
 * Update [x, y] coordinates to OL coordinates
 *
 * @param coordinates: [x, y] coordinates to be converted
 * @param height
 * @returns            [x, y] updated coordinates
 */
export const getOLCoordinates = (
  [x, y]: number[],
  height: number
): [number, number] => {
  return [x, -y + height];
};
