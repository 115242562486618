import styled from 'styled-components';
export const $ToggleInput = styled.input `
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
`;
export const $ToggleBox = styled.div `
  display: inline-flex;
  align-items: center;
  width: 24px;
  height: 14px;
  border-radius: 7px;
  transition: background-color ${({ theme }) => theme.animationSpeeds.slow};
`;
export const $ToggleSwitch = styled.div `
  background: ${({ theme }) => theme.colors.white};
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: 2px solid;
  transition: transform 100ms ease-in-out;
  overflow: hidden;
`;
export const $Toggle = styled.label `
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacings.button}px;
  height: ${({ theme }) => theme.spacings.button}px;
  transition: box-shadow ${({ theme }) => theme.animationSpeeds.slow};

  ${({ theme, componentTheme }) => theme.componentThemes[componentTheme]}

  /* Sibling  */
  input:focus-visible + & {
    box-shadow: ${({ theme }) => theme.glows.focusInner};
  }
  /* Sibling  */
  input:disabled + & {
    opacity: 0.3;
  }

  ${$ToggleSwitch} {
    transform: ${({ active }) => active ? `translateX(10px)` : 'translateX(0)'};
    border-color: ${({ active, theme }) => active ? theme.colors.accentViolet : theme.colors.base};
  }

  ${$ToggleBox} {
    background-color: ${({ active, theme }) => active ? theme.colors.accentViolet : theme.colors.base};
  }

  :focus-within {
    ${$ToggleBox} {
      box-shadow: ${({ theme }) => theme.glows.focus};
    }
  }

  &:not([aria-disabled='true']):hover {
    cursor: pointer;
  }

  &[aria-disabled='true'] > ${$ToggleBox} {
    opacity: 0.3;
  }
`;
