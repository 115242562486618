import { useCallback, useMemo } from 'react';
import {
  useSetDrawingMode,
  useViewerController,
} from '../../../__Viewer/ViewerController';
import {
  AnnotationDrawingMode,
  DrawingMode,
  FocusAreaDrawingMode,
  RegionsDrawingMode,
} from '../DrawingMode';

type SetAnnotationDrawingMode = (
  newState: Omit<AnnotationDrawingMode, 'mode'>
) => void;
type SetFocusAreaDrawingMode = (tool: FocusAreaDrawingMode['tool']) => void;
type SetRegionsDrawingMode = (toolSize: RegionsDrawingMode['toolSize']) => void;
type DisableDrawing = () => void;

type DrawingModeActions = {
  setAnnotationDrawingMode: SetAnnotationDrawingMode;
  setFocusAreaDrawingMode: SetFocusAreaDrawingMode;
  setRegionsDrawingMode: SetRegionsDrawingMode;
  disableDrawing: DisableDrawing;
};

/**
 * Hook returning the current drawing mode for each viewer.
 *
 * Enables to keep track of the potential current tool
 * and annotation category in case of annotation drawing mode.
 *
 * useDrawingModeState hooks into the state on the ViewerControllerProvider.
 * useDrawingModeActions is used as a setter for the current viewer in the ViewerController
 *
 * Need to refactor this into one single hook useDrawingMode? Imports would be modified by this
 * For simplicity keeping both functions exposed to Drawing as before
 */

/**
 * Expose drawing mode state
 */
export function useDrawingModeState(): DrawingMode {
  const viewerController = useViewerController();

  if (viewerController === null) {
    throw new Error(
      'useDrawingModeState must be used in a descendant of ViewerControllerProvider'
    );
  }

  return viewerController.viewers[viewerController.activeViewerIndex]
    .drawingMode;
}

/**
 * Expose method to set the drawing mode.
 */
export function useDrawingModeActions(): DrawingModeActions {
  const setDrawingMode = useSetDrawingMode();

  const setAnnotationDrawingMode = useCallback(
    (newMode: Omit<AnnotationDrawingMode, 'mode'>) => {
      setDrawingMode({ mode: 'annotation', ...newMode });
    },
    [setDrawingMode]
  );

  const setFocusAreaDrawingMode = useCallback(
    (tool: FocusAreaDrawingMode['tool']) => {
      setDrawingMode({ mode: 'focusArea', tool });
    },
    [setDrawingMode]
  );

  const setRegionsDrawingMode = useCallback(
    (toolSize: RegionsDrawingMode['toolSize']) => {
      setDrawingMode({ mode: 'regionsOfInterest', toolSize });
    },
    [setDrawingMode]
  );

  const disableDrawing = useCallback(() => {
    setDrawingMode({ mode: 'off' });
  }, [setDrawingMode]);

  const drawingModeActions = useMemo(
    () => ({
      setAnnotationDrawingMode,
      setFocusAreaDrawingMode,
      setRegionsDrawingMode,
      disableDrawing,
    }),
    [
      disableDrawing,
      setAnnotationDrawingMode,
      setFocusAreaDrawingMode,
      setRegionsDrawingMode,
    ]
  );

  if (drawingModeActions === null) {
    throw new Error(
      'useDrawingModeActions must be used in a descendant of ViewerControllerProvider'
    );
  }

  return drawingModeActions;
}
