import styled from 'styled-components';

export const $LayoutContainer = styled.main`
  flex-grow: 1;
  position: relative;
  width: 100%;
  display: grid;
  /* header + main content */
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
  /* main content may be independently scrollable */
  overflow: hidden;
`;
