import {
  $FilterControls,
  $FilterHeader,
  $FilterSearch,
  Button,
  Filter,
  FilterField,
  Input,
  Loader,
  SpreadFilterProps,
  isTouchDevice,
  useSnackbarMutations,
} from '@aignostics/components';
import { gql, useLazyQuery } from '@apollo/client';
import React, { ReactElement, useEffect } from 'react';
import { useTheme } from 'styled-components';

import {
  Association,
  Batch,
  Disease,
  Scanner,
  Staining,
  Tissue,
} from '../../../types';
import {
  FilterKeysWithAssociation,
  FilterKeysWithoutAssociation,
} from './AdminSubProjectSlides/AdminSubProjectSlides.component';

export const FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS = gql`
  query GET_ADMIN_SUBPROJECT_WSIS_FILTERS($associations: [ID]!) {
    associations {
      id
      name
    }
    batches(associations: $associations) {
      id
      name
    }
    stainings {
      id
      name
    }
    tissues {
      id
      name
    }
    scanners {
      id
      vendor
      model
    }
    diseases {
      name
    }
  }
`;

const generatePageFilters = ({
  showAssociations,
  associations,
  batches,
  scanners,
  stainings,
  tissues,
  diseases,
}: {
  showAssociations: boolean;
  associations: Association[];
  batches: Batch[];
  scanners: Scanner[];
  stainings: Staining[];
  tissues: Tissue[];
  diseases: Disease[];
}):
  | Record<FilterKeysWithoutAssociation, FilterField>
  | Record<FilterKeysWithAssociation, FilterField> => ({
  searchWsis: {
    type: 'search',
    label: 'Search',
    placeholder: 'Search slides',
    value: '',
  },
  ...(showAssociations
    ? {
        associations: {
          type: 'multiselect',
          label: 'Association',
          value: [],
          options: associations.map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        },
      }
    : {}),
  batches: {
    type: 'multiselect',
    label: 'Batch',
    value: [],
    options: batches.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  },
  caseId: {
    type: 'text',
    label: 'CaseID',
    value: '',
  },
  objectivePowers: {
    type: 'multiselect',
    label: 'Objective Power',
    value: [],
    options: [
      { label: '20', value: '20' },
      { label: '40', value: '40' },
    ],
  },
  scanners: {
    type: 'multiselect',
    label: 'Scanner',
    value: [],
    options: scanners.map(({ id, vendor, model }) => ({
      label: `${vendor} ${model ?? ''}`,
      value: id.toString(),
    })),
  },
  stainings: {
    type: 'multiselect',
    label: 'Staining',
    value: [],
    options: stainings.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  },
  tissues: {
    type: 'multiselect',
    label: 'Localization',
    value: [],
    options: tissues.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  },
  diseases: {
    type: 'multiselect',
    label: 'Disease',
    value: [],
    options: diseases.map(({ name }) => ({
      label: name,
      value: name,
    })),
  },
});

export interface AdminSubProjectWsisFilters {
  associations: Association[];
  batches: Batch[];
  stainings: Staining[];
  tissues: Tissue[];
  scanners: Scanner[];
  diseases: Disease[];
}

interface AdminSubProjectWsisFiltersArgs {
  subProjectId: string;
  associations: Array<Pick<Association, 'name'>>;
}

export const AdminSubProjectWsisFilters = ({
  subprojectId,
  filterProps,
  showAssociations,
  onChange,
}: {
  subprojectId: string;
  filterProps: SpreadFilterProps<FilterKeysWithoutAssociation>;
  showAssociations: boolean;
  onChange: (updates: Record<string, string | string[]>) => void;
}): ReactElement => {
  const theme = useTheme();

  const { addSnackbar } = useSnackbarMutations();
  const [
    fetchAdminSubProjectWsisFilters,
    { data, previousData, loading, error },
  ] = useLazyQuery<AdminSubProjectWsisFilters, AdminSubProjectWsisFiltersArgs>(
    FETCH_ADMIN_SUB_PROJECT_WSIS_FILTERS,
    {
      variables: {
        subProjectId: subprojectId,
        associations: [],
      },
      onError: (error) => {
        addSnackbar({
          message: error.message,
          type: 'error',
          closesAfter: 0,
        });
      },
    }
  );

  useEffect(() => {
    if (!filterProps.isDefault) {
      void fetchAdminSubProjectWsisFilters();
    }
  }, [filterProps.isDefault, fetchAdminSubProjectWsisFilters]);

  const associations = (data || previousData)?.associations ?? [];
  const batches = (data || previousData)?.batches ?? [];
  const scanners = (data || previousData)?.scanners ?? [];
  const stainings = (data || previousData)?.stainings ?? [];
  const tissues = (data || previousData)?.tissues ?? [];
  const diseases = (data || previousData)?.diseases ?? [];

  const PAGE_FILTER_FIELDS:
    | Record<FilterKeysWithoutAssociation, FilterField>
    | Record<FilterKeysWithAssociation, FilterField> = generatePageFilters({
    showAssociations,
    associations,
    batches,
    scanners,
    stainings,
    tissues,
    diseases,
  });

  const handleFieldChange = (fieldValue: string | string[]) => {
    onChange({ ...filterProps.value, searchWsis: fieldValue });
  };

  return (
    <>
      {!data && (
        <$FilterHeader>
          <$FilterControls>
            <Button
              small
              icon={loading ? 'ChevronUp' : 'Union'}
              onClick={() => {
                void fetchAdminSubProjectWsisFilters();
              }}
              variant="white"
              aria-expanded={loading}
            >
              Filter
            </Button>

            {!filterProps.isDefault && (
              <Button
                small
                onClick={filterProps.onReset}
                icon="XCircle"
                variant="black"
              >
                Clear filters
              </Button>
            )}
          </$FilterControls>

          <$FilterSearch>
            <Input
              style={{ width: theme.spacings.tile }}
              icon={PAGE_FILTER_FIELDS.searchWsis.icon}
              innerIcon="Search"
              id={PAGE_FILTER_FIELDS.searchWsis.type}
              type="search"
              value={String(filterProps.value['searchWsis'])}
              onChange={handleFieldChange}
              placeholder={PAGE_FILTER_FIELDS.searchWsis.placeholder}
              name="searchField"
              aria-label={PAGE_FILTER_FIELDS.searchWsis.label}
              autoFocus={!isTouchDevice()}
              data-autofocus={!isTouchDevice()}
            />
          </$FilterSearch>
        </$FilterHeader>
      )}
      {loading ? <Loader /> : null}
      {data && !loading && !error && (
        <Filter
          title="Filter"
          fields={PAGE_FILTER_FIELDS}
          {...filterProps}
          onChange={onChange}
          isOpen={true}
        />
      )}
    </>
  );
};
