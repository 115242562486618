import { createContext, Dispatch, RefObject, SetStateAction } from 'react';

export type SideNav = 'menu' | 'user' | null;

export interface LayoutContextProps {
  sideNav: SideNav;
  setSideNav: Dispatch<SetStateAction<SideNav>>;
  headerContentRef: RefObject<HTMLDivElement> | null;
}

export const layoutContextInitValue: LayoutContextProps = {
  sideNav: null,
  setSideNav: () => null,
  headerContentRef: null,
};

/**
 * Context enabling to state when a layer is loading, and whether the side navigation
 * (menu or sidebar) is opened.
 */
const LayoutContext = createContext<LayoutContextProps>(layoutContextInitValue);

export default LayoutContext;
