import { CategoryItem, Divider } from '@aignostics/components';
import React, { FunctionComponent, useEffect } from 'react';
import { Annotation, AnnotationProperties } from '../../../../api-types';

interface ItemProps {
  annotations: Annotation[];
  activeUsers: string[] | undefined;
  setActiveUsers: (users: string[] | undefined) => void;
  onUserAnnotationVisibilityChanged: (visible: boolean, userId: string) => void;
  annotationUsers: AnnotationProperties['createdBy'][];
}

const AnnotationsUserItem: FunctionComponent<ItemProps> = ({
  annotations,
  annotationUsers,
  activeUsers,
  setActiveUsers,
  onUserAnnotationVisibilityChanged,
}) => {
  // Handle newly created annotation
  useEffect(() => {
    const newAnnotation = annotations.find(
      (a) => a.properties.state === 'create'
    );
    if (!newAnnotation) {
      return;
    }
    const _activeUsers = [...(activeUsers ?? [])];

    const newAnnotationAnnotator = newAnnotation.properties.createdBy.id;

    const newAnnotator = !annotationUsers
      .map((user) => user.id)
      .includes(newAnnotationAnnotator);

    if (newAnnotator) {
      setActiveUsers([..._activeUsers, newAnnotationAnnotator]?.sort());
    }
  }, [annotations, annotationUsers, activeUsers, setActiveUsers]);

  return (
    <>
      {annotationUsers.length > 0 && <Divider color="light">Users</Divider>}
      {annotationUsers.map((user) => {
        const isVisible = activeUsers?.includes(user.id);

        return (
          <CategoryItem
            key={user.id}
            name={user.name || user.email || 'Unknown'}
            isVisible={isVisible}
            setVisible={(visible) => {
              onUserAnnotationVisibilityChanged(visible, user.id);
            }}
          />
        );
      })}
    </>
  );
};

export default AnnotationsUserItem;
