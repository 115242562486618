import { ListItemAdminDashboard, PageLayout } from '@aignostics/components';
import { OrganizationRole, VisibleWithScope } from '@aignostics/core';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import {
  $AdminDashboardBackgroundBottomPattern,
  $AdminDashboardListContainer,
  $AdminDashboardWrapper,
  $Header,
  $HeaderDescription,
  $HeaderTitle,
} from './Admin.Dashboard.styles';

const AdminDashboard: FunctionComponent<{
  role: OrganizationRole;
  adminDashboardBackground: string | undefined;
  isSlideLibraryEnabled: boolean;
}> = ({ role, adminDashboardBackground, isSlideLibraryEnabled }) => {
  const theme = useTheme();
  const background = `linear-gradient(180deg, ${theme.colors.primary} 0%, #3A2A54 100%)`;
  return (
    <PageLayout background={background}>
      <$AdminDashboardWrapper>
        <$Header>
          <$HeaderTitle>Portal Admin</$HeaderTitle>
          <$HeaderDescription>Welcome to Aignostics Portal</$HeaderDescription>
        </$Header>
        <$AdminDashboardListContainer>
          <ListItemAdminDashboard title="All Projects" to="projects" />
          <VisibleWithScope role={role} scope="user:readAll">
            <ListItemAdminDashboard title="User List" to="users" />
          </VisibleWithScope>
          {isSlideLibraryEnabled && (
            <VisibleWithScope role={role} scope="user:readAll">
              <ListItemAdminDashboard
                title="Slide Library"
                to="slide-library"
              />
            </VisibleWithScope>
          )}
          <VisibleWithScope role={role} scope="wsi:upload">
            <ListItemAdminDashboard
              title="Data Onboarding"
              to="data-onboarding"
            />
          </VisibleWithScope>
        </$AdminDashboardListContainer>
      </$AdminDashboardWrapper>
      <$AdminDashboardBackgroundBottomPattern src={adminDashboardBackground} />
    </PageLayout>
  );
};

export default AdminDashboard;
