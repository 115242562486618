import {
  SelectSingle,
  SelectSkeleton,
  TableSubRow,
  TableSubRowCell,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import { WsiThumbnail } from '../../../../../../components';
import { StainingWsi } from '../../../../../../types';
import { OnSetCoefficient } from '../AssignedSlidesTable';
import { getMenuOptions } from '../getRegistrationCoefficientsMenuOptions';
import {
  $StainingRowRightSide,
  $SubTitle,
  $Title,
  SelectStyle,
} from './styles';

export const StainingRow = ({
  stainingLayer,
  reference,
  onSetCoefficient,
  availableCoefficients,
  coefficientsLoading,
  rasterTileServerUrl,
  getToken,
}: {
  stainingLayer: StainingWsi;
  reference: string;
  subProjectId: string;
  onSetCoefficient: OnSetCoefficient;
  coefficientsLoading: boolean;
  availableCoefficients: {
    registrationId: string;
    registrationName: string;
    referenceId: string;
  }[];
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const areCoefficientsAvailable = availableCoefficients?.length > 0;

  return (
    <TableSubRow
      aria-label={`Assigned ${stainingLayer.id}`}
      key={stainingLayer.id}
    >
      <TableSubRowCell>
        <div style={{ width: '48px', height: '48px' }}>
          <WsiThumbnail
            wsiId={stainingLayer.id}
            rasterTileServerUrl={rasterTileServerUrl}
            getToken={getToken}
          />
        </div>
      </TableSubRowCell>

      <TableSubRowCell colSpan={areCoefficientsAvailable ? 6 : 7}>
        <$Title>{stainingLayer.staining}</$Title>
        <$SubTitle>{stainingLayer?.scanner?.vendor}</$SubTitle>
      </TableSubRowCell>
      <TableSubRowCell align="right" colSpan={7}>
        <$StainingRowRightSide>
          {coefficientsLoading && <SelectSkeleton />}

          {areCoefficientsAvailable && (
            <SelectSingle
              key={stainingLayer.id}
              id={stainingLayer.id}
              value={stainingLayer.registrationId ?? null}
              selectStyle={SelectStyle}
              options={getMenuOptions(
                availableCoefficients,
                stainingLayer.registrationId
              )}
              onChange={(v) => {
                if (v?.value) {
                  onSetCoefficient(
                    reference,
                    stainingLayer.id,
                    v.value,
                    stainingLayer.isActive,
                    v.label,
                    'staining'
                  );
                }
              }}
            />
          )}
        </$StainingRowRightSide>
      </TableSubRowCell>
    </TableSubRow>
  );
};
