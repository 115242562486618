import { PageLayout } from '@aignostics/components';
import { OrganizationRole, VisibleWithScope } from '@aignostics/core';
import React, { ReactElement, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelectedOrganizationUuid } from '../utils/useSelectedOrganizationUuid';
import type { OnboardingCloudFile } from './TransferCloudSlides/FETCH_ONBOARDING_CLOUD_FILES';
import type { OnboardingCloudFileWithMetadata } from './TransferCloudSlides/RequestMetadataStep.component';
import { TransferCloudSlidesSteps } from './TransferCloudSlides/TransferCloudSlidesSteps.component';

export interface RequestMetadataStep {
  name: 'requestMetadata';
  files: OnboardingCloudFile[];
  title: 'Requesting Metadata';
}

export interface SetFileMetadataStep {
  files: OnboardingCloudFileWithMetadata[];
  name: 'setFileMetadata';
  title: 'Slides Metadata';
}

export interface TransferSlidesStep {
  name: 'transferFiles';
  title: 'Transfer Slides';
  batchId: string;
}

export type TransferCloudSlideStep =
  | RequestMetadataStep
  | SetFileMetadataStep
  | TransferSlidesStep;

type AdminTransferCloudSlidesProps = {
  role: OrganizationRole;
  authToken: string | null;
  apiUrl: string;
};
/**
 * Page for admins to select Slide ( WSI ) files from a cloud bucket and start the slide onboarding process.
 */
export default function AdminTransferCloudSlides({
  role,
  authToken,
  apiUrl,
}: AdminTransferCloudSlidesProps): ReactElement {
  const location = useLocation();
  const organizationUuid = useSelectedOrganizationUuid();

  const files = location.state?.files;
  const [step, onStepChange] = useState<TransferCloudSlideStep>({
    name: 'requestMetadata',
    files,
    title: 'Requesting Metadata',
  });

  if (!files) {
    return (
      <Navigate
        to={`/${organizationUuid}/admin/data-onboarding`}
        state={{ shouldOpenDataUploadModal: true }}
      />
    );
  }

  return (
    <PageLayout
      title={step.title}
      description="Please provide information about the slides (you can use the table header to add global values). You may also include a CSV file containing prefilled fields."
    >
      <VisibleWithScope role={role} scope="wsi:upload">
        <TransferCloudSlidesSteps
          apiUrl={apiUrl}
          step={step}
          onStepChange={onStepChange}
          authToken={authToken}
        />
      </VisibleWithScope>
    </PageLayout>
  );
}
