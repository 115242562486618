import {
  Filter,
  FilterConfigs,
  FilterField,
  HStack,
  Pagination,
  PaginationInfo,
  Section,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  VStack,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useFilters,
  usePagination,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole, OrganizationUser } from '@aignostics/core';
import { INPUT_DEBOUNCE_MS, useDebounce } from '@aignostics/hooks';
import { pluralize } from '@aignostics/utils';
import {
  ApolloError,
  FetchResult,
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import * as Sentry from '@sentry/react';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AssignmentButton, InviteButton } from '../../../components';
import { useSetQueryParams } from '../../../hooks/useSetQueryParams';
import { PaginationType, Project } from '../../../types';
import { FETCH_PROJECTS } from '../../Home';
import { ADD_USER_TO_PROJECT } from './ADD_USER_TO_PROJECT';
import { GET_ADMIN_PROJECT_DETAILS as FETCH_ADMIN_PROJECT_DETAILS } from './FETCH_ADMIN_PROJECT_DETAILS';
import { FETCH_ADMIN_PROJECT_USERS } from './FETCH_ADMIN_PROJECT_USERS';
import { INVITE_USER_TO_PROJECT } from './INVITE_USER_TO_PROJECT';
import { REMOVE_USER_FROM_PROJECT } from './REMOVE_USER_FROM_PROJECT';

interface AdminProjectUsersProps {
  project?: Pick<Project, 'id' | 'name' | 'description' | 'isVisible'>;
  userRole: OrganizationRole;
  organizationUuid: string;
}
type FilterKeys = 'search' | 'filterAssigned';

const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  search: { fallbackValue: '', type: 'string' },
  filterAssigned: { fallbackValue: 'all', type: 'string' },
};
export const ADMIN_PROJECT_USER_PAGE_SIZE = 20;

const UPDATE_USER_IN_PROJECT = gql`
  fragment UpdateUserInProject on User {
    inParent(projectId: $projectId)
  }
`;

const PROJECT_ASSIGNED_VALUES = ['all', 'in', 'out'];
export const PAGE_SIZE = 12;

const AdminProjectUsers = ({
  organizationUuid,
  userRole,
  project,
}: AdminProjectUsersProps): ReactElement => {
  const { projectId } = useParams();
  const [page, setPage] = usePagination(getPageFromQueryParams());

  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );
  const { addSnackbar } = useSnackbarMutations();

  const debouncedFilterSearch = useDebounce(filters.search, INPUT_DEBOUNCE_MS);

  const fetchAdminProjectUsersVariables = useMemo(
    () => ({
      search: debouncedFilterSearch,
      page,
      pageSize: ADMIN_PROJECT_USER_PAGE_SIZE,
      projectId,
      projectAssigned: filters.filterAssigned,
    }),
    [debouncedFilterSearch, page, projectId, filters.filterAssigned]
  );

  const allPossibleFetchAdminProjectUsersQueries = PROJECT_ASSIGNED_VALUES.map(
    (projectAssigned) => ({
      query: FETCH_ADMIN_PROJECT_USERS,
      variables: {
        ...fetchAdminProjectUsersVariables,
        projectAssigned,
      },
    })
  );

  /** Fetch list of all users */
  const {
    data,
    loading: fetchUsersLoading,
    error: fetchUsersError,
  } = useQuery<{
    users: PaginationType<OrganizationUser, { availableUsers: number }>;
  }>(FETCH_ADMIN_PROJECT_USERS, {
    variables: fetchAdminProjectUsersVariables,
  });

  /** Add user to given project */
  const [addUserToProject, { loading: addUserLoading, error: addUserError }] =
    useMutation(ADD_USER_TO_PROJECT);

  /** Remove user from given project */
  const [
    removeUserFromProject,
    { loading: removeUserLoading, error: removeUserError },
  ] = useMutation(REMOVE_USER_FROM_PROJECT);

  /** Send invitation to a user for given project */
  const [inviteUserToProject, { loading: inviteUserLoading }] = useMutation(
    INVITE_USER_TO_PROJECT
  );

  /** Component error state requires setter for catching mutation errors. */
  const [error, setError] = useState(
    fetchUsersError || addUserError || removeUserError
  );

  /** Users List */
  const users = useMemo(() => data?.users?.nodes || [], [data]);
  const pageInfo = useMemo(
    () => data?.users?.pageInfo ?? { totalPages: 0 },
    [data]
  );
  const availableUsers = useMemo(
    () => data?.users?.collectionAttributes?.availableUsers ?? 0,
    [data]
  );

  /** Request loading state */
  const loading =
    fetchUsersLoading ||
    addUserLoading ||
    removeUserLoading ||
    inviteUserLoading;

  const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
    search: {
      icon: 'Search',
      type: 'search',
      label: 'Search',
      value: '',
      placeholder: 'Search users',
    },
    filterAssigned: {
      icon: 'UserCheck',
      type: 'radio',
      label: 'Assigned',
      value: 'all',
      options: [
        { value: 'all', label: 'All' },
        { value: 'in', label: 'Assigned' },
        { value: 'out', label: 'Unassigned' },
      ],
    },
  };

  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...filters,
    }),
    [page, filters]
  );

  const TABLE_HEADERS = ['Name', 'Email', 'Action'];

  useSetQueryParams(queryParams);

  /** Error handler to wrap mutation promises. */
  const catchError = async (request: Promise<FetchResult>): Promise<void> => {
    try {
      await request;
    } catch (error) {
      if (!(error instanceof ApolloError)) return;
      Sentry.captureException(error);
      setError(error);
    }
  };

  const addUser = useCallback(
    (userId: string) => {
      void catchError(
        addUserToProject({
          variables: { userId, projectId },
          update: (cache) => {
            /** Update cached user entity */
            cache.writeFragment({
              id: `User:${userId}`,
              fragment: UPDATE_USER_IN_PROJECT,
              variables: { projectId },
              data: { inParent: true },
            });
            cache.modify({
              id: cache.identify({ __ref: `Project:${projectId}` }),

              fields: {
                usersCount(oldCount) {
                  return oldCount + 1;
                },
              },
            });
          },
          refetchQueries: [
            {
              query: FETCH_ADMIN_PROJECT_USERS,
              variables: {
                projectId,
                isAdminView: true,
              },
            },

            {
              query: FETCH_PROJECTS,
              variables: {
                isAdminView: false,
                page: 1,
                pageSize: PAGE_SIZE,
                search: '',
                createdBy: [],
              },
            },
            {
              query: FETCH_PROJECTS,
              variables: {
                isAdminView: false,
              },
            },
            ...allPossibleFetchAdminProjectUsersQueries,
          ],
        })
      );
    },
    [addUserToProject, projectId, allPossibleFetchAdminProjectUsersQueries]
  );

  const removeUser = useCallback(
    (userId: string) => {
      void catchError(
        removeUserFromProject({
          variables: { userId, projectId },
          update: (cache) => {
            /** Update cached user entity */
            cache.writeFragment({
              id: `User:${userId}`,
              fragment: UPDATE_USER_IN_PROJECT,
              variables: { projectId },
              data: { inParent: false },
            });
            cache.modify({
              id: cache.identify({ __ref: `Project:${projectId}` }),

              fields: {
                usersCount(oldCount) {
                  return Math.max(oldCount - 1, 0);
                },
              },
            });
          },
          refetchQueries: [
            {
              query: FETCH_ADMIN_PROJECT_DETAILS,
              variables: {
                projectId,
              },
            },
            {
              query: FETCH_PROJECTS,
              variables: {
                isAdminView: false,
                page: 1,
                pageSize: PAGE_SIZE,
                search: '',
                createdBy: [],
              },
            },
            {
              query: FETCH_PROJECTS,
              variables: {
                isAdminView: false,
              },
            },
            ...allPossibleFetchAdminProjectUsersQueries,
          ],
        })
      );
    },
    [projectId, removeUserFromProject, allPossibleFetchAdminProjectUsersQueries]
  );

  const sendInvitation = async (userId: string) => {
    try {
      await inviteUserToProject({
        variables: {
          userId,
          projectId,
        },
      });
      addSnackbar({
        type: 'success',
        message: `Invitation sent`,
      });
    } catch (error) {
      Sentry.captureException(error);
      addSnackbar({
        type: 'error',
        message: 'Error sending invitation',
      });
    }
  };

  const theme = useTheme();

  return (
    <Section
      loading={loading}
      error={error}
      title={
        <PaginationInfo
          totalCount={availableUsers}
          currentPage={page}
          itemsPerPage={ADMIN_PROJECT_USER_PAGE_SIZE}
          itemType={pluralize('User', availableUsers)}
        />
      }
    >
      <VStack spacing="line" alignItems={'center'}>
        <Filter
          fields={PAGE_FILTER_FIELDS}
          {...filterProps}
          onChange={(filters) => {
            filterProps.onChange(filters);
            setPage(1);
          }}
        />

        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEADERS.map((headerElement, index) => {
                return <TableHeader key={index}>{headerElement}</TableHeader>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              return (
                <TableRow key={user.id}>
                  <TableCell tooltipText={user.name || 'unknown'}>
                    <Link
                      to={`/${organizationUuid}/admin/users/${user.id}`}
                      style={{
                        color: user.name
                          ? theme.colors.primaryLight
                          : theme.colors.linkBlue,
                      }}
                    >
                      {user.name || 'unknown'}
                    </Link>
                  </TableCell>
                  <TableCell tooltipText={user.email || 'unknown'}>
                    {user.email || 'unknown'}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: theme.spacings[16],
                    }}
                  >
                    <HStack spacing="input">
                      <AssignmentButton<OrganizationUser>
                        entity={user}
                        add={addUser}
                        remove={removeUser}
                        disabled={!userRole.scopes['project:manage']}
                      />
                      {project?.isVisible && user.inParent && (
                        <InviteButton
                          onSend={sendInvitation}
                          userId={user.id}
                          disabled={!userRole.scopes['project:manage']}
                        />
                      )}
                    </HStack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {pageInfo && pageInfo?.totalPages > 1 && (
          <Pagination
            currentPage={page}
            onPageChanged={setPage}
            totalPages={pageInfo?.totalPages}
          />
        )}
      </VStack>
    </Section>
  );
};

export default AdminProjectUsers;
