import { useMemo } from 'react';
import { Annotation, AnnotationFeatureType } from '../../../api-types';
import { ViewerLayersParams } from '../ViewerLayerState/viewersLayersParams/ViewerLayersParamsType';

const ACTIVE_USERS_FALLBACK: string[] = [];
const ACTIVE_CATEGORIES_FALLBACK: string[] = [];

const getFeatureVisible = (
  feature: Annotation,
  users: string[],
  annotationCategories: string[]
): boolean => {
  const conditions = [
    feature.properties.state !== 'delete',
    annotationCategories.includes(feature.properties.category.id),
    users.includes(feature.properties.createdBy.id),
  ];

  return !conditions.includes(false);
};

export const useVisibleAnnotations = (
  annotations: Annotation[],
  viewerParams: ViewerLayersParams,
  annotationFeature?: AnnotationFeatureType
): Annotation[] => {
  const activeUsers = viewerParams.activeUsers ?? ACTIVE_USERS_FALLBACK;
  const activeCategories =
    viewerParams.activeCategories ?? ACTIVE_CATEGORIES_FALLBACK;

  // Filter out invisible features and update local state accordingly.
  const visibleAnnotations = useMemo(() => {
    if (annotationFeature === 'OFF') return [];
    return annotations.filter((annotation) =>
      getFeatureVisible(annotation, activeUsers, activeCategories)
    );
  }, [annotations, activeUsers, activeCategories, annotationFeature]);

  return visibleAnnotations;
};
