import { AnimatePresence } from 'framer-motion';
import React, {
  ReactElement,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { UserSidebar } from '../../UserSidebar';
import { MenuSidebar } from '../../__Navigation';
import AppHeader from '../../__Navigation/AppHeader';
import LayoutContext, { SideNav } from './Layout.Context';
import { $LayoutContainer } from './Layout.styles';

export function Layout({ children }: { children: ReactNode }): ReactElement {
  const [sideNav, setSideNav] = useState<SideNav | null>(null);
  const closeSideNav = () => {
    setSideNav(null);
  };
  const headerContentRef = useRef<HTMLDivElement>(null);

  return (
    <LayoutContext.Provider
      value={{
        sideNav,
        setSideNav,
        headerContentRef,
      }}
    >
      {/* Main Page Layout Container */}
      <$LayoutContainer>
        <AppHeader sideNav={sideNav} setSideNav={setSideNav}>
          <div
            style={{ display: 'flex', flexGrow: 1 }}
            ref={headerContentRef}
          />
        </AppHeader>
        {children}
      </$LayoutContainer>

      {/* Sidebars */}
      <AnimatePresence>
        {sideNav === 'user' && <UserSidebar closeSideNav={closeSideNav} />}
        {sideNav === 'menu' && <MenuSidebar closeSidenav={closeSideNav} />}
      </AnimatePresence>
    </LayoutContext.Provider>
  );
}

export function HeaderContent({
  children,
}: {
  children: ReactNode;
}): ReactElement | null {
  const { headerContentRef } = useContext(LayoutContext);

  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (headerContentRef === null || headerContentRef.current === null) {
    return null;
  }
  return createPortal(children, headerContentRef.current);
}
