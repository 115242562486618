import { Button } from '@aignostics/components';
import { Theme } from '@aignostics/theme';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LoginState } from './Login.component';

export const $LoginMainWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.light};

  @media (max-width: ${({ theme }) => theme.breakpoints.IPAD_PORTRAIT_MIN}px) {
    height: auto;
    justify-content: flex-start;
    padding-top: ${({ theme }) => theme.spacings[96]}px;
  }
`;

export const $UnsupportedScreenBanner = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.IPAD_PORTRAIT_MIN}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    padding: ${({ theme }) => theme.spacings[8]}px
      ${({ theme }) => theme.spacings[16]}px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`;

export const $BannerContent = styled.div`
  display: flex;
  align-items: center;
  text-align: left;

  span {
    padding: ${({ theme }) => theme.spacings[8]}px;
  }
`;

export const $HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacings[56]}px
    ${({ theme }) => theme.spacings[72]}px 0px
    ${({ theme }) => theme.spacings[72]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.IPAD_PORTRAIT_MIN}px) {
    position: relative;
    padding: ${({ theme }) => theme.spacings[16]}px;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacings[96]}px;
  }

  @media (max-height: 734px) {
    gap: ${({ theme }) => theme.spacings[12]}px;
  }
`;

export const $LoginWrapper = styled.div`
  overflow: hidden;
  max-width: 375px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.IPAD_PORTRAIT_MIN}px) {
    max-width: 280px;
  }
`;

export const $LoginHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.spacings.touch * 2}px;
`;

export const $SentEmailTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${({ theme }) => theme.spacings[24]}px;
`;

export const $SentEmailDescription = styled.h1`
  ${({ theme }) => theme.fontStyles.base};
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${({ theme }) => theme.spacings[16]}px;
`;

export const $LoginMain = styled.main`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const stateColor = (theme: Theme) => ({
  pending: theme.colors.dark,
  error: theme.colors.error,
  success: theme.colors.success,
});

export const $LoginError = styled(motion.div)<{ state: LoginState }>`
  background-color: ${({ state, theme }) => stateColor(theme)[state]};
  color: ${({ theme }) => theme.colors.white};
`;

export const $LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacings[32]}px;
`;

export const $LoginMainHeader = styled.p`
  ${({ theme }) => theme.fontStyles.displayBold};
  margin-bottom: 14px;
  margin-top: ${({ theme }) => theme.spacings[32]}px;
`;

export const $LoginMainSubHeader = styled.p`
  ${({ theme }) => theme.fontStyles.base};
  margin-bottom: 14px;
`;

export const $LoginFooterLink = styled(Link)`
  ${({ theme }) => theme.fontStyles.baseBold};
  text-decoration: none;
  z-index: 2;
  display: flex;
`;

export const $ChangePasswordWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacings[48]}px 0;

  > a {
    width: 100%;
  }
`;
export const $SignInButtonProvider = styled(Button)`
  margin-bottom: ${({ theme }) => `${theme.spacings[12]}px`};
  margin-right: ${({ theme }) => `${theme.spacings.line}px`};
  margin-left: ${({ theme }) => `${theme.spacings.line}px`};
  ${({ theme }) => theme.fontStyles.base};
`;

export const $FormContainer = styled.span`
  padding: 0 ${({ theme }) => theme.spacings[48]}px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[8]}px;
`;

export const $FormContainerWithBackground = styled($FormContainer)`
  background-color: ${({ theme }) => theme.colors.lighter};
  margin-top: ${({ theme }) => theme.spacings[32]}px;
  padding: ${({ theme }) => theme.spacings[32]}px
    ${({ theme }) => theme.spacings[48]}px;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
`;
export const $Separator = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.light};
  width: 100%;
  margin: ${({ theme }) => theme.spacings[32]}px 0;
`;

export const $SignInSeparator = styled.span`
  overflow: hidden;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.fontStyles.small};
  height: 20px;
  margin-bottom: ${({ theme }) => `${theme.spacings[24]}px`};
  :before,
  :after {
    background-color: ${({ theme }) => theme.colors.black};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  :before {
    right: 6px;
    margin-left: -50%;
  }
  :after {
    left: 6px;
    margin-right: -50%;
  }
`;

export const $ExpandedWrapper = styled(motion.div)<{ isExpanded: boolean }>`
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
`;

export const $SignInContentWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacings[48]}px ${theme.spacings[48]}px 0`};
  background-color: ${({ theme }) => theme.colors.lighter};
`;

export const $ResetPasswordButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.mid};
  height: ${({ theme }) => `${theme.spacings.button}px`};
  margin: 0;
`;

export const $ChangePasswordButton = styled(Button)`
  width: 100%;
`;

export const $BackgroundWrapper = styled.div<{ backgroundImageSrc?: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(${({ backgroundImageSrc }) => backgroundImageSrc});
  background-size: cover;
  background-position: center;
`;

export const $Logo = styled.h1`
  color: ${({ theme }) => theme.colors.accentPeach};
`;

export const $RUOContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[8]}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.IPAD_PORTRAIT_MIN}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const $RUOText = styled.small`
  color: white;
  display: block;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.IPAD_PORTRAIT_MIN}px) {
    white-space: normal;
    text-align: center;
  }
`;

export const $VersionContainer = styled.p`
  ${({ theme }) => theme.fontStyles.baseBold};
  z-index: 2;
  margin-top: ${({ theme }) => theme.spacings[24]}px;
  color: ${({ theme }) => theme.colors.white};
`;

export const $LegalData = styled.div`
  ${({ theme }) => theme.fontStyles.baseBold};
  position: fixed;
  bottom: ${({ theme }) => theme.spacings[48]}px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.IPAD_PORTRAIT_MIN}px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    white-space: normal;
    padding-right: ${({ theme }) => theme.spacings[56]}px;
    padding-left: ${({ theme }) => theme.spacings[56]}px;
  }

  @media (max-width: 375px) {
    flex-direction: row;
    align-items: center;
    text-align: left;

    span {
      padding-left: ${({ theme }) => theme.spacings[16]}px;
    }
  }
`;
