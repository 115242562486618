import {
  Button,
  CategoryItem,
  Input,
  Link,
  UserAvatar,
  VStack,
} from '@aignostics/components';
import { VisibleWithScope } from '@aignostics/core';
import { useClickOutside } from '@aignostics/hooks';
import { formatDate } from '@aignostics/utils';
import React, { FunctionComponent, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useAsync } from '../../hooks/useAsync';
import useUserRole from '../../hooks/useUserRole';
import { buildTimestamp, name } from '../../metadata';
import {
  useAuthController,
  useAuthUser,
  useGetAuthToken,
} from '../App/AuthProvider';
import { useAppConfig } from '../App/ConfigProvider';
import { useImpersonation } from '../App/ImpersonationProvider';
import { appConfig } from '../App/appConfig';
import { useSelectedOrganizationUuid } from '../__Navigation/Routes/useSelectedOrganizationUuid';
import Impersonation from './Impersonation.component';
import OrganizationSelector from './OrganizationSelector/OrganizationSelector.component';
import {
  $UserSidebar,
  $UserSidebarFooter,
  $UserSidebarHeader,
  $UserSidebarMain,
  $UserSubMenuLink,
} from './UserSidebar.styles';

/**
 * UserSidebar component.
 */
const UserSidebar: FunctionComponent<{ closeSideNav: () => void }> = ({
  closeSideNav,
}) => {
  const theme = useTheme();
  const config = useAppConfig();
  const currentUser = useAuthUser();
  const { impersonatedUserEmail } = useImpersonation();
  const currentUserRole = useUserRole();
  const ref = useRef<HTMLElement>(null);
  const getToken = useGetAuthToken();
  const organizationUuid = useSelectedOrganizationUuid();

  // This is fine because we need the token to render, cannot await
  const token = useAsync(getToken);

  const { signOut } = useAuthController();

  /** Close currentUser side nav on click outside */
  useClickOutside(ref, closeSideNav);

  const isImpersonating = impersonatedUserEmail !== null;

  const organizations = currentUser.roles
    .filter(({ isDisabled }) => !isDisabled)
    .map(({ organization }) => organization);

  return (
    <$UserSidebar
      ref={ref}
      initial={{ opacity: 0, x: '100%' }}
      animate={{ opacity: 1, x: '0%' }}
      exit={{ opacity: 0, x: '100%' }}
    >
      <$UserSidebarHeader role="section" aria-label="User info">
        <UserAvatar
          user={currentUser}
          isImpersonating={isImpersonating}
          size={100}
        />
        <p style={{ fontWeight: 600 }}>{currentUser.name}</p>
        <p>{currentUser.email}</p>
      </$UserSidebarHeader>
      <$UserSidebarMain>
        {organizations.length > 1 && (
          <OrganizationSelector
            organizations={organizations}
            initialActiveOrganizationUuid={organizationUuid}
          />
        )}
        <VisibleWithScope role={currentUserRole} scope="server:debug">
          <CategoryItem name="Developer">
            <VStack
              spacing="base"
              style={{ padding: `${theme.spacings.base}px` }}
              alignItems="stretch"
            >
              <Input
                id="devSettings-BUILD_ENV"
                label="BUILD_ENV"
                value={config.buildEnv}
                disabled
              />
              <Input
                id="devSettings-NODE_ENV"
                label="NODE_ENV"
                value={config.nodeEnv}
                disabled
              />
              <Input
                id="devSettings-BUILD_TIME"
                label="BUILD_TIME"
                value={buildTimestamp}
                disabled
              />
              <Input
                id="devSettings-VERSION"
                label="VERSION"
                value={appConfig.version}
                disabled
              />
              <Input id="devSettings-NAME" label="NAME" value={name} disabled />
              <Input
                id="devSettings-ORIGIN"
                label="ORIGIN"
                value={config.origin}
                disabled
              />
              <Input
                id="devSettings-TILE_SERVER"
                label="TILE_SERVER"
                value={config.portalServices.rasterTileServerUrl}
                disabled
              />
              <Input
                id="devSettings-API"
                label="API"
                value={config.portalServices.apiUrl}
                disabled
              />
              <Input
                id="devSettings-TOKEN"
                label="TOKEN"
                value={token ?? 'getting token...'}
                disabled
                copy
              />
            </VStack>
          </CategoryItem>
        </VisibleWithScope>
        {isImpersonating || currentUserRole.scopes['user:impersonate'] ? (
          <CategoryItem name="Impersonation">
            <VStack
              spacing="base"
              style={{ padding: `${theme.spacings.base}px` }}
              alignItems="stretch"
            >
              <Impersonation />
            </VStack>
          </CategoryItem>
        ) : null}
        {config.isAnnotationSettingsEnabled &&
        currentUserRole.scopes['admin:access'] ? (
          <CategoryItem name="Settings">
            <VStack
              spacing="base"
              style={{ padding: `${theme.spacings.base}px` }}
              alignItems="stretch"
            >
              <$UserSubMenuLink
                href={`/${organizationUuid}/annotation-management`}
              >
                Annotation Management
              </$UserSubMenuLink>
            </VStack>
          </CategoryItem>
        ) : null}
      </$UserSidebarMain>

      <$UserSidebarFooter>
        <VStack spacing="base" alignItems="center">
          <p>
            Built by{' '}
            <Link
              href="https://www.aignostics.com/impressum/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aignostics
            </Link>
          </p>
          <p>
            {appConfig.version} - {formatDate(buildTimestamp)}
          </p>
          <p>eIFU is provided directly to clients by Aignostics</p>

          <Button
            id="user-sign-out-button"
            onClick={signOut}
            banner
            variant="primaryOutline"
            small
          >
            Log out
          </Button>
        </VStack>
      </$UserSidebarFooter>
    </$UserSidebar>
  );
};

export default UserSidebar;
