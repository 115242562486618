import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { BrushTool } from './DrawingMode';

type BrushSettings = Pick<BrushTool, 'size'>;

interface DrawingToolSettingsStore {
  annotation: {
    brush: BrushSettings;
  };
  latestRegionSize: number;
  setBrushSize: (brushSize: number) => void;
  setLatestRegionSize: (size: number) => void;
}

export const useDrawingToolSettingsStore = create(
  persist<DrawingToolSettingsStore>(
    (set) => ({
      annotation: {
        brush: {
          size: 10,
        },
      },
      latestRegionSize: 100,
      setBrushSize: (brushSize) => {
        set({ annotation: { brush: { size: brushSize } } });
      },
      setLatestRegionSize: (size) => {
        set({ latestRegionSize: size });
      },
    }),
    {
      name: 'drawing-tool',
    }
  )
);
