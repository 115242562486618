import { Icon, IconButton } from '@aignostics/components';
import Tooltip from '@aignostics/components/lib/Tooltip';
import React, { ReactElement, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSetSelectedFeature } from '../../__Viewer/ViewerController';
import { checkIsDefaultParams } from '../Client/getSubprojectParamsForViewer';
import { buildSubsequentWsiUrl } from './buildSubsequentWsiUrl';

const $FilterCountContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) =>
    `0 ${theme.spacings[8]}px 0 ${theme.spacings[24]}px`};
`;

const $FilterCount = styled.div`
  width: auto;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

/**
 * Chevron Buttons to navigate to either the next or previous slide within given
 * context.
 *
 * The context may vary depending on weather the parent slide component
 * gets rendered inside admin or client ui. Whilst the context inside client ui
 * is a Subproject, the context in admin ui is a batch.
 *
 */
export const SlidesNavigation = ({
  subsequentWsiIds,
}: {
  subsequentWsiIds: {
    nextWsiId: string | null;
    previousWsiId: string | null;
    currentSlideIndex: number;
    totalCount: number;
  };
}): ReactElement | null => {
  const navigate = useNavigate();
  const location = useLocation();
  const setSelectedFeature = useSetSelectedFeature();

  const navigateToSubsequentWsi = useCallback(
    (subsequentWsiId: string) => {
      setSelectedFeature(null);
      const subsequentWsiUrl = buildSubsequentWsiUrl(location, subsequentWsiId);
      navigate(subsequentWsiUrl);
    },
    [location, navigate, setSelectedFeature]
  );

  const isDefaultFilters = checkIsDefaultParams();

  if (!subsequentWsiIds) return <></>;

  const { previousWsiId, nextWsiId, currentSlideIndex, totalCount } =
    subsequentWsiIds;

  return (
    <>
      <$FilterCountContainer>
        {!isDefaultFilters && (
          <Tooltip text={'Applied filters'}>
            {(tooltipProps) => (
              <Icon
                icon="Filter"
                style={{ marginRight: '8px' }}
                {...tooltipProps}
              />
            )}
          </Tooltip>
        )}
        <Tooltip text={`${currentSlideIndex} / ${totalCount}`}>
          {(tooltipProps) => (
            <$FilterCount
              {...tooltipProps}
            >{`${currentSlideIndex} / ${totalCount}`}</$FilterCount>
          )}
        </Tooltip>
      </$FilterCountContainer>
      <IconButton
        aria-label="Previous Slide"
        icon={'ChevronUp'}
        onClick={() => {
          if (previousWsiId) {
            navigateToSubsequentWsi(previousWsiId);
          }
        }}
        disabled={!previousWsiId}
        description="Previous Slide"
      />
      <IconButton
        aria-label="Next Slide"
        icon={'ChevronDown'}
        onClick={() => {
          if (nextWsiId) {
            navigateToSubsequentWsi(nextWsiId);
          }
        }}
        disabled={!nextWsiId}
        description="Next Slide"
      />
    </>
  );
};
