import { Annotation, FocusArea } from '../../../../api-types';

export const getPrevIndex = (
  features: (Annotation | FocusArea)[],
  index: number | null
): number => {
  if (index === 0 || index === null) {
    return features.length - 1;
  }

  return index - 1;
};

export const getNextIndex = (
  features: (Annotation | FocusArea)[],
  index: number | null
): number => {
  if (index === null || index === features.length - 1) {
    return 0;
  }
  if (index > features.length) {
    return 0;
  }

  return index + 1;
};
