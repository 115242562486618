import React, { useEffect, useRef, useState, } from 'react';
/**
 * A component for lazily rendering a HTML img element, with a placeholder
 * rendered while it's loading
 */
export function LazyImage({ renderPlaceholder, ...imageProps }) {
    const [loadingState, setLoadingState] = useState('idle');
    const imageRef = useRef(null);
    useEffect(() => {
        if (imageRef.current === null)
            return;
        setLoadingState('loading');
        const imageElement = imageRef.current;
        // Image may be fully loaded when this runs, onload will not be called in
        // that case
        if (imageElement.complete)
            setLoadingState('success');
        imageElement.onload = () => {
            setLoadingState('success');
        };
        imageElement.onerror = () => {
            setLoadingState('error');
        };
        return () => {
            imageElement.onload = null;
            imageElement.onerror = null;
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        loadingState !== 'success'
            ? renderPlaceholder({ state: loadingState })
            : null,
        React.createElement("img", { ...imageProps, ref: imageRef, loading: "lazy", style: {
                ...imageProps.style,
                ...(loadingState !== 'success'
                    ? { opacity: 0, height: 0, width: 0 }
                    : {}),
            } })));
}
