import aignxTheme from './aignx';
const theme = {
    ...aignxTheme,
    colors: {
        ...aignxTheme.colors,
        primary: '#333333',
        primaryLight: '#5C5C5C',
        pageHeader: '#FFFFFF',
    },
    glows: {
        ...aignxTheme.glows,
        focus: '0 0 0 1px #FFFFFF, 0 0 0 3px #333333',
        focusInner: 'inset 0 0 0 1px #FFFFFF, inset 0 0 0 3px #FFFFFF',
    },
};
export default theme;
