import isEqual from 'lodash/isEqual';
import { Slide } from '../../types';
import { downloadCSV } from '../../utils';
import { FilterKeys } from './types';

export const areFiltersApplied = (
  filters: Record<FilterKeys, string | string[]>,
  search: string
): boolean =>
  !isEqual(
    {
      associations: [],
      batches: [],
      diseases: [],
      objectivePowers: [],
      project: '',
      samplePreparations: [],
      sampleType: [],
      scanners: [],
      stainings: [],
      subProject: '',
      tissues: [],
      search: '',
    },
    { ...filters, search }
  );

export const downloadSlidesCsv = (slides: Slide[]): void => {
  const headers: string[] = [
    'id',
    'name',
    'Association',
    'Batch',
    'Staining',
    'Tissue',
    'Scanner',
  ];

  const rows: Array<string[]> = slides.map((wsi) => [
    wsi.id,
    wsi.name,
    wsi.association.name,
    wsi.batchName,
    wsi.staining ?? '',
    wsi.tissue ?? '',
    wsi.scanner.vendor,
  ]);

  downloadCSV([headers, ...rows]);
};
