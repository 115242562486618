import { SlideLibrary } from '@aignostics/management-ui';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserRole from '../../../../hooks/useUserRole';
import { useAuthUser, useGetAuthToken } from '../../../App/AuthProvider';
import { useAppConfig } from '../../../App/ConfigProvider';

export const SlideLibraryWrapper: FunctionComponent<{
  isCreateRoute?: boolean;
}> = () => {
  const { isSlideLibraryEnabled } = useAppConfig();
  const navigate = useNavigate();
  const role = useUserRole();
  const currentUser = useAuthUser();

  const {
    portalServices: { rasterTileServerUrl },
  } = useAppConfig();
  const getToken = useGetAuthToken();

  if (!isSlideLibraryEnabled) navigate('/');

  return (
    <SlideLibrary
      getToken={getToken}
      rasterTileServerUrl={rasterTileServerUrl}
      role={role}
      currentUser={currentUser}
    />
  );
};
