import { ApolloClient } from '@apollo/client';
import { Annotation } from '../../api-types';
import { FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI } from '../queries';

export * from './annotation';

/** Adds newly created annotation to cache and increases the annotation count */
export const addAnnotationToCache = (
  client: ApolloClient<object>,
  wsiId: string,
  subProjectId: string,
  annotation: Annotation
): void => {
  /** Update cache with new annotation */
  client.cache.modify({
    id: `WSI:${wsiId}`,
    fields: {
      annotations(annotationsRefs) {
        // Manually reference new annotation
        const newAnnotationRef = { __ref: `Annotation:${annotation.id}` };

        // Put annotation in cache
        client.cache.writeFragment({
          id: `WSI:${wsiId}`,
          fragment: FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI,
          data: { annotations: [annotation] },
          variables: { subProjectId },
        });
        // Return merged annotations refs
        return [...annotationsRefs, newAnnotationRef];
      },
      annotationsCount(count) {
        return count + 1;
      },
    },
  });
};

/** Removes a locally cached annotation. */
export const deleteAnnotationFromCache = (
  client: ApolloClient<object>,
  wsiId: string,
  annotationId: string
): void => {
  client.cache.modify({
    id: `WSI:${wsiId}`,
    fields: {
      annotations(annotationsRefs, { readField }) {
        return annotationsRefs.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (annotationRef: any) =>
            annotationId !== readField('id', annotationRef)
        );
      },
      // Cache annotationsCount decrease
      annotationsCount(count) {
        return count - 1;
      },
    },
  });
};
