import { ApolloClient } from '@apollo/client';
import {
  setAnnotationCategory,
  setAnnotationState,
} from '../../../../graphql/helpers';

export const updateAnnotationCategory = (
  client: ApolloClient<object>,
  state: string | null | undefined,
  featureId: string,
  categoryId: string
): void => {
  // Only update annotation state if it already exists in the
  // database. Locally created annotations should keep the state.
  if (state !== 'create') {
    setAnnotationState(client, featureId, 'update');
  }
  setAnnotationCategory(client, featureId, categoryId);
};
