import { useSnackbarMutations } from '@aignostics/components';
import { useMutation } from '@apollo/client';
import { Polygon } from 'geojson';
import { Map as OLMap } from 'ol';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { SET_FOCUS_AREA } from '../../../../../graphql/mutations';
import { FRAGMENT_FOCUS_AREA_ON_WSI } from '../../../../../graphql/queries';
import { SlideRouteParams } from '../../../Slide';
import StampLayer from './Stamp/Stamp.layer';

interface FocusAreaDrawingLayerProps {
  map: OLMap;
  height: number;
  maxZoom: number;
  size: number;
  disableDrawing: () => void;
}

/**
 * Returns the correct drawing tool layer for the given drawing mode
 */
const FocusAreaDrawingLayer = ({
  height,
  maxZoom,
  map,
  size,
  disableDrawing,
}: FocusAreaDrawingLayerProps): ReactElement | null => {
  const { subProjectId, wsiId } = useParams<
    keyof SlideRouteParams
  >() as SlideRouteParams;
  const { addSnackbar } = useSnackbarMutations();

  const [setFocusArea] = useMutation(SET_FOCUS_AREA, {
    onCompleted: () => {
      addSnackbar({
        message: 'The region was created successfully',
        type: 'success',
      });
      disableDrawing();
    },
  });

  const addFocusArea = (polygon: Polygon) => {
    const focusArea = {
      type: 'Feature',
      id: new Date().toString(),
      geometry: polygon,
    };

    void setFocusArea({
      variables: { subProjectId, wsiId, polygon },
      update: (cache) => {
        cache.writeFragment({
          id: `WSI:${wsiId}`,
          fragment: FRAGMENT_FOCUS_AREA_ON_WSI,
          variables: { subProjectId },
          data: { regionsOfInterest: [focusArea] },
        });
      },
    });
  };

  return (
    <StampLayer
      size={size || 100}
      height={height}
      maxZoom={maxZoom}
      onDrawingDone={addFocusArea}
      map={map}
    />
  );
};

export default FocusAreaDrawingLayer;
