import {
  Button,
  HStack,
  Icon,
  Placeholder,
  TableComponent,
  TableHeaderType,
  TableSkeleton,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import { SortBy, SortByDirection } from '../../../hooks';
import {
  AnnotationCategoriesSortByOptions,
  AnnotationCategory,
} from '../../../types';
import { $TableContainer } from '../AnnotationSettings.component.style';
import { PAGE_SIZE } from './AnnotationCategories.component';

export interface AnnotationCategoriesTableProps {
  loading: boolean;
  annotationCategories: AnnotationCategory[];
  sortBy: SortBy<AnnotationCategoriesSortByOptions>;
  setSortByOptions: (sortBy: SortBy<AnnotationCategoriesSortByOptions>) => void;
}

export const AnnotationCategoriesTable = ({
  loading,
  annotationCategories,
  sortBy,
  setSortByOptions,
}: AnnotationCategoriesTableProps): ReactElement => {
  const columns: TableHeaderType<AnnotationCategory>[] = [
    {
      id: 'name',
      label: 'Category Name',
      minWidth: '100px',
      renderCell: (row) => row?.name,
      align: 'left',
    },
    {
      id: 'color',
      label: 'Category Color',
      minWidth: '200px',
      renderCell: (row) => (
        <input type="color" disabled value={`#${row.color.replace('#', '')}`} />
      ),
      align: 'left',
    },
    {
      id: 'setCode',
      label: 'Set Identifier',
      renderCell: (row) => row?.setCode ?? '--',
      align: 'left',
    },
    {
      id: 'setName',
      label: 'Annotation Set',
      renderCell: (row) => row?.setName ?? '--',
      align: 'left',
    },
    {
      id: 'setModule',
      label: 'Module',
      renderCell: (row) => row?.setModule ?? '--',
      align: 'left',
    },
    {
      id: 'actions',
      label: 'Actions',
      disableSorting: true,
      renderCell: () => (
        <HStack spacing="16">
          <Button disabled small variant="ghost">
            <Icon icon="Edit" />
          </Button>
          <Button disabled small variant="ghost">
            <Icon icon="Trash" />
          </Button>
        </HStack>
      ),
      align: 'right',
    },
  ];

  return (
    <$TableContainer>
      {loading || (!loading && annotationCategories.length) ? (
        <TableComponent<AnnotationCategory>
          enableSelection={false}
          data={loading || !annotationCategories ? [] : annotationCategories}
          columns={columns}
          rowSelector="id"
          sorting={{
            column: sortBy?.column ?? 'name',
            direction: sortBy?.sortDirection ?? 'asc',
          }}
          setSorting={(val) => {
            setSortByOptions({
              column: val?.column as AnnotationCategoriesSortByOptions,
              sortDirection: val?.direction as SortByDirection,
            });
          }}
        />
      ) : null}
      {!loading && annotationCategories.length === 0 ? (
        <Placeholder title="No categories found" />
      ) : null}
      {loading && (
        <div data-testid="annotation-categories-table-skeleton">
          <TableSkeleton rows={PAGE_SIZE} />
        </div>
      )}
    </$TableContainer>
  );
};
