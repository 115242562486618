import styled from 'styled-components';
export const $Popup = styled.div `
  position: absolute;
  top: 0;
  left: ${({ theme }) => -theme.spacings.tile / 2}px;
  z-index: 1000;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ theme }) => theme.spacings.tile}px;
  ${({ theme }) => theme.fontStyles.base};
  box-shadow: ${({ theme }) => theme.shadows.box};
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  overflow: hidden;
  cursor: default;
`;
export const $PopupMeta = styled.div `
  background-color: ${({ theme }) => theme.colors.lighter};
  color: ${({ theme }) => theme.colors.dark};
`;
export const $PopupMetaRow = styled.div `
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.small}px
    ${({ theme }) => theme.spacings.base}px;
`;
export const $PopupTagRow = styled.div `
  display: flex;
  align-items: center;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.white};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighter};
  }
`;
export const $Options = styled.div `
  border-top: 1px solid ${({ theme }) => theme.colors.white};
`;
export const $Option = styled.button `
  ${({ theme }) => theme.fontStyles.base};
  box-sizing: border-box;
  border: none;
  padding: 0;
  background: ${({ theme }) => theme.colors.light};
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  }

  :hover,
  :focus-visible {
    background: ${({ theme }) => theme.colors.transparentDark};
  }
`;
export const $OptionIcon = styled.div `
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${({ theme }) => theme.spacings.button}px;
  width: ${({ theme }) => theme.spacings.button}px;
`;
