import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { replaceInCurrentQueryParams } from '../../../../utils/queryString';
import { buildActiveFeatureParams } from './buildActiveFeatureParams';
import { getActiveFeatureFromSearchString } from './getActiveFeatureFromSearchString';

export const featureTypes = [
  'annotation',
  'interactiveOverlayPolygon',
  'focusArea',
] as const;

export type FeatureType = (typeof featureTypes)[number];

export interface FeatureRef {
  id: string;
  type: FeatureType;
}

const ActiveFeatureContext = createContext<{
  activeFeature: FeatureRef | null;
} | null>(null);
const SetActiveFeatureContext = createContext<{
  setActiveFeature: (value: FeatureRef | null) => void;
} | null>(null);

export function ActiveFeatureProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeFeature, setActiveFeature] = useState<FeatureRef | null>(() =>
    getActiveFeatureFromSearchString(window.location.search)
  );

  const activeFeatureContextValue = useMemo(
    () => ({ activeFeature }),
    [activeFeature]
  );

  const setActiveFeatureContextValue = useMemo(
    () => ({
      setActiveFeature: (feature: FeatureRef | null) => {
        navigate(
          `${location.pathname}?${replaceInCurrentQueryParams(
            buildActiveFeatureParams(feature)
          )}`,
          { replace: true }
        );

        setActiveFeature(feature);
      },
    }),
    [navigate, location.pathname]
  );
  return (
    <ActiveFeatureContext.Provider value={activeFeatureContextValue}>
      <SetActiveFeatureContext.Provider value={setActiveFeatureContextValue}>
        {children}
      </SetActiveFeatureContext.Provider>
    </ActiveFeatureContext.Provider>
  );
}

export function useActiveFeature(): FeatureRef | null {
  const activeFeatureState = useContext(ActiveFeatureContext);

  if (activeFeatureState === null) {
    throw new Error(
      'useActiveFeature must be used in a descendant of ActiveFeatureProvider'
    );
  }

  return activeFeatureState.activeFeature;
}

export function useSetActiveFeature(): (
  activeFeature: FeatureRef | null
) => void {
  const setActiveFeatureState = useContext(SetActiveFeatureContext);

  if (setActiveFeatureState === null) {
    throw new Error(
      'useSetActiveFeature must be used in a descendant of ActiveFeatureProvider'
    );
  }

  return setActiveFeatureState.setActiveFeature;
}
