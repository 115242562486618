import {
  Button,
  CategoryItem,
  PopUp,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { useMutation } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FocusArea } from '../../../../api-types';
import { SET_FOCUS_AREA } from '../../../../graphql/mutations';
import { FRAGMENT_FOCUS_AREA_ON_WSI } from '../../../../graphql/queries';
import { SlideRouteParams } from '../../../__Viewer/Slide';
import {
  DrawingTools,
  StampTool,
  useDrawingModeActions,
  useDrawingModeState,
} from '../../Drawing';
import { useDrawingToolSettingsStore } from '../../Drawing/useDrawingToolSettingsStore';

/**
 * Renders different admin tools
 */
const OldFocusAreaCtrl = ({
  focusAreas,
}: {
  focusAreas?: FocusArea[];
}): ReactElement => {
  const theme = useTheme();
  const { addSnackbar } = useSnackbarMutations();
  const { latestRegionSize, setLatestRegionSize } =
    useDrawingToolSettingsStore();
  const drawingMode = useDrawingModeState();
  const { setFocusAreaDrawingMode, disableDrawing } = useDrawingModeActions();
  /** Get variables from pathname */
  const { subProjectId, wsiId } = useParams<keyof SlideRouteParams>();
  const [setFocusArea, { loading }] = useMutation(SET_FOCUS_AREA);
  const deleteFocusAreaDialog = useDisclosure();
  const focusArea = focusAreas?.[0];

  const confirmDeleteFocusArea = () => {
    setFocusArea({
      variables: { subProjectId, wsiId, focusArea: null, isROIEnabled: false },
      update: (cache) => {
        cache.writeFragment({
          id: `WSI:${wsiId}`,
          fragment: FRAGMENT_FOCUS_AREA_ON_WSI,
          variables: { subProjectId },
          data: { regionsOfInterest: null },
        });
      },
    })
      .then(() => {
        addSnackbar({
          type: 'success',
          message: 'Region of interest deleted successfully',
          closesAfter: 5000,
        });
      })
      .catch(() => {
        addSnackbar({
          type: 'error',
          message: 'Error deleting region of interest',
          closesAfter: 10000,
        });
      });
    deleteFocusAreaDialog.close();
  };

  const rectangleTool: StampTool = {
    name: 'rectangle',
    disabled: false,
    size: latestRegionSize,
    minSize: 100,
    maxSize: 10000,
    sizeStep: 100,
    hasInput: true,
  };

  return (
    <CategoryItem name="Focus Area">
      <section aria-labelledby="focus-area-heading">
        <DrawingTools
          value={drawingMode.mode === 'focusArea' ? drawingMode.tool : null}
          options={[rectangleTool]}
          onChange={(tool) => {
            if (tool === null) {
              disableDrawing();
            } else {
              setLatestRegionSize(tool.size);
              setFocusAreaDrawingMode(tool);
            }
          }}
        />
        <div style={{ padding: `${theme.spacings.base}px` }}>
          <Button
            small
            banner
            variant="error"
            onClick={deleteFocusAreaDialog.open}
            loading={loading}
            disabled={!focusArea}
          >
            Delete Focus Area
          </Button>
          <PopUp
            isVisible={deleteFocusAreaDialog.isOpen}
            title={`Do you want to delete the focus area?`}
            onClose={() => {
              deleteFocusAreaDialog.close();
            }}
            shouldCloseOnBackdropClick={true}
            secondaryAction={{
              label: 'Cancel',
              onClick: () => {
                deleteFocusAreaDialog.close();
              },
            }}
            primaryAction={{
              label: 'Delete',
              onClick: () => {
                confirmDeleteFocusArea();
              },
            }}
          >
            <p style={{ margin: '0' }}>This action cannot be undone</p>
          </PopUp>
        </div>
      </section>
    </CategoryItem>
  );
};

export default OldFocusAreaCtrl;
