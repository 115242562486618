import { createGlobalStyle } from 'styled-components';
const ResetStyle = createGlobalStyle `
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 15px;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Nexa Text', sans-serif;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: auto;
}

body * {
  font-family: 'Nexa Text', sans-serif;
  outline-color: ${({ theme }) => theme.colors.primary};
}

main {
  touch-action: pan-x pan-y;
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  z-index: 0;
}

#tooltip {
  position: absolute;
  z-index: 100000;
}

::selection {
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.accentPeach};
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: inherit;
  margin: 0;
  font-weight: 400;
  font-size: inherit;
}

p {
  font-size: inherit;
  margin: 0;
}

img {
  max-width: 100%;
  box-sizing: initial;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

form fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
`;
export default ResetStyle;
