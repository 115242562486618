import styled from 'styled-components';

export const SearchInputButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[4]}px;
  padding: ${({ theme }) => `${theme.spacings[8]}px 6px`};
  border-right: 1px solid ${({ theme }) => theme.colors.mid};
  background: ${({ theme }) => theme.colors.light};
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.mid};
  border-radius: ${({ theme }) => theme.spacings[2]}px;
  input {
    border: none;
    outline: none;

    :focus {
      outline: none;
      box-shadow: none;
    }
  }
  ${({ theme }) => theme.fontStyles.small}
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacings[12]}px;
  width: 100%;

  input {
    flex: 0;
  }

  * {
    ${({ theme }) => theme.fontStyles.small};
  }

  > div {
    gap: ${({ theme }) => theme.spacings['8']}px;
  }
`;
export const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
