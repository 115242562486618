import {
  Button,
  CategoryItem,
  IconButton,
  Input,
  TagProps,
  VStack,
} from '@aignostics/components';
import { OrganizationRole, VisibleWithScope } from '@aignostics/core';
import React, {
  FormEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { Preset } from './FeatureItem.Preset.types';

interface PresetProps {
  index: number;
  isActive: boolean;
  isEdited: boolean;
  canUpdateShared: boolean;
  canDeleteShared: boolean;
  onClick: (preset: Preset) => void;
  onDelete: (id: string) => void;
  updatePreset: (id: string, name: string, isShared: boolean) => void;
  duplicatePreset: () => void;
  preset: Preset;
  userRole: OrganizationRole;
}

/**
 * A single preset item component, which represents a locally stored preset
 * config along with functionality to update, edit, delete the preset.
 */
const PresetItem: FunctionComponent<PresetProps> = ({
  index,
  isActive,
  isEdited,
  canUpdateShared,
  canDeleteShared,
  onClick,
  onDelete,
  updatePreset,
  duplicatePreset,
  preset,
  userRole,
}) => {
  const theme = useTheme();
  const [isEditMode, setEditMode] = useState(false);
  const [name, setName] = useState(preset.name || `Preset ${index + 1}`);

  const onEditSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    setEditMode(false);

    updatePreset(preset.id, name, preset.isShared);
  };

  // Reset if item looses active state without submit
  useEffect(() => {
    if (!isActive) {
      setEditMode(false);
    }
  }, [isActive]);

  const tags: TagProps[] = preset.isShared ? [{ name: 'Shared' }] : [];

  const onDeleteItem = () => {
    onDelete(preset.id);
  };

  const onUpdateItem = () => {
    updatePreset(preset.id, name, preset.isShared);
  };

  if (isEdited) tags.push({ name: 'Edited' });

  const shouldShowEdit = !preset.isShared || canUpdateShared;
  const shouldShowShare = !isEdited && !preset.isShared;
  const shouldShowUpdate =
    isEdited && ((preset.isShared && canUpdateShared) || !preset.isShared);
  const shouldShowDelete = !preset.isShared || canDeleteShared;

  return (
    <CategoryItem
      key={preset.name}
      name={name}
      tags={tags}
      active={isActive}
      setActive={() => {
        onClick(preset);
      }}
      componentTheme="lighter"
      group="presets-radio-group"
    >
      {isEditMode && (
        <form
          onSubmit={onEditSubmit}
          style={{ display: 'flex', width: '100%' }}
        >
          <Input
            id="name"
            onChange={(name: string) => {
              setName(name);
            }}
            placeholder={name}
            value={name}
            autoFocus
          />

          <IconButton icon="Send" type="submit" disabled={!name} />
        </form>
      )}

      <VStack spacing="base" style={{ padding: `${theme.spacings.base}px` }}>
        {shouldShowEdit && (
          <Button
            icon="Edit"
            small
            banner
            variant="primaryOutline"
            onClick={() => {
              setEditMode(!isEditMode);
            }}
          >
            Edit Name
          </Button>
        )}
        {shouldShowShare && (
          <VisibleWithScope role={userRole} scope="preset:create">
            <Button
              icon="Share"
              small
              banner
              variant="primaryOutline"
              onClick={() => {
                updatePreset(preset.id, preset.name, true);
              }}
            >
              Share Preset
            </Button>
          </VisibleWithScope>
        )}
        {shouldShowUpdate && (
          <Button
            icon="RefreshCw"
            small
            banner
            variant="primaryOutline"
            onClick={onUpdateItem}
          >
            Update
          </Button>
        )}
        <Button
          icon="Duplicate"
          small
          banner
          variant="primaryOutline"
          onClick={duplicatePreset}
        >
          Duplicate preset
        </Button>
        {shouldShowDelete && (
          <Button
            icon="Trash"
            small
            banner
            variant="error"
            onClick={onDeleteItem}
          >
            Delete Preset
          </Button>
        )}
      </VStack>
    </CategoryItem>
  );
};

export default PresetItem;
