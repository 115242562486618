import styled from 'styled-components';
import LayerImageTiles from './Layer.Images.component';

export const $LayerImageTiles = styled(LayerImageTiles).attrs({
  className: 'layer-blend-lighten',
})`
  &.layer-blend-lighten {
    mix-blend-mode: lighten;
  }
`;
