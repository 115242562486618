import { gql } from '@apollo/client';

export const SET_FOCUS_AREA = gql`
  mutation SET_FOCUS_AREA(
    $subProjectId: ID!
    $wsiId: ID!
    $polygon: JSONObject
  ) {
    setFocusArea(subProjectId: $subProjectId, wsiId: $wsiId, polygon: $polygon)
  }
`;
