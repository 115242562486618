import { contrast } from '@aignostics/utils';
import styled from 'styled-components';
export const $CounterBadge = styled.div `
  position: ${({ attached }) => (attached ? 'absolute' : 'relative')};
  top: ${({ attached, theme }) => (attached ? -theme.spacings.small : 0)}px;
  right: ${({ attached, theme }) => (attached ? theme.spacings.small : 0)}px;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  color: ${({ color, theme }) => theme.colors[color]};
  background-color: ${({ color, theme }) => contrast(theme.colors[color], theme)};
  display: flex;
  flex-shrink: 0;
  ${({ theme }) => theme.fontStyles.base};
  height: ${({ theme }) => theme.spacings.large}px;
  justify-content: center;
  line-height: 1;
  min-width: ${({ theme }) => theme.spacings.large}px;
  padding: 0 ${({ theme }) => theme.spacings.small}px;
`;
