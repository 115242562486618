import { Link } from 'react-router-dom';
import styled from 'styled-components';
const ipadPortrait = (theme) => `
  only screen and
  (min-width: ${theme.breakpoints.IPAD_PORTRAIT_MIN}px) and 
  (max-width: ${theme.breakpoints.IPAD_PORTRAIT_MAX}px)
`;
export const $SubNavigation = styled.nav `
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.FULL}px;
  margin: auto;

  @media ${({ theme }) => ipadPortrait(theme)} {
    padding: ${({ theme }) => `0 ${theme.spacings.line}px`};
  }
`;
export const $SubNavigationList = styled.ul `
  display: flex;
  gap: ${({ theme }) => theme.spacings.line * 2}px;
`;
export const $TabLink = styled(Link) `
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.fontStyles.baseBold};
  padding: ${({ theme }) => theme.spacings.line - 2}px 0;
  border-top: solid 2px transparent;
  border-bottom: solid 2px transparent;
  &.active {
    border-bottom: solid 2px ${({ theme }) => theme.colors.primary};
  }
  :hover {
    border-bottom: solid 2px ${({ theme }) => theme.colors.primary};
  }
`;
