import { FeatureRef } from './ActiveFeatureProvider';
import {
  ActiveFeatureIdParamKey,
  ActiveFeatureTypeParamKey,
} from './constants';

export function buildActiveFeatureParams(
  activeFeature: FeatureRef | null
): Record<string, unknown> {
  if (activeFeature === null) {
    return {
      [ActiveFeatureIdParamKey]: undefined,
      [ActiveFeatureTypeParamKey]: undefined,
    };
  }

  return {
    [ActiveFeatureIdParamKey]: activeFeature.id,
    [ActiveFeatureTypeParamKey]: activeFeature.type,
  };
}
