import { useKeyValueCache } from '@aignostics/hooks';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { LoaderBar } from '../LoaderBar';
import { Skeleton } from '../PageSkeleton';
import { useSnackbarMutations } from '../Snackbar/SnackbarContext';
import PageHeader from './PageHeader.component';
/** Generic layout wrapper component for every page view */
const PageLayout = ({ title, description, expandableDescription = true, loading = false, logo, error, children, unmountWhileLoading = true, isScrollable = true, background, fullWidth = false, currentOrganization, }) => {
    const wrapperRef = useRef(null);
    const { addSnackbar } = useSnackbarMutations();
    const theme = useTheme();
    const [isSideScrollable, setIsSideScrollable] = useState(window.innerWidth < theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING);
    useScrollRestore(wrapperRef);
    useEffect(() => {
        if (error) {
            addSnackbar({ message: error.message, type: 'error' });
        }
    }, [error, addSnackbar]);
    const onWindowResize = useCallback(() => {
        setIsSideScrollable(window.innerWidth < theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING);
    }, [theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING]);
    useEffect(() => {
        window.addEventListener('resize', onWindowResize, false);
        return () => {
            window.removeEventListener('resize', onWindowResize, false);
        };
    }, [onWindowResize]);
    const shouldRenderChildren = useMemo(() => !unmountWhileLoading || !loading, [unmountWhileLoading, loading]);
    return (React.createElement("div", { style: {
            overflowY: isScrollable ? 'auto' : 'hidden',
            display: 'flex',
            flexDirection: 'column',
            overflowX: isSideScrollable ? 'auto' : 'hidden',
            background,
        }, ref: wrapperRef },
        React.createElement(LoaderBar, { loading: loading }),
        title && (React.createElement(PageHeader, { title: title, description: description, expandableDescription: expandableDescription, logo: logo, fullWidth: fullWidth, currentOrganization: currentOrganization })),
        loading && !shouldRenderChildren && React.createElement(Skeleton, null),
        shouldRenderChildren ? React.createElement(React.Fragment, null, children) : null));
};
function useScrollRestore(scrollableContainerRef) {
    const location = useLocation();
    /** Persist location scroll postion. */
    const [scrollTopCached, setScrollTopCached] = useKeyValueCache(location.pathname || '/', 0);
    /** Use layout effect for ui calculation on unmount */
    useLayoutEffect(() => {
        const currentMainViewRef = scrollableContainerRef.current;
        if (currentMainViewRef === null)
            return;
        currentMainViewRef.scrollTo?.(0, scrollTopCached);
        return () => {
            setScrollTopCached(currentMainViewRef.scrollTop);
        };
    }, [
        location.pathname,
        scrollTopCached,
        scrollableContainerRef,
        setScrollTopCached,
    ]);
}
export default PageLayout;
