import {
  Button,
  HStack,
  Icon,
  Placeholder,
  TableComponent,
  TableHeaderType,
  TableSkeleton,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { SortBy, SortByDirection } from '../../../hooks';
import {
  AnnotationCategorySet,
  AnnotationCategorySetSortByOptions,
} from '../../../types';
import { $TableContainer } from '../AnnotationSettings.component.style';
import { PAGE_SIZE } from './AnnotationCategorySets.component';

const $TableLink = styled.a`
  text-decoration: none;
`;

export interface AnnotationCategorySetsTableProps {
  loading: boolean;
  annotationCategorySets: AnnotationCategorySet[];
  sortBy: SortBy<AnnotationCategorySetSortByOptions>;
  setSortByOptions: (
    sortByOption: SortBy<AnnotationCategorySetSortByOptions>
  ) => void;
}

const generateLinkWithFilters = ({
  setName,
  setCode,
  module,
}: {
  setName: string;
  setCode: string;
  module?: string;
}) => {
  const base = 'annotation-management/categories';
  const filterSetNameString = `filterSetName=${encodeURIComponent(setName)}`;
  const filterSetCodeString = `filterSetCode=${encodeURIComponent(setCode)}`;
  const filterModuleString = module
    ? `&filterModule=${encodeURIComponent(module)}`
    : '';
  return `${base}?${filterSetNameString}&${filterSetCodeString}${filterModuleString}`;
};

export const AnnotationCategorySetsTable = ({
  loading,
  annotationCategorySets,
  sortBy,
  setSortByOptions,
}: AnnotationCategorySetsTableProps): ReactElement => {
  const columns: TableHeaderType<AnnotationCategorySet>[] = [
    {
      id: 'name',
      label: 'Annotation Set',
      renderCell: (row) => row.name,
      align: 'left',
    },
    {
      id: 'code',
      label: 'Set Identifier',
      minWidth: '20%',
      renderCell: (row) => row?.code,
      align: 'left',
    },
    {
      id: 'moduleCount',
      withSubRows: true,
      label: 'Modules',
      renderCell: (row) => row?.moduleCount ?? '0',
      align: 'left',
      subRowsConfig: {
        data: (row) =>
          row.modules.map((module) => ({
            ...module,
            setName: row.name,
            setCode: row.code,
          })),
        columns: [
          {
            id: 'name',
            renderCell: (data) => data.moduleName,
          },
          {
            id: 'categoryCount',
            renderCell: ({ setName, setCode, categoryCount, moduleName }) => (
              <$TableLink
                href={generateLinkWithFilters({
                  setName,
                  setCode,
                  module: moduleName,
                })}
              >
                {categoryCount}
              </$TableLink>
            ),
          },
        ],
      },
    },
    {
      id: 'categoryCount',
      label: 'Categories',
      renderCell: (row) => (
        <$TableLink
          href={generateLinkWithFilters({
            setName: row.name,
            setCode: row.code,
          })}
        >
          {row?.categoryCount ?? '0'}
        </$TableLink>
      ),
      align: 'left',
    },
    {
      id: 'actions',
      label: 'Actions',
      disableSorting: true,
      renderCell: () => (
        <HStack spacing="16">
          <Button disabled small variant="ghost">
            <Icon icon="Edit" />
          </Button>
          <Button disabled small variant="ghost">
            <Icon icon="Trash" />
          </Button>
        </HStack>
      ),
      align: 'right',
    },
  ];

  return (
    <$TableContainer>
      {loading || (!loading && annotationCategorySets.length) ? (
        <TableComponent<AnnotationCategorySet>
          enableSelection={false}
          data={
            loading || !annotationCategorySets ? [] : annotationCategorySets
          }
          columns={columns}
          rowSelector="id"
          sorting={{
            column: sortBy?.column ?? 'name',
            direction: sortBy?.sortDirection ?? 'asc',
          }}
          setSorting={(val) => {
            setSortByOptions({
              column: val?.column as AnnotationCategorySetSortByOptions,
              sortDirection: val?.direction as SortByDirection,
            });
          }}
        />
      ) : null}
      {!loading && annotationCategorySets.length === 0 ? (
        <Placeholder title="No sets & modules found" />
      ) : null}
      {loading && (
        <div data-testid="annotation-category-sets-table-skeleton">
          <TableSkeleton rows={PAGE_SIZE} />
        </div>
      )}
    </$TableContainer>
  );
};
