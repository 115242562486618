import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Icon from '../Icon';
import Loader from '../Loader';
import { $Button } from './Button.styles';
const Button = React.forwardRef(({ children, banner, to, icon, type, variant = 'primary', onClick, disabled, small, loading, ...props }, ref) => {
    const theme = useTheme();
    // Allow button to be used as anchor link component
    const as = to && Link;
    return (React.createElement($Button
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: ts cannot interfere styled-components `as` types
    , { 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error: ts cannot interfere styled-components `as` types
        as: as, to: to, type: type, onClick: onClick, disabled: disabled, small: small ? 1 : 0, banner: banner ? 1 : 0, variant: variant, ref: ref, ...props },
        icon && React.createElement(Icon, { icon: icon, size: small ? 'input' : 'button' }),
        children && React.createElement("span", null, children),
        loading && (React.createElement(Loader, { sizeContainer: theme.spacings.input, sizeDot: theme.spacings.small, amount: 4, color: "white" }))));
});
Button.displayName = 'Button';
export default Button;
