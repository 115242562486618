import {
  Link,
  OneLiner,
  TableComponent,
  TableHeaderType,
  Tooltip,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import { WsiThumbnail } from '../../components';
import { PaginationType, Slide } from '../../types';
import { CellWithThumbnail, ThumbnailContainer } from './SlideLibrary.styles';

const SlideResultsTable = ({
  getToken,
  rasterTileServerUrl,
  selection,
  setSelection,
  onSelectMultiple,
  isAllSelected,
  data,
  sortBy,
  setSortByOption,
}: {
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  selection: Slide[];
  setSelection: (wsi: Slide, value: boolean) => void;
  onSelectMultiple: (e: 'none' | 'all' | 'page') => void;
  isAllSelected: boolean;
  data: PaginationType<Slide, Record<string, unknown>>;
  sortBy: {
    column: string;
    direction: 'asc' | 'desc';
  } | null;
  setSortByOption: (
    value: {
      column: string;
      direction: 'asc' | 'desc';
    } | null
  ) => void;
}): ReactElement => {
  const columns: TableHeaderType<Slide>[] = [
    {
      id: 'name',
      label: 'Slide Name',
      minWidth: '250px',
      maxWidth: '400px',
      renderCell: (row) => (
        <CellWithThumbnail>
          <ThumbnailContainer>
            <WsiThumbnail
              wsiId={row.id}
              rasterTileServerUrl={rasterTileServerUrl}
              getToken={getToken}
            />
          </ThumbnailContainer>
          <Link href={`/admin/wsi/${row.id}`} target="_blank">
            <Tooltip text={row.name}>
              {(tooltipProps) => <div {...tooltipProps}>{row.name}</div>}
            </Tooltip>
          </Link>
        </CellWithThumbnail>
      ),
      align: 'left',
    },
    {
      id: 'staining',
      label: 'Staining',
      withSubRows: true,
      renderCell: (row) => row?.staining,
      align: 'center',
      subRowsConfig: {
        data: (row) => row.fluorescence,
        columns: [
          {
            id: 'name',
            renderCell: (data) => (
              <CellWithThumbnail>
                <ThumbnailContainer />
                <OneLiner>{data.name}</OneLiner>
              </CellWithThumbnail>
            ),
            align: 'left',
          },
          {
            id: 'staining',
            renderCell: (data) => data.staining,
          },
        ],
      },
    },
    {
      id: 'uuid',
      label: 'UUID',
      renderCell: (row) => row.id,
      align: 'center',
    },
    {
      id: 'association',
      label: 'Association',
      renderCell: (row) => row?.association?.name,
      align: 'center',
    },
    {
      id: 'batch',
      label: 'Batch',
      renderCell: (row) => row?.batchName,
      align: 'center',
    },
    {
      id: 'tissue',
      label: 'Tissue',
      renderCell: (row) => row?.tissue ?? '',
      align: 'center',
    },
    {
      id: 'scanner',
      label: 'Scanner',
      renderCell: (row) => row?.scanner?.vendor,
      align: 'center',
    },
  ];

  return (
    <TableComponent<Slide>
      enableSelection={true}
      data={data?.nodes ?? []}
      columns={columns}
      rowSelector={'id'}
      sorting={sortBy}
      setSorting={setSortByOption}
      selection={selection.map(({ id }) => id)}
      setSelection={setSelection}
      onSelectMultiple={onSelectMultiple}
      isAllSelected={isAllSelected}
    />
  );
};

export default SlideResultsTable;
