import {
  Loader,
  ToggleSplitView,
  ViewerIndex,
  useDisclosure,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { useKeyPress } from '@aignostics/hooks';
import {
  default as React,
  ReactElement,
  Suspense,
  useCallback,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  AnnotationFeatureType,
  OtherAnnotationVisibility,
} from '../../../api-types';
import { Preset, getBasePreset } from '../../__Features/Presets';
import { usePresets } from '../../__Features/Presets/Presets.context';
import { useSlide } from '../../__Pages/Slide/SlideContext';
import Scale from '../OpenLayers/Overlays/Scale.component';
import Viewer from '../OpenLayers/Viewer.component';
import { ViewerProps } from '../types';
import SplitView from './SplitView.component';
import UnsavedPresetDialog from './UnsavedPresetDialog.component';
import {
  $SplitViewContainer,
  $ToggleSplitViewContainer,
} from './ViewerController.styles';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
  useViewerController,
} from './ViewerControllerProvider';
import { useSyncActiveViewerLocation } from './utils/useSyncActiveViewerLocation';

interface ViewerControllerProps
  extends Pick<ViewerProps, 'annotations' | 'overlays' | 'wsi'> {
  subProjectOtherAnnotationVisibility: OtherAnnotationVisibility | undefined;
  annotationFeature: AnnotationFeatureType | undefined;
  getToken: () => Promise<string>;
  currentUser: User;
  userRole: OrganizationRole;
  isROIEnabled: boolean;
  vectorTileServerUrl: string;
  rasterTileServerUrl: string;
  organizationUuid: string;
}

const ViewerViewportWrapper = styled.div({
  flexGrow: 1,
  display: 'flex',
  width: '100%',
  position: 'relative',
});

const viewerPosition = ['left_viewer', 'right_viewer'];

/**
 * Responsible for rendering either single view or split view.
 * In either case, this component reads the params to be applied from
 * the query params, and pass them to the child viewers as props.
 */
function ViewerController({
  wsi,
  overlays,
  annotations,
  subProjectOtherAnnotationVisibility,
  annotationFeature,
  getToken,
  userRole,
  currentUser,
  isROIEnabled,
  vectorTileServerUrl,
  rasterTileServerUrl,
  organizationUuid,
}: ViewerControllerProps): ReactElement {
  const { isAdminSlideViewer } = useSlide();
  const viewerController = useViewerController();
  const {
    activeViewerIndex,
    addSplitViewPanel,
    viewers,
    maps,
    setActiveViewerIndex,
    removeSplitViewPanel,
  } = viewerController;

  useSyncActiveViewerLocation(viewerController.activeViewer, wsi);

  const unsavedPresetModal = useDisclosure();

  const [unsavedUpdatedPreset, setUnsavedUpdatedPreset] =
    useState<Preset | null>(null);

  const { presets, updatePreset } = usePresets();

  useBoundaryKeyboardControl();

  const shouldRenderDialog =
    unsavedPresetModal.isOpen &&
    viewers.length > 1 &&
    unsavedUpdatedPreset !== null &&
    Array.isArray(presets);

  const handleSavePreset = useCallback(async () => {
    if (!unsavedUpdatedPreset) return;
    const updatedPreset = {
      name: unsavedUpdatedPreset.name,
      ...getBasePreset(unsavedUpdatedPreset),
    };
    await updatePreset(
      unsavedUpdatedPreset.id,
      unsavedUpdatedPreset.isShared,
      updatedPreset
    );

    unsavedPresetModal.close();
    removeSplitViewPanel();
  }, [
    removeSplitViewPanel,
    unsavedPresetModal,
    unsavedUpdatedPreset,
    updatePreset,
  ]);

  const handleDiscardPreset = useCallback(() => {
    removeSplitViewPanel();
    setUnsavedUpdatedPreset(null);
  }, [removeSplitViewPanel]);

  return (
    <Suspense fallback={<Loader />}>
      <ViewerViewportWrapper>
        {viewers.length > 1 ? (
          <$SplitViewContainer data-testid="split-view-wrapper">
            {viewers.map((viewer, index) => {
              const indexViewer = index as ViewerIndex;

              return (
                <SplitView
                  key={viewerPosition[index]}
                  active={activeViewerIndex === index}
                  onInactive={setUnsavedUpdatedPreset}
                  onClick={() => {
                    setActiveViewerIndex(indexViewer);
                  }}
                  onClose={() => {
                    removeSplitViewPanel(indexViewer);
                  }}
                  extendedViewerParams={viewer}
                  viewerId={viewerPosition[index]}
                  test-id={viewerPosition[index]}
                >
                  <Viewer
                    map={maps[index]}
                    extendedViewerParams={viewer}
                    wsi={wsi}
                    overlays={overlays}
                    annotations={annotations}
                    otherAnnotationVisibility={
                      subProjectOtherAnnotationVisibility
                    }
                    viewerId={viewerPosition[index]}
                    test-id={viewerPosition[index]}
                    annotationFeature={annotationFeature}
                    currentUser={currentUser}
                    userRole={userRole}
                    isROIEnabled={isROIEnabled}
                    getToken={getToken}
                    organizationUuid={organizationUuid}
                    rasterTileServerUrl={rasterTileServerUrl}
                    vectorTileServerUrl={vectorTileServerUrl}
                  />
                </SplitView>
              );
            })}
          </$SplitViewContainer>
        ) : (
          <Viewer
            map={maps[0]}
            extendedViewerParams={viewers[0]}
            wsi={wsi}
            overlays={overlays}
            annotations={annotations}
            otherAnnotationVisibility={subProjectOtherAnnotationVisibility}
            viewerId="full_viewer"
            annotationFeature={annotationFeature}
            rasterTileServerUrl={rasterTileServerUrl}
            vectorTileServerUrl={vectorTileServerUrl}
            getToken={getToken}
            organizationUuid={organizationUuid}
            currentUser={currentUser}
            userRole={userRole}
            isROIEnabled={isROIEnabled}
          />
        )}

        {!isAdminSlideViewer && (
          <$ToggleSplitViewContainer>
            <ToggleSplitView
              splitViewPanelsCount={viewers.length}
              addSplitViewPanel={addSplitViewPanel}
              removeSplitViewPanel={removeSplitViewPanel}
            />
          </$ToggleSplitViewContainer>
        )}

        <Scale wsi={wsi} />

        {unsavedUpdatedPreset !== null && (
          <UnsavedPresetDialog
            isVisible={shouldRenderDialog}
            unsavedUpdatedPreset={unsavedUpdatedPreset}
            onSave={handleSavePreset}
            onDiscard={handleDiscardPreset}
            onClose={unsavedPresetModal.close}
          />
        )}
      </ViewerViewportWrapper>
    </Suspense>
  );
}

function useBoundaryKeyboardControl(): void {
  const { isBoundaryVisible } = useActiveViewerParams();
  const setActiveViewerParams = useSetActiveViewerParams();
  const toggleBoundaryVisible = useCallback(() => {
    setActiveViewerParams({ isBoundaryVisible: !isBoundaryVisible });
  }, [isBoundaryVisible, setActiveViewerParams]);

  // Toggle opacity via keyboard.
  useKeyPress({ key: 'b' }, toggleBoundaryVisible);
}

export default ViewerController;
