import styled from 'styled-components';
import { $ListItem, $ListItemTitleContainer, } from '../ListItem.styles';
export const $ListItemSlideCompact = styled($ListItem) `
  background-color: ${({ active, theme }) => active ? theme.colors.light : theme.colors.white};
  :focus-within {
    box-shadow: none;
  }
  :active {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;
export const $ListItemContentContainer = styled.div `
  height: 48px;
  order: 2;
  display: flex;
  flex: 1;
  flex-direction: column;

  ${$ListItemTitleContainer} {
    padding: 0 4px;
    width: 200px;
    font-weight: 400;
  }
`;
