/* eslint-disable sonarjs/cognitive-complexity */
import styled from 'styled-components';
import Icon from '../Icon';
import { fadeInVertical } from './components/SelectMenu/SelectMenu.styles';
const TABLE_MAX_COLUMN_DEFAULT_WIDTH = '250px';
const TABLE_MIN_COLUMN_DEFAULT_WIDTH = '100px';
const TABLE_SELECT_CHECKBOX_WIDTH = '100px';
const TABLE_EMPTY_COLUMN_WIDTH = '1000px';
const justifyContent = (align) => {
    switch (align) {
        case 'left':
            return `flex-start`;
        case 'right':
            return `flex-end`;
        case 'center':
            return `center`;
        default:
            return `center`;
    }
};
export const TableOuter = styled.div `
  background-color: ${({ theme }) => theme.colors.lighter};
  width: 100%;
  overflow: scroll;
  white-space: nowrap;
  max-height: 90vh;
`;
export const Table = styled.table `
  border-collapse: separate;
  border-spacing: 0;
  border-style: none;
  margin: 0 auto;
  width: 100%;

  th:first-child,
  td:first-child {
    left: 0;
    position: sticky;
    z-index: 2;
  }

  th {
    top: 0;
    position: sticky;
    z-index: 2;
    background: white;

    :first-child {
      z-index: 3;
    }
  }
  th,
  tr,
  td {
    height: ${({ theme }) => theme.spacings['64']}px;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid ${({ theme }) => theme.colors.light};
  }
  tr,
  td {
    padding: 0;
  }
`;
export const HeaderRow = styled.tr `
  width: 100%;
  background: white;
`;
export const HeaderCell = styled.th `
  background: white;
  ${({ theme }) => theme.fontStyles.smallBold};
  text-align: ${({ align }) => align ?? 'center'};
  padding: ${({ theme }) => `${theme.spacings['16']}px ${theme.spacings['12']}px`};

  :last-child {
    > div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }

  ${({ theme, enableSelection, align, minWidth, maxWidth }) => {
    if (enableSelection) {
        return `
      :first-child {
          width: ${TABLE_SELECT_CHECKBOX_WIDTH}!important;
          padding: ${theme.spacings['12']}px ${theme.spacings['16']}px ${theme.spacings['12']}px ${theme.spacings['40']}px;
        > div {
              > div:first-child {
                  width: ${theme.spacings['32']}px;
                  display: flex;
                  align-items: center
                }
            }
        }
      
      :nth-child(2) {
            position: sticky;
            left: ${TABLE_SELECT_CHECKBOX_WIDTH}!important;
            z-index: 3;
            text-align: ${align ?? 'left'};
            
            > div:first-child {
              justify-content: ${justifyContent(align ?? 'left')};
            }
        }
        
          :not(:first-child) {

            min-width: ${minWidth ?? TABLE_MIN_COLUMN_DEFAULT_WIDTH};
            max-width: ${maxWidth ?? TABLE_MAX_COLUMN_DEFAULT_WIDTH};
            width:  ${maxWidth ?? TABLE_EMPTY_COLUMN_WIDTH};
            justify-content: ${justifyContent(align ?? 'center')};
            text-align: ${align ?? 'center'};
          }
        `;
    }
    return `
        min-width: ${minWidth ?? TABLE_MIN_COLUMN_DEFAULT_WIDTH};
        max-width: ${maxWidth ?? TABLE_MAX_COLUMN_DEFAULT_WIDTH};
        width:  ${maxWidth ?? TABLE_EMPTY_COLUMN_WIDTH};
        justify-content: ${justifyContent(align ?? 'center')};
        
        :first-child {
          position: sticky;
          left: 0;
          z-index: 3;
          text-align: ${align ?? 'left'};
          
          div:first-child {
            justify-content: ${justifyContent(align ?? 'left')};
          };  
        }
`;
}}

  div {
    justify-content: ${({ align }) => justifyContent(align ?? 'center')};
  }
`;
export const SortableHeader = styled.div `
  position: relative;
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
  display: flex;
  gap: ${({ theme }) => `${theme.spacings['8']}px`};
  align-items: center;
  justify-content: center;
`;
export const TableRow = styled.tr `
  border-bottom: 1px solid ${({ theme }) => theme.colors.lighter};
`;
export const TableSubRow = styled.tr `
  ${fadeInVertical};
  background: #f5f5f5;

  height: ${({ theme }) => theme.spacings['32']}px;
  td {
    ${({ theme }) => theme.fontStyles.small};
    position: sticky;
    left: ${({ enableSelection }) => enableSelection ? TABLE_SELECT_CHECKBOX_WIDTH : 0};
    z-index: 1;
    background: inherit;
    padding: ${({ theme }) => `0 ${theme.spacings['12']}px`};
    min-height: ${({ theme }) => `${theme.spacings['64']}px`};
    ${({ enableSelection, align, minWidth, maxWidth }) => {
    if (enableSelection) {
        return `
            :nth-child(2) {
              position: sticky;
              left: ${TABLE_SELECT_CHECKBOX_WIDTH};
              z-index: 2;
              text-align: ${align ?? 'left'};
              justify-content: ${justifyContent(align ?? 'left')};
            }
      
            :not(:first-child) {
              min-width: ${minWidth ?? TABLE_MIN_COLUMN_DEFAULT_WIDTH};
              max-width: ${maxWidth ?? TABLE_MAX_COLUMN_DEFAULT_WIDTH};
            }
          `;
    }
    return `
            :first-child {
              position: sticky;
              left: 0;
              z-index: 2;
              text-align: ${align ?? 'left'};
              justify-content: ${justifyContent(align ?? 'left')};
            }
      
            :not(:first-child) {
              min-width: ${minWidth ?? TABLE_MIN_COLUMN_DEFAULT_WIDTH};
              max-width: ${maxWidth ?? TABLE_MAX_COLUMN_DEFAULT_WIDTH};
            }`;
}}
  }
`;
export const TableCell = styled.td `
  position: relative;
  text-align: ${(props) => props.align ?? 'center'};

  ${({ enableSelection, align, minWidth, maxWidth }) => {
    if (enableSelection) {
        return `
      :first-child {
        > div {
            width: ${TABLE_SELECT_CHECKBOX_WIDTH}!important;
          }
        }
      :nth-child(2) {
            position: sticky;
            left: ${TABLE_SELECT_CHECKBOX_WIDTH};
            z-index: 1;
            text-align: ${align ?? 'left'};
            justify-content: ${justifyContent(align ?? 'left')};

            > div:first-child {
              justify-content: ${justifyContent(align ?? 'left')};
              text-align: ${align ?? 'left'};
            }
        }
        
          :not(:first-child) {
              min-width: ${minWidth ?? TABLE_MIN_COLUMN_DEFAULT_WIDTH};
              max-width: ${maxWidth ?? TABLE_MAX_COLUMN_DEFAULT_WIDTH};
              justify-content: ${justifyContent(align ?? 'center')};
            }
        `;
    }
    return `
    
        min-width: ${minWidth ?? TABLE_MIN_COLUMN_DEFAULT_WIDTH};
        max-width: ${maxWidth ?? TABLE_MAX_COLUMN_DEFAULT_WIDTH};
        text-align: ${align ?? 'center'};
        justify-content: ${justifyContent(align ?? 'left')};
        
        :first-child {
          position: sticky;
          left: 0;
          z-index: 1;
          text-align: ${align ?? 'left'};
          justify-content: ${justifyContent(align ?? 'center')};
        }
      `;
}}

  div {
    justify-content: ${({ align }) => justifyContent(align ?? 'center')};
    align-items: center;
  }
`;
export const ExpandSubRowContainer = styled.div `
  width: 82%;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${TABLE_MAX_COLUMN_DEFAULT_WIDTH};
`;
export const ExpandSubRowButton = styled.div `
  height: ${({ theme }) => `${theme.spacings['32']}px`};
  margin-left: ${({ theme }) => `${theme.spacings['4']}px`};
  background: white;
  border-radius: ${({ theme }) => `${theme.spacings['2']}px`};
  width: fit-content;
  padding: ${({ theme }) => `${theme.spacings['12']}px`};
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth ?? TABLE_MAX_COLUMN_DEFAULT_WIDTH};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacings['8']}px`};
  cursor: pointer;
  justify-content: ${({ align }) => justifyContent(align ?? 'center')};
`;
export const CopyContainer = styled.div `
  position: absolute;
  top: 25%;
  right: ${({ theme }) => `${theme.spacings['8']}px`};
  height: inherit;
  overflow: visible;
  padding-left: ${({ theme }) => `${theme.spacings['12']}px`};

  background: ${(props) => props.isOpen
    ? `linear-gradient(
    90deg,
    ${props.theme.colors.light}00 0%,
    ${props.theme.colors.light}99 35%,
    ${props.theme.colors.light}99 100%
  )`
    : `linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  )`};
`;
export const TableCellContent = styled.div `
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  height: ${({ theme }) => `${theme.spacings['64']}px`};
  align-items: center;
  margin-top: ${({ theme }) => `${theme.spacings['8']}px`};
  padding: ${({ theme }) => `0 ${theme.spacings['12']}px`};
  background: ${({ isOpen, theme }) => (isOpen ? theme.colors.light : 'white')};
  ${({ theme }) => theme.fontStyles.small};
  transition: background 300ms ease;
`;
export const CheckboxWrapper = styled.div `
    padding: ${({ theme }) => `${theme.spacings['12']}px ${theme.spacings['16']}px ${theme.spacings['12']}px 31px`};
    width: ${TABLE_SELECT_CHECKBOX_WIDTH}!important;
    height: ${({ theme }) => theme.spacings['64']}px;
    display: flex;
    align-items: center;
    background: white;
    margin-top: ${({ theme }) => theme.spacings['8']}px;
    background: ${({ isOpen, theme }) => (isOpen ? theme.colors.light : 'white')}};

  > div:first-child {
    width: ${({ theme }) => theme.spacings['32']}px;
    display: flex;
    align-items: center;
    background: ${({ isOpen, theme }) => (isOpen ? theme.colors.light : 'white')}
  }
`;
export const HeaderIconWrapper = styled.div `
  height: ${({ theme }) => `${theme.spacings['12']}px`};
  width: ${({ theme }) => `${theme.spacings['12']}px`};

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeaderArrowIcon = styled(Icon) `
  margin: auto;
`;
export const RadioButtonWrapper = styled.div `
  * {
    cursor: pointer;
  }
`;
export const SelectMenuWrapper = styled.div `
  padding: 0 ${({ theme }) => theme.spacings['12']}px;
  * {
    ${({ theme }) => theme.fontStyles.small};
    margin: ${({ theme }) => `${theme.spacings['8']}px 0`};
  }
  label {
    margin: 0 ${({ theme }) => theme.spacings['12']};
  }
`;
export const SelectAllWrapper = styled.div `
  display: flex;
  gap: 8px;
  margin-left: 8px;
`;
