import { Feature, Polygon } from 'geojson';
import { MapBrowserEvent, Map as OLMap } from 'ol';
import { Coordinate } from 'ol/coordinate';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { default as VectorLayer } from '../../../Layers/Vector.Layer.component';
import {
  addInteractions,
  closeLineString,
  getDistance,
  getGeoJsonFromPoints,
  getPointFromDrawingCoordinateFactory,
  preventTouch,
  removeInteractions,
  usePointerOverlay,
} from '../../../utils';
import { getFeaturesForPoints } from './getFeaturesForPoints';
import { getStyleForFeature } from './getStyleForFeature';

export enum DrawingStatus {
  init = 'init',
  paused = 'paused',
  resume = 'resume',
  pending = 'pending',
  drawing = 'drawing',
  valid = 'valid',
}

/** Distance threshold for pen drawings. */
export const DISTANCE_THRESHOLD = 8;

interface PenLayerProps {
  width: number;
  height: number;
  maxZoom: number;
  zIndex: number;
  onDrawingDone: (polygon: Polygon) => void;
  map: OLMap;
}

/**
 * Renders a line string while drawing. Circle features at first and last point
 * with a certain color indicate the drawing status (e.g. "paused") to the user.
 */
function PenLayer({
  width,
  height,
  maxZoom,
  zIndex,
  onDrawingDone,
  map,
}: PenLayerProps): ReactElement {
  const theme = useTheme();

  const [points, setPoints] = useState<number[][]>([]);
  const [status, setStatus] = useState<DrawingStatus>(DrawingStatus.init);

  // Setup pointer overlay
  const pointer = usePointerOverlay(map, { size: 6 });

  const getPointFromDrawingCoordinate = useMemo(
    () => getPointFromDrawingCoordinateFactory(height),
    [height]
  );

  // Return the projected distance in pixels relative to viewport between two
  // coordinates
  const getProjectedDistance = useCallback(
    (coordinate: Coordinate, referencePoint: 'first' | 'last'): number => {
      // First and last point of line string drawing
      const firstPoint = points[0] as number[] | undefined;
      const lastPoint = points[points.length - 1] as number[] | undefined;

      const pts = {
        first: firstPoint,
        last: lastPoint !== firstPoint ? lastPoint : undefined,
      };

      const refPt = pts[referencePoint];

      const currPoint = getPointFromDrawingCoordinate(coordinate);

      if (refPt !== undefined) {
        const distance = getDistance(refPt, currPoint);
        const zoom = map.getView().getZoom() || 0;
        const distanceProjected = distance / 2 ** (maxZoom - zoom);
        return distanceProjected;
      }
      return 0;
    },
    [getPointFromDrawingCoordinate, map, maxZoom, points]
  );

  // Add a new point to the array
  const addPoint = useCallback(
    (coordinate: Coordinate) => {
      const point = getPointFromDrawingCoordinate(coordinate);
      setPoints((prevPoints) => [...prevPoints, point]);
    },
    [getPointFromDrawingCoordinate]
  );

  useEffect(() => {
    const startDrawing = (event: MapBrowserEvent<UIEvent>) => {
      const isPenCoordinatesInLastCircleThreshold =
        getProjectedDistance(event.coordinate, 'last') < DISTANCE_THRESHOLD;
      switch (status) {
        case DrawingStatus.init:
          removeInteractions(map);
          addPoint(event.coordinate);
          setStatus(DrawingStatus.pending);
          break;
        case DrawingStatus.resume:
          removeInteractions(map);
          setStatus(DrawingStatus.drawing);
          break;
        case DrawingStatus.paused:
          if (isPenCoordinatesInLastCircleThreshold) {
            removeInteractions(map);
            setStatus(DrawingStatus.drawing);
          }
          break;
        case DrawingStatus.drawing:
        case DrawingStatus.valid:
        case DrawingStatus.pending:
          break;
      }
    };

    // Update drawing status on pointer down event.
    const handlePointerDown = preventTouch(startDrawing);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerdown', handlePointerDown);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerdown', handlePointerDown);
    };
  }, [addPoint, map, status, getProjectedDistance]);

  useEffect(() => {
    // Update drawing status on pointer move event and draw line.
    const handlePointerMove = (event: MapBrowserEvent<UIEvent>) => {
      const isPenCoordinatesInLastCircleThreshold =
        getProjectedDistance(event.coordinate, 'last') <= DISTANCE_THRESHOLD;

      const isPenCoordinatesInFirstCircleThreshold =
        getProjectedDistance(event.coordinate, 'first') <= DISTANCE_THRESHOLD;
      // Updated pointer position
      pointer?.setPosition(event.coordinate);

      // Handle according to current drawing status
      switch (status) {
        case DrawingStatus.pending: {
          addPoint(event.coordinate);
          if (!isPenCoordinatesInFirstCircleThreshold) {
            setStatus(DrawingStatus.drawing);
          }
          break;
        }
        case DrawingStatus.paused: {
          if (isPenCoordinatesInLastCircleThreshold) {
            setStatus(DrawingStatus.resume);
          }
          break;
        }
        case DrawingStatus.resume: {
          if (!isPenCoordinatesInLastCircleThreshold) {
            setStatus(DrawingStatus.paused);
          }
          break;
        }
        case DrawingStatus.drawing: {
          addPoint(event.coordinate);
          if (isPenCoordinatesInFirstCircleThreshold) {
            setStatus(DrawingStatus.valid);
          }
          break;
        }
        case DrawingStatus.valid: {
          if (!isPenCoordinatesInFirstCircleThreshold) {
            setStatus(DrawingStatus.drawing);
          }
          break;
        }
        case DrawingStatus.init:
          break;
      }
    };
    map.on('pointermove', handlePointerMove);

    return () => {
      map.un('pointermove', handlePointerMove);
    };
  }, [addPoint, getProjectedDistance, map, pointer, status]);

  useEffect(() => {
    // Update drawing status on pointer up event and set geometry to parent
    // handler.
    const handlePointerUp = () => {
      switch (status) {
        case DrawingStatus.drawing: {
          addInteractions(map);
          setStatus(DrawingStatus.paused);
          break;
        }
        case DrawingStatus.pending:
          addInteractions(map);
          setStatus(DrawingStatus.init);
          setPoints([]);
          break;
        case DrawingStatus.valid: {
          addInteractions(map);
          const polygon = closeLineString(points);
          const geometry = getGeoJsonFromPoints(polygon, true);

          if (geometry.type !== 'Polygon') {
            throw new Error('Drawn line must be a Polygon.');
          }
          // Send polygon geometry to parent handler
          onDrawingDone(geometry);
          setStatus(DrawingStatus.init);
          setPoints([]);
          break;
        }
        case DrawingStatus.init:
        case DrawingStatus.paused:
        case DrawingStatus.resume:
          break;
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerup', handlePointerUp);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerup', handlePointerUp);
    };
  }, [map, onDrawingDone, points, status, getProjectedDistance]);

  const features: Feature[] = useMemo(
    () => getFeaturesForPoints(points, status),
    [points, status]
  );

  return (
    <VectorLayer
      features={features}
      zIndex={zIndex}
      opacity={1}
      width={width}
      height={height}
      getStyle={getStyleForFeature(status, theme)}
      map={map}
    />
  );
}

export default PenLayer;
