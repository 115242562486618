import React, { Fragment } from 'react';
import { $SplitViewButtonPanel, $SplitViewLabel, $ToggleSplitViewWrapper, } from './ToggleSplitView.styles';
export const ToggleSplitView = ({ splitViewPanelsCount, addSplitViewPanel, removeSplitViewPanel, }) => {
    const splitViewPanelAmounts = [1, 2];
    const toggleSplitViewPanel = (toggleButtonIndex) => {
        if (toggleButtonIndex > splitViewPanelsCount) {
            addSplitViewPanel();
        }
        else {
            removeSplitViewPanel();
        }
    };
    return (React.createElement($ToggleSplitViewWrapper, null, splitViewPanelAmounts.map((amount, index) => {
        const amountArr = Array.from({ length: amount }, (_, i) => i);
        const isActive = amount === splitViewPanelsCount;
        const label = `${index > 0 ? 'Enable' : 'Disable'} split view`;
        const name = 'toggle-split-view';
        const id = `${name}-${index}`;
        return (React.createElement(Fragment, { key: amount },
            React.createElement("input", { id: id, type: "radio", checked: isActive, name: name, onChange: () => {
                    toggleSplitViewPanel(amount);
                }, style: { display: 'none' } }),
            React.createElement($SplitViewLabel, { "data-testid": id, htmlFor: id, "aria-label": label, active: isActive }, amountArr.map((_, index) => (React.createElement($SplitViewButtonPanel, { key: index }))))));
    })));
};
