import {
  ApolloClient,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import createCache from './createCache';

/**
 * Creates a new Apollo client instance for the given URI and sets request header information.
 * @param uri         The API endpoint URI
 * @param getToken    Function to get the authentication token
 * @param impersonate Optional user ID for impersonation
 * @returns           Apollo client instance
 */
export function createClient(
  uri: string,
  getToken: () => Promise<string>,
  impersonate: string | null
): ApolloClient<NormalizedCacheObject> {
  const httpLink = createHttpLink({ uri });

  /**
   * Send authorization token and optional impersonate user ID via request header.
   */
  const authLink = setContext(async () => {
    const token = await getToken();
    const headers: Record<string, string> = {
      authorization: `Bearer ${token}`,
    };

    if (impersonate !== null) {
      headers.impersonate = impersonate;
    }
    return { headers };
  });

  // Error handling link to log GraphQL and network errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // Format the locations array if it exists
        const formattedLocations = locations
          ? locations.map((loc) => `(${loc.line}, ${loc.column})`).join(', ')
          : '';

        const formattedPath = path ? path.join('.') : '';

        Sentry.captureException(
          `[GraphQL error]: Message: ${message}, Location: ${formattedLocations}, Path: ${formattedPath}`
        );
      });
    }
    if (networkError) {
      const errorMessage =
        networkError instanceof Error
          ? networkError.message
          : String(networkError);
      Sentry.captureException(`[Network error]: ${errorMessage}`);
    }
  });

  const link = errorLink.concat(authLink.concat(httpLink));

  return new ApolloClient({ link, cache: createCache() });
}
