export type AppBrandingSetting = 'aignx' | 'gavi';

/** Get initial app branding setting from location hostname */
export const getAppBrandingSetting = (): AppBrandingSetting => {
  const key = location.hostname;

  if (/ultivue|gavi/.test(key)) {
    return 'gavi';
  }

  return 'aignx';
};
