import { AppBrandingSetting } from './getAppBrandingSetting';

/**
 * Return correct favicon uri for given app branding setting and build
 * environment.
 */
export function getFavicon(
  appBrandingSetting: AppBrandingSetting,
  buildEnv: string
): string {
  switch (buildEnv) {
    case 'production':
    case 'staging':
      return `${
        process.env.REACT_APP_ROUTER_BASENAME ?? ''
      }/assets/${appBrandingSetting}-${buildEnv}.png`;
    default:
      return `${
        process.env.REACT_APP_ROUTER_BASENAME ?? ''
      }/assets/${appBrandingSetting}-develop.png`;
  }
}
