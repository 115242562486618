import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { fadeIn } from '../animations/fadeIn';
export const $GridItemStackWrapper = styled.div `
  padding-right: 14px;
  min-width: 256px;
`;
export const $GridItem = styled(Link).withConfig({
    shouldForwardProp: (prop) => prop !== 'stack' && prop !== 'isVisible',
}) `
  ${fadeIn}
  height: 100%;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.5)};
  position: relative;
  box-sizing: border-box;
  overflow: visible;
  text-decoration: none;
  border-radius: 2px;
  padding: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.light}`};
  min-width: ${({ theme }) => `${theme.spacings.tile}px`};
  background: ${({ theme, active }) => active ? theme.colors.warning : theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: stretch;

  :focus-visible {
    box-shadow: ${({ theme }) => theme.glows.focus};
    z-index: 1;
  }

  :active {
    background-color: ${({ active, theme }) => active ? theme.colors.warning : theme.colors.white};
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  box-shadow: ${({ stack, theme }) => stack
    ? `
           10px 0 0 -5px ${theme.colors.white},
           10px 0 0 -4px ${theme.colors.light},
           19px 0 0 -8px ${theme.colors.white},
           19px 0 0 -7px ${theme.colors.light}`
    : `none`};

  &:hover {
    box-shadow: ${({ stack, theme }) => stack
    ? `
             10px 0 0 -5px ${theme.colors.white},
             10px 0 0 -4px ${theme.colors.light},
             19px 0 0 -8px ${theme.colors.white},
             19px 0 0 -7px ${theme.colors.light},
             0 0 24px #00000019`
    : `0 0 24px #00000019`};
  }
`;
export const $GridItemFloatingTagsContainer = styled.div `
  position: absolute;
  z-index: 1;
  left: 8px;
  top: 8px;
`;
export const $GridItemHeaderContainer = styled.div `
  width: 100%;
  padding: 0 12px;
  align-items: center;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const $GridItemImageContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => `${theme.spacings.tile}px`};
  height: ${({ theme }) => `${theme.spacings.tile}px`};

  img,
  svg {
    aspect-ratio: 1 / 1;
  }
`;
export const $GridItemFooterContainer = styled.div `
  width: 100%;
  padding: 0 12px 8px;
  align-items: center;
  height: '28px';
`;
