import { isEqual } from 'lodash';
import React, { useState } from 'react';
import Icon from '../../../Icon';
import { Button, Checkbox } from '../../../index';
import { HeaderCell } from '../../TableComponent.styles';
import SelectMenu from '../SelectMenu/SelectMenu.component';
import { MenuFooter, MenuHeader, MenuItem, MenuItems, MenuWrapper, } from './DynamicColumnsMenu.styles';
const DynamicColumnsMenu = ({ columns, setColumnsState, }) => {
    const [innerColumnsState, setInnerColumnsState] = useState(columns);
    const setColumnVisibility = (column, value) => {
        setInnerColumnsState((prevState) => {
            return prevState.map((c) => c.id === column ? { ...c, checked: value } : c);
        });
    };
    const isDefaultState = isEqual(columns, innerColumnsState);
    const onSave = () => {
        setColumnsState(innerColumnsState);
    };
    const onClose = () => {
        setInnerColumnsState(columns);
    };
    const MENU_MAX_HEIGHT = 400;
    return (React.createElement(HeaderCell, { enableSelection: false },
        React.createElement(SelectMenu, { maxHeight: MENU_MAX_HEIGHT, menuItems: React.createElement(MenuWrapper, { maxHeight: MENU_MAX_HEIGHT },
                React.createElement(MenuHeader, null, "Edit table columns"),
                React.createElement(MenuItems, null, innerColumnsState.map((column) => (React.createElement(MenuItem, { key: `table_column_${column.id}`, isFrozen: column.isFrozen, onClick: (e) => {
                        setColumnVisibility(column.id, !column.checked);
                        e.stopPropagation();
                    } },
                    React.createElement(Checkbox, { checked: Boolean(column.checked), disabled: column.isFrozen }),
                    React.createElement(React.Fragment, null, column.label))))),
                React.createElement(MenuFooter, null,
                    React.createElement(Button, { variant: "primaryOutline", small: true, onClick: onClose }, "Cancel"),
                    React.createElement(Button, { small: true, disabled: isDefaultState, onClick: onSave }, "Save changes"))) },
            React.createElement(Icon, { icon: "PlusSquare" }))));
};
export default DynamicColumnsMenu;
