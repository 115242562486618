import {
  Button,
  HStack,
  IconButton,
  useSnackbarMutations,
} from '@aignostics/components';
import { MementoCache } from '@aignostics/core';
import { useApolloClient, useMutation } from '@apollo/client';
import React, { FormEvent, FunctionComponent, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Annotation, AnnotationDispatch } from '../../../api-types';
import {
  deleteAnnotationFromCache,
  setAnnotationCreatedAt,
  setAnnotationState,
} from '../../../graphql/helpers';
import { buildSubsequentWsiUrl } from '../../__Pages/Slide/buildSubsequentWsiUrl';
import { SlideRouteParams } from '../../__Viewer/Slide';
import {
  useSelectedFeature,
  useSetSelectedFeature,
} from '../../__Viewer/ViewerController';
import { SYNC_ANNOTATIONS } from './SYNC_ANNOTATIONS';
import { useAnnotations } from './hooks/useAnnotations';
import { filterDuplicatedAndEmptyAnnotations } from './utils/filterDuplicatedAndEmptyAnnotations';

export interface LocalAnnotation extends Annotation {
  properties: Annotation['properties'] & {
    state: 'create' | 'update' | 'delete';
  };
}

interface SyncAnnotationsResult {
  message: string;
  results: { annotationId: string; createdAt: string }[];
}

/**
 * Component for submitting locally changed annotations to the backend. Besides
 * the actual submit also renders undo- & redo- functionality as well as a
 * button to jump to the next slide to be annotated.
 */
const SubmitCtrl: FunctionComponent<{
  localAnnotations: LocalAnnotation[];
}> = ({ localAnnotations }) => {
  const client = useApolloClient();
  const cache = client.cache as MementoCache;
  const { subProjectId, wsiId } = useParams<
    keyof SlideRouteParams
  >() as SlideRouteParams;
  const navigate = useNavigate();
  const [syncAnnotations, { loading, error }] = useMutation(SYNC_ANNOTATIONS);
  const { addSnackbar } = useSnackbarMutations();
  const setSelectedFeature = useSetSelectedFeature();
  const selectedFeature = useSelectedFeature();
  const location = useLocation();
  const [{ nextWsiIdWithoutAnnotations }] = useAnnotations(true);

  useEffect(() => {
    if (error) {
      addSnackbar({ message: error.message, type: 'error', closesAfter: 0 });
    }
  }, [error, addSnackbar]);

  // Returns the annotation insert payload to be passed to the api for a given
  // annotation.
  //
  // We distinguish three different inserts based on their state:
  // `create` – newly created annotation
  // `update` – locally updated annotation
  // `delete` – locally deleted annotation
  const getPayload = (annotation: LocalAnnotation): AnnotationDispatch => {
    const annotationId = annotation.id;
    switch (annotation.properties.state) {
      case 'create':
        return {
          state: annotation.properties.state,
          annotationId,
          wsiId,
          annotationCategoryId: annotation.properties.category.id,
          geometry: annotation.geometry,
          origin: annotation.properties.origin,
          drawnAt: annotation.properties.drawnAt,
        };
      case 'update':
        return {
          annotationId,
          state: annotation.properties.state,
          annotationCategoryId: annotation.properties.category.id,
          geometry: annotation.geometry,
          drawnAt: annotation.properties.drawnAt,
          wsiId,
        };
      case 'delete':
        return {
          annotationId,
          state: annotation.properties.state,
          wsiId,
        };
    }
  };

  // Sends annotations to backend and updates local cache.
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const annotations = localAnnotations.map(getPayload);
    const filteredAnnotations =
      filterDuplicatedAndEmptyAnnotations(annotations);
    void syncAnnotations({
      variables: { annotations: filteredAnnotations, subProjectId },
      update: (_, { data }) => {
        const results = (data.syncAnnotations as SyncAnnotationsResult).results;
        // Update annotation states and permanently remove deletes
        localAnnotations.forEach((annotation) => {
          switch (annotation.properties.state) {
            case 'create':
            case 'update': {
              // Simply unset state value after `create` and `update`.
              // This indicates, the annotation is in sync with the database.
              setAnnotationState(client, annotation.id, null);

              // Update cache with created_at values computed on db insertion
              const createdAt = results.find(
                (res) => res.annotationId === annotation.id
              )?.createdAt;

              if (createdAt !== undefined) {
                setAnnotationCreatedAt(client, annotation.id, createdAt);
              }
              break;
            }
            case 'delete': {
              deleteAnnotationFromCache(client, wsiId, annotation.id);
            }
          }
        });

        if (selectedFeature) {
          setSelectedFeature({
            ...selectedFeature,
            properties: {
              ...selectedFeature.properties,
              state: undefined,
            },
          });
        }

        /** Reset memento cache state */
        cache.mementoReset();

        // evict all cache tat start with WSI
        const allKeys = Object.keys(cache.extract());
        allKeys.forEach((key) => {
          if (key.startsWith('WSI:')) {
            cache.evict({ id: key });
          }
        });

        cache.gc();
      },
    });
  };

  /** Resets all annotations to their initial state. */
  const handleReset = () => {
    if (
      window.confirm(
        `${localAnnotations.length} unsynced changes will be lost.`
      )
    ) {
      localAnnotations.forEach((annotation) => {
        switch (annotation.properties.state) {
          case 'create':
            /** Directly remove newly created annotations. */
            deleteAnnotationFromCache(client, wsiId, annotation.id);
            break;
          case 'delete':
            /** Set delete flag on annotations coming from backend. */
            setAnnotationState(client, annotation.id, null);
            break;
          // TODO add "update" case
          default:
            break;
        }
      });
    }
  };

  /** Go to next wsi without annotations. */
  const goNextWsi = () => {
    if (!nextWsiIdWithoutAnnotations) return;

    if (
      localAnnotations.length === 0 ||
      window.confirm(
        `${localAnnotations.length} unsynced changes will be lost.`
      )
    ) {
      const subsequentWsiUrl = buildSubsequentWsiUrl(
        location,
        nextWsiIdWithoutAnnotations
      );

      navigate(subsequentWsiUrl);
    }
  };

  return (
    <>
      <HStack
        as="form"
        onSubmit={handleSubmit}
        onReset={handleReset}
        alignItems="center"
        spacing="base"
      >
        <HStack>
          <IconButton
            icon="RotateCcw"
            onClick={() => cache.undo()}
            disabled={loading || !cache.canUndo()}
            onKey={{ key: 'z', metaKey: true }}
            description="Undo"
          />

          <IconButton
            icon="RotateCw"
            onClick={() => cache.redo()}
            disabled={loading || !cache.canRedo()}
            onKey={{ key: 'z', metaKey: true, shiftKey: true }}
            description="Redo"
          />
        </HStack>

        <Button
          type="submit"
          small
          banner
          disabled={loading || localAnnotations.length === 0}
          loading={loading}
        >
          {[
            'Submit',
            localAnnotations.length > 0 && `(${localAnnotations.length})`,
          ]
            .filter(Boolean)
            .join(' ')}
        </Button>

        <IconButton
          icon="ChevronsRight"
          onClick={goNextWsi}
          disabled={!nextWsiIdWithoutAnnotations}
          description="Next Slide"
        />
      </HStack>
    </>
  );
};

export default SubmitCtrl;
